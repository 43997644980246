import _ from 'lodash';

import {
  Practice,
  PracticeEnablementConfig,
  PracticePreference,
} from 'ev-types';

export function getPracticePreference(
  practice: Practice | undefined,
  preference: PracticePreference,
) {
  if (!practice) {
    return false;
  }
  const pref = _.find(practice.attributes.preferences, {
    name: preference,
  });

  return pref && pref.value;
}

export function getPracticeEnablementConfig(
  practice: Practice | undefined,
  config: PracticeEnablementConfig,
) {
  return practice?.attributes.enablement_configs[config] ?? false;
}
