import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: { token: '' },
  reducers: {
    setAuthToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
  },
});
