import _ from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { DateRange } from '@mui/x-date-pickers-pro';

import { nt, useTranslation } from 'ev-i18n';

export type DateRangeValue = {
  startDate: string | null;
  endDate: string | null;
};

export const formatDateTime = (date: DateTime, format?: string): string =>
  format ? date.toFormat(format) : date.toISO() || nt('Invalid date');

export const formatDateRange = (
  dateRange: DateRange<DateTime>,
  format?: string,
): DateRangeValue => ({
  startDate: dateRange[0] ? formatDateTime(dateRange[0], format) : dateRange[0],
  endDate: dateRange[1] ? formatDateTime(dateRange[1], format) : dateRange[1],
});

export const changeDateRangeHours = (
  dateRange: DateRange<DateTime>,
): DateRange<DateTime> => [
  dateRange[0]
    ? dateRange[0].set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    : dateRange[0],
  dateRange[1]
    ? dateRange[1].set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      })
    : dateRange[1],
];

export const isDateRangeEqualTo = (
  dateRange1: DateRange<DateTime>,
  dateRange2: DateRange<DateTime>,
): boolean => {
  const startDateEqual =
    dateRange1[0] && dateRange2[0]
      ? dateRange1[0].equals(dateRange2[0])
      : dateRange1[0] === dateRange2[0];
  const endDateEqual =
    dateRange1[1] && dateRange2[1]
      ? dateRange1[1].equals(dateRange2[1])
      : dateRange1[1] === dateRange2[1];
  return startDateEqual && endDateEqual;
};

export const convertToDateRangeValue = (
  dateRange: DateRange<DateTime>,
  format?: string,
): DateRangeValue => formatDateRange(changeDateRangeHours(dateRange), format);

export const convertToDateRange = (
  dateRange: DateRangeValue,
  format?: string,
): DateRange<DateTime> => {
  let startDate = null;
  let endDate = null;
  if (dateRange.startDate) {
    startDate = format
      ? DateTime.fromFormat(dateRange.startDate, format)
      : DateTime.fromISO(dateRange.startDate);
  }
  if (dateRange.endDate) {
    endDate = format
      ? DateTime.fromFormat(dateRange.endDate, format)
      : DateTime.fromISO(dateRange.endDate);
  }
  return changeDateRangeHours([startDate, endDate]);
};

export enum DateRangeShortcutType {
  Today = 'today',
  Yesterday = 'yesterday',
  Tomorrow = 'tomorrow',
  ThisWeek = 'thisWeek',
  LastWeek = 'lastWeek',
  NextWeek = 'nextWeek',
  Past7Days = 'past7Days',
  Next7Days = 'next7Days',
  Next14Days = 'next14Days',
  Past14Days = 'past14Days',
  Past30Days = 'past30Days',
  Next30Days = 'next30Days',
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
  NextMonth = 'nextMonth',
  ThisYear = 'thisYear',
  LastYear = 'lastYear',
  AllTime = 'allTime',
  CustomRange = 'customRange',
}

export const useGetDateRangeShortcutLabel = () => {
  const { t } = useTranslation();

  return useCallback(
    (shortcut: DateRangeShortcutType): string => {
      const lookup = {
        [DateRangeShortcutType.Today]: t('Today'),
        [DateRangeShortcutType.Yesterday]: t('Yesterday'),
        [DateRangeShortcutType.Tomorrow]: t('Tomorrow'),
        [DateRangeShortcutType.ThisWeek]: t('This week'),
        [DateRangeShortcutType.NextWeek]: t('Next week'),
        [DateRangeShortcutType.LastWeek]: t('Last week'),
        [DateRangeShortcutType.Past7Days]: t('Past 7 days'),
        [DateRangeShortcutType.Past14Days]: t('Past 14 days'),
        [DateRangeShortcutType.Next7Days]: t('Next 7 days'),
        [DateRangeShortcutType.Next14Days]: t('Next 14 days'),
        [DateRangeShortcutType.Past30Days]: t('Past 30 days'),
        [DateRangeShortcutType.Next30Days]: t('Next 30 days'),
        [DateRangeShortcutType.ThisMonth]: t('This month'),
        [DateRangeShortcutType.LastMonth]: t('Last month'),
        [DateRangeShortcutType.NextMonth]: t('Next month'),
        [DateRangeShortcutType.ThisYear]: t('This year'),
        [DateRangeShortcutType.LastYear]: t('Last year'),
        [DateRangeShortcutType.AllTime]: t('All time'),
        [DateRangeShortcutType.CustomRange]: t('Custom range'),
      };

      return lookup[shortcut];
    },
    [t],
  );
};

// eslint-disable-next-line complexity
export const getDateRangeShortcutValue = (
  shortcut: DateRangeShortcutType,
): DateRange<DateTime> => {
  let value: DateRange<DateTime> = [null, null];
  const today = DateTime.now();
  switch (shortcut) {
    case DateRangeShortcutType.Today: {
      value = [today, today];
      break;
    }
    case DateRangeShortcutType.Yesterday: {
      const yesterday = today.minus({ day: 1 });
      value = [yesterday, yesterday];
      break;
    }
    case DateRangeShortcutType.Tomorrow: {
      const tomorrow = today.plus({ day: 1 });
      value = [tomorrow, tomorrow];
      break;
    }
    case DateRangeShortcutType.ThisWeek: {
      value = [today.startOf('week'), today.endOf('week')];
      break;
    }
    case DateRangeShortcutType.NextWeek: {
      const nextWeek = today.plus({ weeks: 1 });
      value = [nextWeek.startOf('week'), nextWeek.endOf('week')];
      break;
    }
    case DateRangeShortcutType.LastWeek: {
      const aWeekAgo = today.minus({ weeks: 1 });
      value = [aWeekAgo.startOf('week'), aWeekAgo.endOf('week')];
      break;
    }
    case DateRangeShortcutType.Past7Days: {
      value = [today.minus({ days: 6 }), today];
      break;
    }
    case DateRangeShortcutType.Next7Days: {
      value = [today, today.plus({ days: 7 })];
      break;
    }
    case DateRangeShortcutType.Past14Days: {
      value = [today.minus({ days: 13 }), today];
      break;
    }
    case DateRangeShortcutType.Next14Days: {
      value = [today, today.plus({ days: 14 })];
      break;
    }
    case DateRangeShortcutType.Past30Days: {
      value = [today.minus({ days: 29 }), today];
      break;
    }
    case DateRangeShortcutType.Next30Days: {
      value = [today, today.plus({ days: 30 })];
      break;
    }
    case DateRangeShortcutType.ThisMonth: {
      value = [today.startOf('month'), today];
      break;
    }
    case DateRangeShortcutType.LastMonth: {
      const aMonthAgo = today.minus({ months: 1 });
      value = [aMonthAgo.startOf('month'), aMonthAgo.endOf('month')];
      break;
    }
    case DateRangeShortcutType.NextMonth: {
      const nextMonth = today.plus({ months: 1 });
      value = [nextMonth.startOf('month'), nextMonth.endOf('month')];
      break;
    }
    case DateRangeShortcutType.ThisYear: {
      value = [today.startOf('year'), today];
      break;
    }
    case DateRangeShortcutType.LastYear: {
      const ayearAgo = today.minus({ years: 1 });
      value = [ayearAgo.startOf('year'), ayearAgo.endOf('year')];
      break;
    }
    case DateRangeShortcutType.AllTime:
    default: {
      value = [null, null];
      break;
    }
  }
  return changeDateRangeHours(value);
};

export const findDateRangeShortcutByDateRangeValue = (
  dateRange: DateRangeValue,
  shortcuts: DateRangeShortcutType[],
  format?: string,
): DateRangeShortcutType | undefined => {
  const range = convertToDateRange(dateRange, format);
  return shortcuts.find(shortcut =>
    isDateRangeEqualTo(getDateRangeShortcutValue(shortcut), range),
  );
};

export const getDateRangeLabel = (value: DateRangeValue) => {
  const start = value.startDate
    ? DateTime.fromISO(value.startDate).toLocaleString(DateTime.DATE_SHORT)
    : '';
  const end = value.endDate
    ? DateTime.fromISO(value.endDate).toLocaleString(DateTime.DATE_SHORT)
    : '';
  return _.compact([start, end]).join(' - ');
};
