enum Environments {
  Local = 'development',
  Test = 'test',
  Poc = 'poc',
  DevTest = 'devtest',
  Acceptance = 'acceptance',
  Beta = 'beta',
  Production = 'production',
}

export default Environments;
