import api, { Base } from 'ev-api/api';

import {
  InitNativeAuthParams,
  InitNativeAuthResponse,
  InitWebAuthParams,
  InitWebAuthResponse,
  NativeCredentialsRegistrationParams,
  NativeCredentialsRegistrationResponse,
  RegisterWebAuthResponse,
  VerifyAuthResponse,
  VerifyNativeAuthParams,
  VerifyWebAuthParams,
  VerifyWebRegistrationParams,
  VerifyWebRegistrationResponse,
} from './params';

export const authApi = api.injectEndpoints({
  endpoints: builder => ({
    initWebRegistration: builder.mutation<RegisterWebAuthResponse, void>({
      query: () => ({
        url: `${Base.V3}/auth_credentials/webauthn_register_init`,
        method: 'POST',
      }),
    }),
    verifyWebRegistration: builder.mutation<
      VerifyWebRegistrationResponse,
      VerifyWebRegistrationParams
    >({
      query: ({ credential }) => ({
        url: `${Base.V3}/auth_credentials/webauthn_register_verify`,
        method: 'POST',
        body: {
          public_key_credential: credential,
        },
      }),
    }),
    initWebAuthentication: builder.mutation<
      InitWebAuthResponse,
      InitWebAuthParams
    >({
      query: ({ uid }) => ({
        url: `${Base.V3}/auth_credentials/webauthn_auth_init`,
        method: 'POST',
        body: {
          uid,
        },
      }),
    }),
    verifyWebAuthentication: builder.mutation<
      VerifyAuthResponse,
      VerifyWebAuthParams
    >({
      query: ({ credential }) => ({
        url: `${Base.V3}/auth_credentials/webauthn_auth_verify`,
        method: 'POST',
        body: {
          public_key_credential: credential,
        },
      }),
    }),
    registerNativeCredentials: builder.mutation<
      NativeCredentialsRegistrationResponse,
      NativeCredentialsRegistrationParams
    >({
      query: ({ credential, deviceID }) => ({
        url: `${Base.V3}/auth_credentials/register`,
        method: 'POST',
        body: {
          credential,
          device_id: deviceID,
        },
      }),
    }),
    initNativeAuthentication: builder.mutation<
      InitNativeAuthResponse,
      InitNativeAuthParams
    >({
      query: ({ uid, deviceID }) => ({
        url: `${Base.V3}/auth_credentials/auth_init`,
        method: 'POST',
        body: {
          uid,
          device_id: deviceID,
        },
      }),
    }),
    verifyNativeAuthentication: builder.mutation<
      VerifyAuthResponse,
      VerifyNativeAuthParams
    >({
      query: ({ signature }) => ({
        url: `${Base.V3}/auth_credentials/auth_verify`,
        method: 'POST',
        body: {
          signature,
        },
      }),
    }),
  }),
});

export const {
  useInitWebRegistrationMutation,
  useVerifyWebAuthenticationMutation,
  useVerifyWebRegistrationMutation,
  useRegisterNativeCredentialsMutation,
  useInitNativeAuthenticationMutation,
  useVerifyNativeAuthenticationMutation,
  useInitWebAuthenticationMutation,
} = authApi;
