import { detect } from 'detect-browser';

export function checkBrowser() {
  const browserInfo = detect() || { os: '', type: '', name: '', version: '' };

  const { os, type } = browserInfo;

  const isAndroidBrowser = type === 'browser' && os && /Android OS/i.test(os);
  const isIosBrowser = type === 'browser' && os && /iOS/i.test(os);
  const isMacSystem = type === 'browser' && os && /Mac OS/i.test(os);

  return {
    isAndroidBrowser,
    isIosBrowser,
    isMacSystem,
  };
}

export default function useBrowserCheck() {
  return checkBrowser();
}

export function useTypeBrowserCheck() {
  const browserInfo = detect() || { os: '', type: '', name: '', version: '' };
  const { os, type, name } = browserInfo;

  const isChrome = type === 'browser' && os && /chrome/i.test(name);
  const isFirefox = type === 'browser' && os && /firefox/i.test(name);
  const isSafari = type === 'browser' && os && /safari/i.test(name);
  const isEdge = type === 'browser' && os && /edge-chromium/i.test(name);

  return {
    isChrome,
    isFirefox,
    isSafari,
    isEdge,
  };
}
