import { Id, ResponseId } from 'ev-types';

export type ChartQuestion = {
  id: Id;
  name: string;
  label: string;
  links_to_patient_details: boolean;
  own_summary_page: boolean;
  initial_value: string;
  required: boolean;
  position: number;
  templates?: ChartQuestionTemplate[];
  attestation_type?: ChartAttestationType;
  is_attachment: boolean;
};

export type ChartQuestionTemplateConfig = {
  name: string;
  label: string;
  initial_value: string;
  required?: boolean;
};

export type ChartQuestionTemplateBooleanConfig = ChartQuestionTemplateConfig & {
  yes_label: string;
  yes_output: string;
  no_label: string;
  no_output: string;
};

export type ChartQuestionTemplateStringConfig = ChartQuestionTemplateConfig;
export type ChartQuestionTemplateTextConfig = ChartQuestionTemplateConfig;

export type ChartQuestionTemplateOption = {
  label: string;
  output: string;
};

export type ChartQuestionTemplateOptionsConfig = ChartQuestionTemplateConfig & {
  options: ChartQuestionTemplateOption[];
};

export type ChartQuestionTemplateRadioOptionsConfig =
  ChartQuestionTemplateOptionsConfig;

export type ChartQuestionTemplateDateConfig = ChartQuestionTemplateConfig & {
  output: string;
};

export type ChartQuestionTemplateESignatureConfig =
  ChartQuestionTemplateConfig & {
    output: string;
  };

export enum ChartTemplateQuestionKind {
  String = 'string',
  Text = 'text',
  Options = 'options',
  RadioOptions = 'radio-options',
  Boolean = 'boolean',
  Date = 'date',
  eSignature = 'esignature',
}

export type ChartQuestionTemplateConfigs =
  | ChartQuestionTemplateBooleanConfig
  | ChartQuestionTemplateStringConfig
  | ChartQuestionTemplateTextConfig
  | ChartQuestionTemplateOptionsConfig
  | ChartQuestionTemplateRadioOptionsConfig
  | ChartQuestionTemplateDateConfig
  | ChartQuestionTemplateESignatureConfig;

export type ChartQuestionTemplateQuestion = {
  id: Id;
  kind: ChartTemplateQuestionKind;
  priority: number;
  config: ChartQuestionTemplateConfigs;
};

export enum ChartQuestionKind {
  Text = 'text',
  Robust = 'robust',
}

export type ChartQuestionTemplate = {
  id: Id;
  kind: ChartQuestionKind;
  label: string;
  output: string;
  position: number;
  questions?: ChartQuestionTemplateQuestion[];
};

export type ChartCodeAttributes = {
  code: string;
  description: string;
  kind: string;
};

export type ChartCode = {
  id: string | number;
  type: string;
  attributes: ChartCodeAttributes;
};

export type ChartAddendum = {
  user_id: ResponseId;
  visit_id: ResponseId;
  posted_at: string;
  text: string;
  signature: {
    full_name_with_prefix: string;
    npi?: string | null;
  };
};

export enum ChartAttestationType {
  Signature = 'signature',
  Cosignature = 'cosignature',
}
