import { useEffect, useState } from 'react';

import { usePrimaryUser } from 'ev-hooks/commonData';

import i18n, { DEFAULT_LOCALE } from './i18n';

const LANGUAGES = ['en', 'es'];

export function getBrowserLanguage(browserDefault: string): string {
  if (LANGUAGES.includes(browserDefault)) {
    return browserDefault;
  }
  return DEFAULT_LOCALE;
}

export function useLanguageUpdater() {
  const primaryUser = usePrimaryUser();
  const browserDefault = navigator.language.substring(0, 2);
  const [, setLanguage] = useState('');

  useEffect(() => {
    const language =
      (
        primaryUser.attributes.locale || getBrowserLanguage(browserDefault)
      ).substring(0, 2) || DEFAULT_LOCALE;
    setLanguage(language);
    void i18n.changeLanguage(language);
  }, [browserDefault, primaryUser.attributes.locale]);

  return i18n.language;
}
