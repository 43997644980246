import classnames from 'classnames';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import styled from 'styled-components/macro';

import FormControlLabel from '@mui/material/FormControlLabel';
import SwitchMUI from '@mui/material/Switch';

import Text from 'ev-components/Text';
import Tooltip from 'ev-components/Tooltip';
import { TooltipVariant } from 'ev-components/Tooltip/Tooltip';

export enum ToggleSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'extraLarge',
}

type AccessibleLabelProps = {
  label?: string;
  labelMargin?: React.CSSProperties['margin'];
  ariaLabel?: string;
};

export type ToggleProps = {
  children?: React.ReactNode;
  id: string;
  initialValue?: boolean;
  className?: string;
  onChange?: (value: boolean, id: string) => void;
  control?: Control;
  disabled?: boolean;
  size?: ToggleSize;
  labelPlacement?: 'end' | 'start';
  readonly?: boolean;
  tooltip?: string;
} & AccessibleLabelProps;

const Toggle = (props: ToggleProps) => {
  const {
    id,
    children,
    ariaLabel,
    className = '',
    label = '',
    labelMargin,
    initialValue = false,
    control,
    disabled = false,
    size = ToggleSize.Large,
    labelPlacement = 'end',
    readonly,
    tooltip,
  } = props;

  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({
    name: id,
    control,
    defaultValue: initialValue,
  });

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (readonly) {
      return;
    }
    const { checked } = event.target;
    onChange(checked);
    if (props.onChange) {
      props.onChange(checked, id);
    }
  };

  const getLabel = () => {
    switch (size) {
      case ToggleSize.Small:
        return <Text.Footnote>{label}</Text.Footnote>;
      case ToggleSize.Medium:
        return <Text.Description>{label}</Text.Description>;
      default:
        return <Text.Body>{label}</Text.Body>;
    }
  };

  return (
    <Tooltip
      arrowPlacement="bottom"
      id="toggle-tooltip"
      text={tooltip ?? ''}
      variant={TooltipVariant.Dark}
    >
      <Container
        className={classnames(className, size, {
          labelAtLeft: label && labelPlacement === 'start',
          labelAtRight: label && labelPlacement === 'end',
        })}
      >
        <FormControlLabel
          aria-label={ariaLabel}
          control={
            <SwitchMUI
              checked={value}
              className={className}
              data-testid={name}
              disabled={disabled}
              id={name}
              onBlur={onBlur}
              onChange={handleChange}
              readOnly={readonly}
              ref={ref}
              size={size}
            />
          }
          htmlFor={name}
          label={getLabel()}
          labelPlacement={labelPlacement}
          sx={{ margin: labelMargin }}
        />
        {children}
      </Container>
    </Tooltip>
  );
};

export default Toggle;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &.small.labelAtLeft {
    .MuiFormControlLabel-label {
      margin-right: 4px;
      line-height: 10px;
    }
  }
  &.small.labelAtRight {
    .MuiFormControlLabel-label {
      margin-left: 4px;
      line-height: 10px;
    }
  }
  &.medium.labelAtLeft {
    .MuiFormControlLabel-label {
      margin-right: 4px;
      line-height: 10px;
    }
  }
  &.medium.labelAtRight {
    .MuiFormControlLabel-label {
      margin-left: 4px;
      line-height: 10px;
    }
  }
  &.large.labelAtLeft {
    .MuiFormControlLabel-label {
      margin-right: 6px;
    }
  }
  &.large.labelAtRight {
    .MuiFormControlLabel-label {
      margin-left: 6px;
    }
  }
  &.extraLarge.labelAtLeft {
    .MuiFormControlLabel-label {
      margin-right: 8px;
    }
  }
  &.extraLarge.labelAtRight {
    .MuiFormControlLabel-label {
      margin-left: 8px;
    }
  }
`;
