import { EVColors } from 'ev-theme/styles';

export const MuiSelect = {
  styleOverrides: {
    icon: {
      color: EVColors.mako,
    },
    select: {
      fontSize: 14,
    },
  },
};
