import { withAppProviders } from 'Providers';
import { lazy } from 'react';

// TWILIO MIGRATION CONDITIONAL COMPONENT
// import { PracticeEnablementConfig } from 'ev-types';

// import LoadingIndicator from 'ev-components/LoadingIndicator';
// import { useNullableCurrentPractice } from 'ev-hooks/commonData';
// import { getPracticeEnablementConfig } from 'ev-utils/practice';

// const VideoApp = lazy(() => import('app-video'));
const VideoAppLegacy = lazy(() => import('app-video-legacy'));

const VideoAppSwitch = () => {
  // const currentPractice = useNullableCurrentPractice();
  // const useNewVideoVendor = getPracticeEnablementConfig(
  //   currentPractice,
  //   PracticeEnablementConfig.UseNewVideoVendor,
  // );

  // if (!currentPractice) {
  //   return <LoadingIndicator />;
  // }

  // return useNewVideoVendor ? <VideoApp /> : <VideoAppLegacy />;
  return <VideoAppLegacy />;
};

export default withAppProviders(VideoAppSwitch, false);
