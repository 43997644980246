import { useCallback } from 'react';
import {
  useNavigate, // eslint-disable-line no-restricted-imports
  useSearchParams,
} from 'react-router-dom';

export const useAppNavigate = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return useCallback(
    (
      target: string | number,
      params?: URLSearchParams | null,
      replace?: boolean,
    ) => {
      if (typeof target === 'string') {
        navigate(
          {
            pathname: target,
            search: '?' + (params ?? searchParams).toString(),
          },
          { replace },
        );
      } else {
        navigate(target);
      }
    },
    [navigate, searchParams],
  );
};

export const useParamsString = () => {
  const [searchParams] = useSearchParams();
  const value = searchParams.toString();
  if (value.length > 0) {
    return `?${value}`;
  }
  return '';
};

export const useUpdatePracticeSearchParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  return useCallback(
    (value: string) => {
      searchParams.delete('segment');
      searchParams.set('practice', value);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams],
  );
};
