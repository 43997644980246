import { DateTime } from 'luxon';
import { useCallback } from 'react';

import {
  DateRange,
  DateRangePicker as MUIDateRange,
  StaticDateRangePicker,
  StaticDateRangePickerProps,
} from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import DateRangeShortcut from 'ev-components/DateRange/DateRangeShortcut';

import {
  convertToDateRange,
  convertToDateRangeValue,
  DateRangeShortcutType,
  DateRangeValue,
  getDateRangeShortcutValue,
  useGetDateRangeShortcutLabel,
} from './utils';

export type DateRangePickerProps = {
  value: DateRangeValue;
  onChange: (value: DateRangeValue) => void;
  shortcuts?: DateRangeShortcutType[] | null;
  format?: string;
  readOnly?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  calendars?: 1 | 2 | 3;
  displayStaticWrapperAs?: 'desktop' | 'mobile';
  listItemHeight?: string;
  listItemWidth?: string;
  id?: string;
  localeText?: { start: string; end: string };
  staticPicker?: boolean;
  size?: 'small' | 'medium' | 'large';
};

export const DateRangePicker = ({
  value,
  onChange,
  shortcuts,
  format,
  readOnly = false,
  disableFuture = false,
  disablePast = false,
  calendars = 1,
  displayStaticWrapperAs = 'desktop',
  listItemHeight,
  listItemWidth,
  id,
  localeText,
  staticPicker = true,
  size = 'medium',
}: DateRangePickerProps) => {
  const dateRange = convertToDateRange(value, format);
  const getDateRangeShortcutLabel = useGetDateRangeShortcutLabel();

  const handleOnChangeDateRange = useCallback(
    (range: DateRange<DateTime>) => {
      onChange(convertToDateRangeValue(range, format));
    },
    [onChange, format],
  );

  const dateRangeProps: StaticDateRangePickerProps<DateTime> = {
    calendars,
    disableFuture,
    disablePast,
    readOnly,
    value: dateRange,
    displayStaticWrapperAs,
    onChange: handleOnChangeDateRange,
    defaultRangePosition: 'end',
  };

  if (shortcuts) {
    dateRangeProps.slots = { shortcuts: DateRangeShortcut };
    dateRangeProps.slotProps = {
      shortcuts: {
        value: dateRange,
        items: shortcuts.map(shortcut => ({
          label: getDateRangeShortcutLabel(shortcut),
          getValue: () => getDateRangeShortcutValue(shortcut),
        })),
        listItemHeight,
        listItemWidth,
      } as never,
    };
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      {staticPicker ? (
        <StaticDateRangePicker
          {...dateRangeProps}
          data-testid={id}
          localeText={localeText}
        />
      ) : (
        <MUIDateRange
          {...dateRangeProps}
          data-testid={id}
          localeText={localeText}
          slotProps={{
            textField: {
              size: size,
            },
          }}
        />
      )}
    </LocalizationProvider>
  );
};

export default DateRangePicker;
