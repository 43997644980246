export const MuiTabs = {
  styleOverrides: {
    root: {
      flexShrink: '0',
      maxHeight: '65px',
      minWidth: 'fit-content',
      maxWidth: '100vw',
      borderRadius: '4px',
      '.MuiTabs-scroller': {
        maxWidth: 'inherit',
        display: 'flex',
        flexGrow: '0',
        '.MuiTabs-flexContainer': {
          overflowY: 'auto',
          height: 'fit-content',
          'scrollbar-width': 'none' /* Firefox */,
          '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
          '&::-webkit-scrollbar': {
            /* WebKit */ width: '0',
            height: '0',
          },
        },
      },
    },
    indicator: {
      height: '0px',
    },
  },
};
