import _ from 'lodash';
import { DateTime } from 'luxon';
import styled from 'styled-components/macro';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { DateRange, PickersShortcutsProps } from '@mui/x-date-pickers-pro';

import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import { useTranslation } from 'ev-i18n';

import Icon from 'ev-components/Icon';
import { Text } from 'ev-components/Text';
import { EVColors } from 'ev-theme/styles';

import { isDateRangeEqualTo } from './utils';

const DateRangeShortcut = (
  props: PickersShortcutsProps<DateRange<DateTime>> & {
    value?: DateRange<DateTime>;
    listItemHeight?: string;
    listItemWidth?: string;
  },
) => {
  const { t } = useTranslation();
  const {
    value,
    items,
    onChange,
    isValid,
    listItemWidth = '171px',
    listItemHeight = '50px',
  } = props;

  if (items?.length === 0) {
    return null;
  }

  const resolvedItems = _.map(items, item => {
    const { label, getValue } = item;
    const itemValue = getValue({ isValid });
    return {
      label,
      onClick: () => {
        onChange(itemValue, 'set', { label });
      },
      selected: !!value && isDateRangeEqualTo(itemValue, value),
    };
  });

  return (
    <Box
      sx={{
        display: 'flex',
        gridColumn: 1,
        gridRow: 2,
      }}
    >
      <Box>
        <TitleContainer>
          <Text.Description bold>{t('Dates')}</Text.Description>
        </TitleContainer>
        <Divider orientation="horizontal" />
        <StyledList>
          {_.map(resolvedItems, item => {
            return (
              <StyledListItem
                $listItemHeight={listItemHeight}
                $listItemWidth={listItemWidth}
                $selected={item.selected}
                key={item.label}
                disablePadding
              >
                <StyledListItemButton onClick={item.onClick}>
                  <Text.Description>{item.label}</Text.Description>
                  {item.selected && <SelectedIcon icon={faCheck} />}
                </StyledListItemButton>
              </StyledListItem>
            );
          })}
        </StyledList>
      </Box>
      <Divider orientation="vertical" flexItem />
    </Box>
  );
};

export default DateRangeShortcut;

const StyledList = styled(List)`
  margin: 0;
  padding: 0;
`;

const StyledListItem = styled(ListItem)<{
  $listItemHeight: string;
  $listItemWidth: string;
  $selected: boolean;
}>`
  height: ${p => p.$listItemHeight};
  width: ${p => p.$listItemWidth};

  ${({ $selected }) =>
    $selected &&
    `
      background-color: ${EVColors.zircon};
    `}
`;

const StyledListItemButton = styled(ListItemButton)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  padding: 12px 16px;
`;

const SelectedIcon = styled(Icon)`
  color: ${EVColors.cerulean};
  margin: 0 0 0 8px;
`;
