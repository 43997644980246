import React from 'react';
import ReactDOM from 'react-dom';

import { LicenseInfo } from '@mui/x-license-pro';

import { MUI_KEY } from 'ev-config/config';

import './ev-i18n/i18n';
import './index.css';
import App from './Main';
import reportWebVitals from './reportWebVitals';

LicenseInfo.setLicenseKey(MUI_KEY);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
