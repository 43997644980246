import api, { Base } from 'ev-api/api';

import { CreateExpressRoomParams } from './params';
import { CreateExpressRoomResponse } from './responses';

export const expressApi = api.injectEndpoints({
  endpoints: builder => ({
    createExpressRoom: builder.mutation<
      CreateExpressRoomResponse,
      CreateExpressRoomParams
    >({
      query: ({ practiceId }) => ({
        url: `${Base.V3}/express/create_room`,
        method: 'POST',
        body: {
          practice_id: practiceId,
        },
      }),
    }),
  }),
});

export const { useCreateExpressRoomMutation } = expressApi;
