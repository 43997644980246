import { Practice, PracticePreference, User } from 'ev-types';

import { getPracticePreference } from 'ev-utils/practice';

export const getInsuranceInfo = (user: User, practice: Practice) => {
  const insuranceValidated =
    !!user.attributes.insurance_validation_result?.validated &&
    !!user.attributes.insurance_last_validated_at;

  const validateInsuranceVisitConfirmationFlow = getPracticePreference(
    practice,
    PracticePreference.ValidateInsuranceVisitConfirmationFlow,
  );

  return {
    insuranceValidated,
    showInsuranceVerificationStatus: validateInsuranceVisitConfirmationFlow,
  };
};

export default getInsuranceInfo;
