import { Components } from '@mui/material/styles/components';

import { EVColors } from 'ev-theme/styles';

export const MuiAccordion: Components['MuiAccordion'] = {
  styleOverrides: {
    root: {
      '&:before': {
        display: 'none',
      },
      '&:hover, &.ev-display-hover': {
        '.MuiAccordionSummary-root': {
          backgroundColor: EVColors.alabaster,
        },
      },
      '&.ev-display-focus': {
        '.MuiAccordionSummary-root': {
          boxShadow: `0px 0px 0px 4px ${EVColors.cornflower}`,
        },
      },
      '&.ev-display-open-hover': {
        '.MuiAccordionSummary-root.Mui-expanded': {
          color: EVColors.cobalt,
        },
      },
      '.MuiAccordionSummary-root': {
        height: '52px',
        color: EVColors.mako,
        '&.Mui-focusVisible': {
          boxShadow: `0px 0px 0px 4px ${EVColors.cornflower}`,
        },
        '&.Mui-expanded': {
          minHeight: '52px',
          color: EVColors.cerulean,
          '&:hover': {
            color: EVColors.cobalt,
          },
        },
      },
      '&.Mui-disabled': {
        backgroundColor: EVColors.alabaster,
      },
      '&.Mui-expanded': {
        '.MuiAccordionSummary-root': {
          backgroundColor: EVColors.selectedHover,
        },
      },
    },
  },
};
