import { EVColors } from 'ev-theme/styles';

export const MuiTab = {
  styleOverrides: {
    root: {
      minHeight: '32px',
      padding: '8px',
      '.ev-tab-label': {
        textTransform: 'none',
        cursor: 'pointer',
      },
      '&.ev-tab-contained': {
        color: EVColors.mako,
        borderRadius: '4px',
        margin: '0px 4px',
        svg: {
          color: EVColors.mako,
          height: '14px',
          width: '14px',
        },
        '&.ev-tab-medium': {
          height: '40px',
          minWidth: '128px',
          padding: '8px',
          width: 'fit-content',
        },
        '&.ev-tab-small': {
          height: '32px',
          minWidth: '128px',
          padding: '8px',
          width: 'fit-content',
        },
        '&:focus-visible, &.ev-display-focus': {
          borderRadius: '5px',
          border: `2px solid ${EVColors.cornflower}`,
        },
        '&:not(.Mui-disabled, .Mui-selected):hover, &.ev-display-hover': {
          backgroundColor: EVColors.alabaster,
        },
        '&.Mui-selected': {
          color: EVColors.cerulean,
          backgroundColor: EVColors.zircon,
          svg: {
            color: EVColors.cerulean,
          },
          '& .ev-tab-label': {
            color: EVColors.cerulean,
          },
        },
        '&.Mui-disabled': {
          color: EVColors.mercury,
          svg: {
            color: EVColors.mercury,
          },
          '& .ev-tab-label': {
            color: EVColors.mercury,
          },
        },
      },
      '&.ev-tab-underlined': {
        '&.ev-tab-small': {
          padding: '3px 16px 11px 16px',
          height: '32px',
          svg: {
            height: '14px',
            width: '14px',
          },
        },
        '&.ev-tab-medium': {
          height: '40px',
          svg: {
            height: '16px',
            width: '16px',
          },
        },
        '&.ev-tab-large': {
          height: '48px',
          svg: {
            height: '18px',
            width: '18px',
          },
        },
        borderBottom: '2px solid',
        borderBottomColor: EVColors.stone,
        color: EVColors.mako,
        minWidth: 'fit-content',
        svg: {
          color: EVColors.mako,
        },
        '&.Mui-disabled': {
          borderBottomColor: EVColors.aluminum,
          color: EVColors.mercury,
          svg: {
            color: EVColors.mercury,
          },
        },
        '&:focus-visible, &.ev-display-focus': {
          border: `2px solid ${EVColors.cornflower}`,
          borderBottomColor: EVColors.stone,
          borderRadius: '5px',
          boxShadow: `0 2px 0px -0px ${EVColors.cornflower}`,
        },
        borderRadius: '0px',
        width: 'fit-content',
        height: '32px',
        '&:not(.Mui-disabled, .Mui-selected):hover, &.ev-display-hover': {
          backgroundColor: 'transparent',
          color: EVColors.cobalt,
          borderBottomColor: EVColors.cobalt,
          svg: {
            color: EVColors.cobalt,
          },
          '.ev-badge': {
            backgroundColor: EVColors.cobalt,
            color: EVColors.white,
          },
          label: {
            color: EVColors.cobalt,
          },
        },
        '&.Mui-selected': {
          borderBottomColor: EVColors.cerulean,
          color: EVColors.cerulean,
          svg: {
            color: EVColors.cerulean,
          },
        },
      },
      '&.ev-tab-icon': {
        height: 'auto',
        '&.ev-tab-small': {
          minHeight: 'fit-content',
          width: '80px',
        },
        svg: {
          height: '24px',
          width: '24px',
        },
        borderBottom: '2px solid',
        borderRadius: '0px',
        width: '80px',
        padding: '8px 20px',
        '.MuiBadge-root:not(:has(svg))': {
          width: '40px',
          '.MuiBadge-dot': {
            marginBottom: '7px',
          },
        },
        '.MuiBadge-dot': {
          backgroundColor: EVColors.concrete,
        },
        '&:not(.Mui-disabled)': {
          '.MuiBadge-dot': {
            backgroundColor: EVColors.cerulean,
          },
        },
        '&.Mui-selected': {
          borderColor: EVColors.cerulean,
        },
      },
    },
  },
};
