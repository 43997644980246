import { MouseEventHandler } from 'react';
import styled from 'styled-components/macro';

import MUILink from '@mui/material/Link';

import { TextStyle } from 'ev-components/Text';

type Variant = 'body' | 'description';

type LinkProps = {
  children?: React.ReactNode;
  id: string;
  href?: string;
  download?: boolean | string;
  boldText?: boolean;
  onClick?: MouseEventHandler;
  underlined?: boolean;
  variant?: Variant;
  className?: string;
};

const Link = ({
  href,
  onClick,
  id,
  children,
  download = false,
  underlined = false,
  variant = 'body',
  boldText,
  className,
}: LinkProps) => {
  return (
    <Anchor
      $bold={boldText}
      $variant={variant}
      className={className}
      data-testid={id}
      download={download}
      href={href}
      onClick={onClick}
      target="_blank"
      underline={underlined ? 'always' : 'none'}
    >
      {children}
    </Anchor>
  );
};

export default Link;

const Anchor = styled(MUILink)<{ $variant: Variant; $bold?: boolean }>`
  cursor: pointer;
  .MuiLink-underlineAlways {
    text-decoration: underline;
  }
  ${({ $variant, $bold }) =>
    $variant === 'body'
      ? $bold
        ? TextStyle.SemiBold.Body
        : TextStyle.Regular.Body
      : $bold
        ? TextStyle.SemiBold.Description
        : TextStyle.Regular.Description}
`;
