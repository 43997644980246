import { useMemo } from 'react';

import {
  faAllergies,
  faClipboardListCheck,
  faHeartbeat,
  faPrescriptionBottleAlt,
  faSyringe,
  faUsers,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';

import { useTranslation } from 'ev-i18n';

import { User } from './user';

//this enum must correspond with the key used on the evaultDocument for the medical record being used
export enum TypeOfMedicalRecord {
  AllergyGeneral = 'allergies_general',
  AllergyMedication = 'allergies_medications',
  Conditions = 'conditions',
  FamilyHistory = 'family_history',
  Medications = 'medications',
  Procedures = 'procedures',
}

export enum HealthRecordOptions {
  GeneralAllergiesPatient = 'general-allergies-patient',
  MedicationAllergiesPatient = 'medication-allergies-patient',
  MedicationsPatient = 'medications-patient',
  ConditionsPatient = 'conditions-patient',
  ProceduresPatient = 'procedures-patient',
  FamilyHistoryPatient = 'family-history-patient',
  QuestionnairePatient = 'questionnaire-patient',
}

export enum HealthRecordGroups {
  Allergies = 'allergies',
  Medications = 'medications',
  Conditions = 'conditions',
  Procedures = 'procedures',
  FamilyHistory = 'family_history',
  Questions = 'questions',
}

export type HealthRecordObject = {
  [key in HealthRecordOptions]: HealthRecordOption;
};

export type HealthRecordOption = {
  title: string;
  icon: IconDefinition;
  group: string;
  path: string;
};

export const useAllHealthRecordsOptions = (): HealthRecordObject => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      [HealthRecordOptions.GeneralAllergiesPatient]: {
        title: t('General Allergies'),
        icon: faAllergies,
        group: HealthRecordGroups.Allergies,
        path: 'allergies/general',
      },
      [HealthRecordOptions.MedicationAllergiesPatient]: {
        title: t('Medication Allergies'),
        icon: faAllergies,
        group: HealthRecordGroups.Allergies,
        path: 'allergies/medication',
      },
      [HealthRecordOptions.MedicationsPatient]: {
        title: t('Medications'),
        icon: faPrescriptionBottleAlt,
        group: HealthRecordGroups.Medications,
        path: 'medications',
      },
      [HealthRecordOptions.ConditionsPatient]: {
        title: t('Medical Conditions'),
        icon: faHeartbeat,
        group: HealthRecordGroups.Conditions,
        path: 'conditions',
      },
      [HealthRecordOptions.ProceduresPatient]: {
        title: t('Medical Procedures'),
        icon: faSyringe,
        group: HealthRecordGroups.Procedures,
        path: 'procedures',
      },
      [HealthRecordOptions.FamilyHistoryPatient]: {
        title: t('Family history'),
        icon: faUsers,
        group: HealthRecordGroups.FamilyHistory,
        path: 'family_history',
      },
      [HealthRecordOptions.QuestionnairePatient]: {
        title: t('Questionnaire'),
        icon: faClipboardListCheck,
        group: HealthRecordGroups.Questions,
        path: 'questionnaire',
      },
    }),
    [t],
  );
};

export type HealthRecordsComponentProps = {
  onHandleUpdate?: (type?: TypeOfMedicalRecord) => void;
  editMode?: boolean;
  patient?: User;
  sidebarMode?: boolean;
  hideLoading?: boolean;
};
