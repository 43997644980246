import styled from 'styled-components/macro';

import { useTranslation } from 'ev-i18n';

import { EVColors } from 'ev-theme/styles';

const SkipToMain = () => {
  const { t } = useTranslation();

  return (
    <nav aria-label={t('Skip to main content')} id="skip-to-main">
      <StyledAnchor href="#main">{t('Skip to main content')}</StyledAnchor>
    </nav>
  );
};

export default SkipToMain;

const StyledAnchor = styled.a`
  & {
    padding: 8px;
    position: absolute;
    background: ${EVColors.surface.brandInverse};
    color: ${EVColors.white};
    left: 0%;
    height: 30px;
    transform: translateY(-100%);
    transition: transform 0.3s;
    text-decoration: none;
  }
  & :focus {
    transform: translateY(0%);
  }
`;
