import { Components } from '@mui/material/styles/components';

import { EVColors, Font, Typography } from 'ev-theme/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    special: true;
    menu: true;
  }
  export interface ButtonPropsSizeOverrides {
    extraLarge: true;
  }
}

export const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    root: {
      minWidth: '0',
      textTransform: 'none',
      '&.Mui-disabled': {
        borderColor: EVColors.concrete,
        backgroundColor: EVColors.concrete,
        color: EVColors.mercury,
      },
      '&[aria-busy*="true"]': {
        backgroundColor: EVColors.alabaster,
        color: EVColors.mako,
        borderColor: 'transparent',
        svg: {
          color: EVColors.cerulean,
        },
        '&:hover, &.hover': {
          backgroundColor: EVColors.alabaster,
          color: EVColors.mako,
          borderColor: 'transparent',
          svg: {
            color: EVColors.cerulean,
          },
        },
      },
      '.badge': {
        position: 'absolute',
        lineHeight: '14px',
        top: -8,
        right: 2,
      },
      '&.MuiButton-fullWidth': {
        width: '100%',
      },
      '&.ev-auto-height': {
        height: 'auto',
      },
      '&.ev-turn-down': {
        svg: {
          transform: 'scaleY(-1)',
        },
      },
    },
    sizeSmall: {
      height: '32px',
      padding: '8px 16px',
      ...Font.SemiBold,
      ...Typography.Footnote,
      '& .ev-button-icon': {
        fontSize: '12px !important',
      },
      '&.ev-fixed-icon': {
        padding: '0',
        width: '32px',
      },
    },
    sizeMedium: {
      height: '40px',
      padding: '10px 16px',
      ...Font.SemiBold,
      ...Typography.Description,
      '& .ev-button-icon': {
        fontSize: '14px !important',
      },
      '&.ev-fixed-icon': {
        padding: '0',
        width: '40px',
      },
    },
    sizeLarge: {
      height: '48px',
      padding: '14px 24px',
      ...Font.SemiBold,
      ...Typography.Body,
      '& .ev-button-icon': {
        fontSize: '16px !important',
      },
      '& .badge': {
        top: -9,
      },
      '&.ev-fixed-icon': {
        padding: '0',
        width: '48px',
      },
    },
    contained: {
      '&:hover, &.hover': {
        backgroundColor: EVColors.cobalt,
      },
      '&.ev-open': {
        backgroundColor: EVColors.openBlue,
      },
      '&.ev-cancel': {
        backgroundColor: EVColors.maraschino,
        color: EVColors.white,
        '&:hover, &.hover': {
          backgroundColor: EVColors.darkRed,
        },
      },
    },
    outlined: {
      borderColor: EVColors.silverBullet,
      '&:hover, &.hover': {
        backgroundColor: EVColors.alabaster,
        color: EVColors.cobalt,
        borderColor: EVColors.aluminum,
      },
      '&.Mui-disabled': {
        background: 'none',
        color: EVColors.mercury,
      },
      '&.ev-open': {
        backgroundColor: EVColors.zircon,
        color: EVColors.cerulean,
      },
      '&.ev-cancel': {
        color: EVColors.maraschino,
        '&:hover, &.hover': {
          color: EVColors.darkRed,
        },
      },
    },
    text: {
      color: EVColors.cerulean,
      backgroundColor: 'none',
      minWidth: 0,
      height: 'initial',
      fontWeight: 400,
      fontSize: 'inherit',
      width: 'initial',
      '&:hover, &.hover': {
        backgroundColor: 'none',
        color: EVColors.cobalt,
      },
      '&.Mui-disabled': {
        color: EVColors.mercury,
      },
      '&.ev-open': {
        backgroundColor: EVColors.zircon,
        color: EVColors.cerulean,
      },
      '&.ev-cancel': {
        color: EVColors.maraschino,
        '&:hover, &.hover': {
          backgroundColor: EVColors.alabaster,
          color: EVColors.darkRed,
        },
      },
    },
  },
  variants: [
    {
      props: { size: 'extraLarge' },
      style: {
        height: '56px',
        padding: '16px 24px',
        ...Font.SemiBold,
        ...Typography.Body,
        '& .ev-button-icon': {
          fontSize: '16px !important',
        },
        '& .badge': {
          top: -9,
        },
      },
    },
    {
      props: { variant: 'special' },
      style: {
        '& .ev-button-icon': {
          color: EVColors.mako,
        },
        '&.ev-open': {
          background: EVColors.zircon,
          borderColor: EVColors.silverBullet,
          color: EVColors.asphalt,
          '.ev-button-icon': {
            color: EVColors.cerulean,
          },
        },
        backgroundColor: EVColors.white,
        color: EVColors.mako,
        border: `1px solid ${EVColors.silverBullet}`,
        '&:hover, &.hover': {
          backgroundColor: EVColors.alabaster,
          color: EVColors.gunpowder,
          border: `1px solid ${EVColors.aluminum}`,
          '& .ev-button-icon': {
            color: EVColors.gunpowder,
          },
        },
        '&.Mui-disabled': {
          background: 'none',
          color: EVColors.mercury,
          '.ev-button-icon': {
            color: EVColors.mercury,
          },
        },
        '&.ev-cancel': {
          color: EVColors.maraschino,
          '& .ev-button-icon': {
            color: EVColors.maraschino,
          },
          '&:hover, &.hover': {
            color: EVColors.darkRed,
            '& .ev-button-icon': {
              color: EVColors.darkRed,
            },
          },
        },
      },
    },
    {
      props: { variant: 'text' },
      style: {
        '&.Mui-disabled': {
          background: 'none',
        },
        '&:hover, &.hover': {
          backgroundColor: EVColors.alabaster,
        },
      },
    },
    {
      props: { variant: 'text', size: 'small' },
      style: {
        height: 32,
        padding: '8px 16px',
      },
    },
    {
      props: { variant: 'text', size: 'medium' },
      style: {
        height: 40,
        padding: '10px 16px',
      },
    },
    {
      props: { variant: 'text', size: 'large' },
      style: {
        height: 48,
        padding: '14px 24px',
      },
    },
    {
      props: { variant: 'text', size: 'extraLarge' },
      style: {
        height: 56,
        padding: '16px 24px',
      },
    },
  ],
};
