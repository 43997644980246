declare module '@mui/material/styles' {
  interface TypographyVariants {
    // Headlines
    h1Bold: React.CSSProperties;
    h2Bold: React.CSSProperties;
    h3Bold: React.CSSProperties;
    h4Bold: React.CSSProperties;
    h5Bold: React.CSSProperties;

    // Title
    title: React.CSSProperties;
    titleBold: React.CSSProperties;

    // Body
    body: React.CSSProperties;
    bodyBold: React.CSSProperties;
    bodySemiBold: React.CSSProperties;
    bodyItalic: React.CSSProperties;
    bodyItalicBold: React.CSSProperties;

    // Description
    description: React.CSSProperties;
    descriptionBold: React.CSSProperties;
    descriptionItalic: React.CSSProperties;
    descriptionItalicBold: React.CSSProperties;

    // Footnote
    footnote: React.CSSProperties;
    footnoteBold: React.CSSProperties;
    footnoteItalic: React.CSSProperties;
    footnoteItalicBold: React.CSSProperties;

    // Caption
    captionBold: React.CSSProperties;
    captionItalic: React.CSSProperties;
    captionItalicBold: React.CSSProperties;
  }

  // Allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    // Headlines
    h1Bold: React.CSSProperties;
    h2Bold: React.CSSProperties;
    h3Bold: React.CSSProperties;
    h4Bold: React.CSSProperties;
    h5Bold: React.CSSProperties;

    // Title
    title: React.CSSProperties;
    titleBold: React.CSSProperties;

    // Body
    body: React.CSSProperties;
    bodyBold: React.CSSProperties;
    bodySemiBold: React.CSSProperties;
    bodyItalic: React.CSSProperties;
    bodyItalicBold: React.CSSProperties;

    // Description
    description: React.CSSProperties;
    descriptionBold: React.CSSProperties;
    descriptionItalic: React.CSSProperties;
    descriptionItalicBold: React.CSSProperties;

    // Footnote
    footnote: React.CSSProperties;
    footnoteBold: React.CSSProperties;
    footnoteItalic: React.CSSProperties;
    footnoteItalicBold: React.CSSProperties;

    // Caption
    captionBold: React.CSSProperties;
    captionItalic: React.CSSProperties;
    captionItalicBold: React.CSSProperties;
  }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    // Headlines
    h1Bold: true;
    h2Bold: true;
    h3Bold: true;
    h4Bold: true;
    h5Bold: true;

    // Titles
    title: true;
    titleBold: true;

    // Body
    body1: false;
    body2: false;
    body: true;
    bodyBold: true;
    bodySemiBold: true;
    bodyItalic: true;
    bodyItalicBold: true;

    // Description
    description: true;
    descriptionBold: true;
    descriptionItalic: true;
    descriptionItalicBold: true;

    // Caption
    captionBold: true;
    captionItalic: true;
    captionItalicBold: true;

    // Footnote
    footnote: true;
    footnoteBold: true;
    footnoteItalic: true;
    footnoteItalicBold: true;

    // Properties that we won't use
    h6: false;
    subtitle1: false;
    subtitle2: false;
    overline: false;
    button: false;
  }
}

const Inter = ['Inter', 'sans-serif'].join(',');

export const Font = {
  Regular: {
    fontFamily: Inter,
    fontWeight: 400,
    fontStyle: 'normal',
  },
  SemiBold: {
    fontFamily: Inter,
    fontWeight: 600,
    fontStyle: 'normal',
  },
};

export const Typography = {
  Headline1: {
    fontSize: 28,
    lineHeight: '48px',
  },
  Headline2: {
    fontSize: 26,
    lineHeight: '40px',
  },
  Headline3: {
    fontSize: 24,
    lineHeight: '36px',
  },
  Headline4: {
    fontSize: 22,
    lineHeight: '32px',
  },
  Headline5: {
    fontSize: 20,
    lineHeight: '28px',
  },
  Title: {
    fontSize: 18,
    lineHeight: '24px',
  },
  Body: {
    fontSize: 16,
    lineHeight: '20px',
  },
  Description: {
    fontSize: 14,
    lineHeight: '18px',
  },
  Footnote: {
    fontSize: 12,
    lineHeight: '16px',
  },
  Caption: {
    fontSize: 10,
    lineHeight: '16px',
  },
};

export const TypographyTheme = {
  htmlFontSize: 16,
  fontSize: 16,
  fontFamily: Inter,
  fontWeightBold: 700,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  h1: {
    ...Font.Regular,
    ...Typography.Headline1,
  },
  h1Bold: {
    ...Font.SemiBold,
    ...Typography.Headline1,
  },
  h2: {
    ...Font.Regular,
    ...Typography.Headline2,
  },
  h2Bold: {
    ...Font.SemiBold,
    ...Typography.Headline2,
  },
  h3: {
    ...Font.Regular,
    ...Typography.Headline3,
  },
  h3Bold: {
    ...Font.SemiBold,
    ...Typography.Headline3,
  },
  h4: {
    ...Font.Regular,
    ...Typography.Headline4,
  },
  h4Bold: {
    ...Font.SemiBold,
    ...Typography.Headline4,
  },
  h5: {
    ...Font.Regular,
    ...Typography.Headline5,
  },
  h5Bold: {
    ...Font.SemiBold,
    ...Typography.Headline5,
  },
  title: {
    ...Font.Regular,
    ...Typography.Title,
  },
  titleBold: {
    ...Font.SemiBold,
    ...Typography.Title,
  },
  body: {
    ...Font.Regular,
    ...Typography.Body,
  },
  bodyBold: {
    ...Font.SemiBold,
    ...Typography.Body,
  },
  bodySemiBold: {
    ...Font.SemiBold,
    ...Typography.Body,
  },
  bodyItalic: {
    ...Font.Regular,
    ...Typography.Body,
    fontStyle: 'italic',
  },
  bodyItalicBold: {
    ...Font.SemiBold,
    ...Typography.Body,
    fontStyle: 'italic',
  },
  description: {
    ...Font.Regular,
    ...Typography.Description,
  },
  descriptionBold: {
    ...Font.SemiBold,
    ...Typography.Description,
  },
  descriptionItalic: {
    ...Font.Regular,
    ...Typography.Description,
    fontStyle: 'italic',
  },
  descriptionItalicBold: {
    ...Font.SemiBold,
    ...Typography.Description,
    fontStyle: 'italic',
  },
  caption: {
    ...Font.Regular,
    ...Typography.Caption,
  },
  captionBold: {
    ...Font.SemiBold,
    ...Typography.Caption,
  },
  captionItalic: {
    ...Font.Regular,
    ...Typography.Caption,
    fontStyle: 'italic',
  },
  captionItalicBold: {
    ...Font.SemiBold,
    ...Typography.Caption,
    fontStyle: 'italic',
  },
  footnote: {
    ...Font.Regular,
    ...Typography.Footnote,
  },
  footnoteBold: {
    ...Font.SemiBold,
    ...Typography.Footnote,
  },
  footnoteItalic: {
    ...Font.Regular,
    ...Typography.Footnote,
    fontStyle: 'italic',
  },
  footnoteItalicBold: {
    ...Font.SemiBold,
    ...Typography.Footnote,
    fontStyle: 'italic',
  },
};
