import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { commonFilters, commonInitialState } from './common';

const initialState = {
  searchFilter: commonInitialState.searchFilter,
};

export const queueViewSlice = createSlice({
  name: 'queueViewSlice',
  initialState,
  reducers: {
    setSearchFilter: commonFilters.setSearchFilter,
    resetFilters(state) {
      _.each(initialState, (object, key) => _.set(state, key, object));
    },
  },
});

export const { setSearchFilter, resetFilters } = queueViewSlice.actions;
