import { EVColors } from 'ev-theme/styles';

export const MuiRating = {
  styleOverrides: {
    root: {
      '.MuiRating-icon': {
        color: EVColors.cerulean,
        padding: '6px',
        transform: 'none',
      },
      '&.Mui-disabled': {
        '.MuiRating-icon': {
          color: EVColors.mercury,
          opacity: 'none',
        },
      },
      '&&.Mui-focusVisible .MuiRating-iconActive': {
        outline: 'none',
      },
      '&.MuiRating-readOnly': {
        '.MuiRating-icon': {
          color: EVColors.mako,
          padding: '6px',
        },
      },
    },
    sizeSmall: {
      '.MuiRating-icon': {
        svg: {
          height: '14px',
          width: '14px',
        },
      },
    },
    sizeMedium: {
      '.MuiRating-icon': {
        svg: {
          height: '24px',
          width: '24px',
        },
      },
    },
    sizeLarge: {
      '.MuiRating-icon': {
        svg: {
          height: '32px',
          width: '32px',
        },
      },
    },
  },
};
