import { createSlice } from '@reduxjs/toolkit';

import { authSlice } from './slices/auth';
import { avatarSlice } from './slices/avatar';
import { drawerSlice } from './slices/drawer';
import { globalErrorSlice } from './slices/global-error';
import { personalFilterViewSlice } from './slices/personal-filter-view';
import { selectedPatientSlice } from './slices/selected-patient';
import { selectedVisitSlice } from './slices/selected-visit';
import { sidebarSlice } from './slices/sidebar';
import { testOverlaySlice } from './slices/test-overlay';
import { timezoneNagSlice } from './slices/timezone-nag';
import { totalVisitsSlice } from './slices/total-visits';
import { patientsViewSlice } from './slices/views/patients';
import { queueViewSlice } from './slices/views/queue';
import { schedulingViewSlice } from './slices/views/scheduling';
import { visitHistoryViewSlice } from './slices/views/visitHistory';
import { waitingRoomSlice } from './slices/waiting-room';

export { createSlice };
export const { selectPatient, clearSelectedPatient } =
  selectedPatientSlice.actions;
export const { selectSegment } = waitingRoomSlice.actions;
export const { setGlobalError, clearGlobalError } = globalErrorSlice.actions;
export const { setTestModeOverlayVisible } = testOverlaySlice.actions;
export const { setAuthToken } = authSlice.actions;
export const { updateTotalVisits } = totalVisitsSlice.actions;
export const { toggleDrawer, setDrawerOpen } = drawerSlice.actions;
export const { setTimezoneNag } = timezoneNagSlice.actions;
export const {
  setPfvArrived,
  setPfvReady,
  selectPfv,
  clearPfv,
  selectProvider,
  clearStatusFilters,
  clearProviderFilters,
  toggleFieldVisibility,
  setInitialColumnsVisibility,
  setColumnsVisibility,
  setGridDensity,
  setSortBy,
  setColumnsProps,
  setWRColumnFiltering,
  setWRColumnSorting,
} = personalFilterViewSlice.actions;
export const { selectVisit, clearSelectedVisit, setVisitPreferredPharmacy } =
  selectedVisitSlice.actions;
export const { setSidebarPage, openSidebar, closeSidebar } =
  sidebarSlice.actions;

export const { setAvatar, clearAvatar } = avatarSlice.actions;

const slices = {
  [selectedPatientSlice.name]: selectedPatientSlice.reducer,
  [waitingRoomSlice.name]: waitingRoomSlice.reducer,
  [totalVisitsSlice.name]: totalVisitsSlice.reducer,
  [globalErrorSlice.name]: globalErrorSlice.reducer,
  [testOverlaySlice.name]: testOverlaySlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [drawerSlice.name]: drawerSlice.reducer,
  [personalFilterViewSlice.name]: personalFilterViewSlice.reducer,
  [selectedVisitSlice.name]: selectedVisitSlice.reducer,
  [sidebarSlice.name]: sidebarSlice.reducer,
  [patientsViewSlice.name]: patientsViewSlice.reducer,
  [schedulingViewSlice.name]: schedulingViewSlice.reducer,
  [visitHistoryViewSlice.name]: visitHistoryViewSlice.reducer,
  [queueViewSlice.name]: queueViewSlice.reducer,
  [timezoneNagSlice.name]: timezoneNagSlice.reducer,
  [avatarSlice.name]: avatarSlice.reducer,
};

export default slices;
