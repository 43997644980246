import { ReactElement } from 'react';

import { PopperPlacementType } from '@mui/material/Popper';
import MUITooltip from '@mui/material/Tooltip';

export enum TooltipVariant {
  Light = 'light',
  Dark = 'dark',
}

export type BaseTooltipProps = {
  id: string;
  text: string | ReactElement;
  variant?: TooltipVariant;
  arrowPlacement?: PopperPlacementType;
  sx?: { [key: string]: string | number };
  maxWidth?: string;
  offsetX?: string;
};

export interface TooltipProps extends BaseTooltipProps {
  children: ReactElement;
  open?: boolean;
}

const Tooltip = ({
  id,
  arrowPlacement,
  variant = TooltipVariant.Light,
  text,
  open,
  children,
  sx,
  maxWidth = '139px',
  offsetX,
}: TooltipProps) => {
  return (
    <MUITooltip
      PopperProps={{
        placement: arrowPlacement,
      }}
      componentsProps={{
        tooltip: {
          className: variant,
        },
        popper: {
          sx: {
            '& .MuiTooltip-tooltip': {
              marginLeft: offsetX,
            },
            ...sx,
            maxWidth,
          },
          className: 'chromatic-ignore',
        },
      }}
      data-testid={id}
      open={open}
      title={text}
      arrow
    >
      {children}
    </MUITooltip>
  );
};

export default Tooltip;
