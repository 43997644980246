/* eslint-disable complexity */
import _ from 'lodash';
import { Settings } from 'luxon';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Id, Practice, PracticeEnablementConfig, User } from 'ev-types';

import {
  useGetCurrentUserQuery,
  useGetPracticeByHandleQuery,
  useGetPracticesByIdQuery,
} from 'ev-api/core';
import { ErrorPayload, SESSION_TIMEOUT_ERROR_MESSAGE } from 'ev-api/errors';
import { oldEvaultApi } from 'ev-api/evault';
import { newEvaultApi } from 'ev-api/evault/new-evault';
import UnsupportedBrowser from 'ev-common/UnsupportedBrowser';
import LoadingIndicator from 'ev-components/LoadingIndicator';
import { URL_PREFIX } from 'ev-config/config';
import { useAppNavigate } from 'ev-hooks/navigate';
import { useAppDispatch } from 'ev-store/redux';
import { refresh } from 'ev-utils/browser';
import { getPracticeEnablementConfig } from 'ev-utils/practice';

import { ADMIN_ROOT } from 'app-admin/paths';
import LoginPaths from 'app-login/paths';

import useBrowserCompatibilityCheck from './browserCompatibilityCheck';
import { useUpdatePracticeSearchParam } from './navigate';

/**
 * primaryUser and currentUser must be nullable in order for CommonDataProvider
 * to serve unauthenticated pages. For backwards compatibility, useCommonData,
 * usePrimaryUser, and useCurrentUser behave as though primaryUser and
 * currentUser are not nullable while useNullablePrimaryUser and
 * useNullableCurrentUser serve pages that provide both authenticated and
 * unauthenticated experiences.
 */
export type CommonDataContext = {
  primaryUser?: User;
  currentUser?: User;
  currentPractice?: Practice;
  selectUser: (id: Id) => void;
  updatePractice: (practice: Practice) => void;
  isFetchingCurrentPractice: boolean;
  _valid: boolean;
};

export type CommonDataContextRequired = Required<CommonDataContext>;

const PracticeParam = 'practice';
const SelectedUserParam = 'selectedUserId';

// this should rarely be imported in other files,
// but it is needed in some specific testing cases
export const commonDataContext = createContext({} as CommonDataContext);

export const CommonDataProvider = ({
  children,
  requiresAuth = true,
}: {
  children: React.ReactNode;
  requiresAuth: boolean;
}) => {
  const { data: primaryUser, isLoading: isLoadingPrimaryUser } =
    useGetCurrentUserQuery();
  const [selectedUserId, setSelectedUserId] = useState<Id | null>(null);
  const [currentPractice, setCurrentPractice] = useState<
    Practice | undefined
  >();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const updatePracticeSearchParam = useUpdatePracticeSearchParam();
  const practiceId = primaryUser?.attributes.primary_practice_id || '0';
  const practiceHandle = searchParams.get(PracticeParam) || '';
  const isBrowserSupported = useBrowserCompatibilityCheck(currentPractice);
  const navigate = useAppNavigate();

  const {
    data: practice,
    isFetching: isFetchingPracticeByHandle,
    error: practiceByHandleError,
  } = useGetPracticeByHandleQuery(
    { handle: practiceHandle },
    { skip: !practiceHandle },
  );

  const { data: practicesArray, isFetching: isFetchingCurrentPractice } =
    useGetPracticesByIdQuery(
      { ids: [practiceId] },
      {
        skip: practiceId === '0',
      },
    );

  let p = practiceHandle ? practice : practicesArray && practicesArray[0];

  // This works around the following bug in devise
  // https://github.com/heartcombo/devise/issues/5213
  useEffect(() => {
    const error = practiceByHandleError as ErrorPayload | undefined;
    if (error?.data.error === SESSION_TIMEOUT_ERROR_MESSAGE) {
      refresh();
    }
  }, [practiceByHandleError]);

  // If the user is signing in from a practice they are not part of (they can do this
  // because practices are part of the same customer), then we need to change
  // to the user's primary practice
  if (
    primaryUser &&
    practicesArray &&
    !primaryUser.attributes.practice_handles?.includes(practiceHandle)
  ) {
    p = practicesArray.find(
      practice => primaryUser.attributes.primary_practice_id === practice.id,
    );
  }

  if (p?.id !== currentPractice?.id) {
    setCurrentPractice(p);
  }

  useEffect(() => {
    if (currentPractice) {
      const currentValue = searchParams.get(PracticeParam);
      const handle = currentPractice.attributes.handle;
      const isAdminApp = pathname.includes(ADMIN_ROOT);

      if (handle !== currentValue && !isAdminApp) {
        updatePracticeSearchParam(handle);
      }
    }
  }, [currentPractice, pathname, searchParams, updatePracticeSearchParam]);

  const updatePractice = useCallback(
    (practice: Practice) => setCurrentPractice(practice),
    [],
  );

  const selectUser = useCallback(
    (id: Id) => {
      if (!primaryUser) {
        return;
      }

      searchParams.set(SelectedUserParam, String(id));
      setSearchParams(searchParams, { replace: true });

      const user = _.find(primaryUser.dependents, { id }) || null;

      if (!user) {
        searchParams.delete(SelectedUserParam);
        setSearchParams(searchParams, { replace: true });
        setSelectedUserId(null);
      } else {
        setSelectedUserId(user.id);
      }
    },
    [searchParams, setSearchParams, primaryUser],
  );

  useEffect(() => {
    if (primaryUser) {
      const selectedId = searchParams.get(SelectedUserParam);
      if (selectedId) {
        selectUser(selectedId);
      }
    }
  }, [primaryUser, searchParams, selectUser]);

  useEffect(() => {
    if (!isLoadingPrimaryUser && !primaryUser && requiresAuth) {
      navigate(`${URL_PREFIX}/login/${LoginPaths.Login}`);
    }
  }, [isLoadingPrimaryUser, navigate, primaryUser, requiresAuth]);

  if (
    ((!primaryUser || isFetchingCurrentPractice) && requiresAuth) ||
    isLoadingPrimaryUser ||
    isFetchingPracticeByHandle ||
    !currentPractice
  ) {
    return (
      <LoadingContainer>
        <LoadingIndicator />
      </LoadingContainer>
    );
  }

  if (!isBrowserSupported) {
    return <UnsupportedBrowser practice={currentPractice} />;
  }

  const currentUser =
    _.find(primaryUser?.dependents, { id: selectedUserId as Id }) ||
    primaryUser;

  Settings.defaultZone =
    currentUser?.attributes.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <commonDataContext.Provider
      value={{
        primaryUser,
        currentUser,
        currentPractice,
        selectUser,
        updatePractice,
        isFetchingCurrentPractice,
        _valid: true,
      }}
    >
      {children}
    </commonDataContext.Provider>
  );
};

export function useCommonData(useNullableData: boolean = false) {
  const context = useContext(commonDataContext);
  if (!context._valid) {
    console.error(
      'Attempted to use common data context outside of a CommonDataProvider',
    );
  }

  if (
    !useNullableData &&
    (!context.primaryUser || !context.currentUser || !context.currentPractice)
  ) {
    throw new Error(
      'primaryUser, currentUser, or currentPractice is undefined - did you mean to call useNullablePrimaryUser, useNullableCurrentUser, or useNullableCurrentPractice?',
    );
  }

  return context as CommonDataContextRequired;
}

export function useNullablePrimaryUser(
  useNullableData: boolean = true,
): User | undefined {
  return useCommonData(useNullableData).primaryUser;
}

export function usePrimaryUser(): User {
  const primaryUser = useNullablePrimaryUser(false);
  if (!primaryUser) {
    throw new Error(
      'primaryUser is undefined - did you mean to call useNullablePrimaryUser',
    );
  }
  return primaryUser;
}

export function useNullableCurrentUser(
  useNullableData: boolean = true,
): User | undefined {
  return useCommonData(useNullableData).currentUser;
}

export function useCurrentUser(): User {
  const currentUser = useNullableCurrentUser(false);
  if (!currentUser) {
    throw new Error(
      'currentUser is undefined - did you mean to call useNullableCurrentUser',
    );
  }
  return currentUser;
}

export function useNullableCurrentPractice(
  useNullableData: boolean = true,
): Practice | undefined {
  return useCommonData(useNullableData).currentPractice;
}

export function useCurrentPractice() {
  const currentPractice = useNullableCurrentPractice(false);
  if (!currentPractice) {
    throw new Error(
      'currentPractice is undefined - did you mean to call useNullableCurrentPractice',
    );
  }
  return currentPractice;
}

export function useEvault() {
  return useEvaultForUser({ user: useCurrentUser() });
}

export function useEvaultForUser({
  user,
  accessToken,
  skip,
}: {
  user?: User;
  accessToken?: string;
  skip?: boolean;
}) {
  const vaultId = user?.attributes.vault_vault_id || '';
  const vaultHealthDocId = user?.attributes.vault_health_doc_id || '';
  const vaultToken = accessToken || user?.attributes.vault_access_token || '';
  const healthRecordId = user?.attributes.health_record_id ?? null;

  const { useGetPatientEvaultDocumentQuery } = useEvaultEndpoint();
  const {
    data: evaultRecord,
    isLoading,
    isFetching,
  } = useGetPatientEvaultDocumentQuery(
    {
      vaultId,
      vaultHealthDocId,
      vaultToken,
      healthRecordId,
    },
    {
      skip:
        !(vaultId && vaultHealthDocId && vaultToken && healthRecordId) || skip,
    },
  );

  return {
    evaultRecord,
    vaultId,
    vaultHealthDocId,
    vaultToken,
    healthRecordId,
    isLoadingEvaultRecord: isLoading,
    isFetchingEvaultRecord: isFetching,
  };
}

export function useEvaultEndpoint() {
  const currentPractice = useNullableCurrentPractice();
  const dispatch = useAppDispatch();
  const newEvault = useMemo(() => {
    const shouldUseNewApi = currentPractice
      ? getPracticeEnablementConfig(
          currentPractice,
          PracticeEnablementConfig.RedirectToNewEvault,
        )
      : false;
    if (shouldUseNewApi) {
      dispatch(oldEvaultApi.util.resetApiState());
    }
    return shouldUseNewApi;
  }, [currentPractice, dispatch]);
  return newEvault ? newEvaultApi : oldEvaultApi;
}

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
