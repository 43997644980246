export enum SidebarPage {
  Visit = 'visit',
  Patient = 'patient',
  ScheduledVisit = 'scheduled_visit',
  Chat = 'chat',
  Charting = 'charting',
  Attachments = 'attachments',
  Prescribe = 'prescribe',
  Billing = 'billing',
  Participants = 'participants',
  Empty = 'empty',
  Download = 'download',
  MobileDefault = 'mobile_default',
  PatientFacingNotes = 'patient_facing_notes',
  CancelVisit = 'cancel-visit',
  CompleteVisit = 'complete-visit',
}

export enum SideBarQuickLaunch {
  ConnectedDevices = 'connected_devices',
}
