import { createGlobalStyle } from 'styled-components/macro';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import { Styles } from 'ev-components/Text';
import { EVColors } from 'ev-theme/styles';
import { theme } from 'ev-theme/theme';

// https://dev.to/cydstumpel/4-things-in-web-development-i-ve-learned-in-2020-so-far-3cg
const iOSHeightHack = `
  height: 100vh;
  @media (pointer: coarse) {
    height: -webkit-fill-available;
  }
`;

const GlobalStyles = createGlobalStyle`
  html {
    font-family: "Inter", "sans-serif";
    font-feature-settings: "cv05";
    font-weight: 400;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  body {
    margin: 0;
    color: ${theme.palette.text.primary};
  }

  @media (max-width: 1024px) {
    body {
      user-select: none;
    }
  }

  /* Disable overscroll on mobile to prevent the header from going off the screen */
  html {
    overflow: hidden;
    ${iOSHeightHack}
  }

  body {
    overflow: auto;
    ${iOSHeightHack}
  }

  .fullHeight {
    ${iOSHeightHack}
  }
  /* */

  #root {
    display: flex;
    flex-direction: column;
    ${iOSHeightHack}
  }

  .navlink-plain {
    text-decoration: none;
    color: ${EVColors.cerulean};
    -webkit-tap-highlight-color: transparent;

    :visited,
    :active,
    :hover {
      color: ${theme.palette.text.primary};
    }
  }

  a:not(.MuiButtonBase-root) {
    color: ${EVColors.cerulean};
    -webkit-tap-highlight-color: transparent;

    :visited,
    :active,
    :hover {
      color: ${EVColors.cerulean};
    }
  }
  
  a:not(.MuiButtonBase-root, .MuiLink-underlineAlways) {
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  // Safari-friendly focused style
  button.focus-visible::before,
  button.Mui-focusVisible::before {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 4px solid ${EVColors.cornflower};
    border-radius: 8px;
    pointer-events: none;
  }
  button.focus-visible.inlineBorder::before,
  button.Mui-focusVisible.inlineBorder::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  [hidden] {
    display: none !important;
  }

  .tippy-box[data-theme~='evisit'] {
    background-color: ${EVColors.asphalt};
    color: ${EVColors.white};
    padding: 8px 16px;
    border-radius: 4px;

    ::before {
      border-color: ${EVColors.asphalt};
    }

    ${Styles.Caption}

    .bold {
      font-weight: 700;
    }
  }

  // Removes yellow autofill background on Safari
  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 9999px ${EVColors.white} inset !important;
    background-clip: content-box !important;
    height: 14px;
}
@keyframes upAndDown {
    0%, 100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-4px);
    }
    75% {
      transform: translateY(4px);
    }
  }
`;

tippy.setDefaultProps({
  delay: 0,
  duration: 200,
  theme: 'evisit',
});

export default GlobalStyles;
