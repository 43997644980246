import { isFunction } from './types';

export const copyToClipboard = async (
  value: string | (() => Promise<string>),
) => {
  // Chrome/Safari
  if ('ClipboardItem' in window) {
    // ClipboardItem required for using clipboard in async context in Safari
    if (isFunction(value)) {
      const clipboardItem = new ClipboardItem({
        'text/plain': value().then(result =>
          Promise.resolve(new Blob([result], { type: 'text/plain' })),
        ),
      });
      await navigator.clipboard.write([clipboardItem]);
    } else {
      await navigator.clipboard.writeText(value);
    }
  } else {
    // Firefox doesn't support ClipboardItem, but it doesn't blow up in an
    // async context like Safari does
    if (isFunction(value)) {
      await value().then(result => navigator.clipboard.writeText(result));
    } else {
      await navigator.clipboard.writeText(value);
    }
  }
};

export const clipboardExists = () => {
  return !!navigator.clipboard;
};
