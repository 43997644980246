import _ from 'lodash';

import {
  Address,
  CondensedUser,
  CondensedUserAttributes,
  EnrollmentUser,
  EnrollmentUserType,
  Id,
  LiveStatus,
  NotificationChannel,
  PendingIntegration,
  Preference,
  User,
  UserAttributes,
  UserCreator,
  UserCreditCard,
  UserProfile,
} from 'ev-types';

import {
  AddressResponse,
  isAddressResponse,
} from 'ev-api/common/AddressResponse';
import { PreferenceResponse } from 'ev-api/common/PreferenceResponse';
import {
  isPharmacyResponse,
  NotificationChannelResponse,
  PharmacyResponse,
} from 'ev-api/core';
import { pharmacyTransform } from 'ev-api/core/pharmacies/transformers';
import {
  maybeSanitizeId,
  sanitizeId,
  sanitizeIdArray,
  sanitizeNullableId,
} from 'ev-api/utils';
import { isObject } from 'ev-utils/types';

import {
  GetEnrollmentUserSuccessResponse,
  isDependentResponseData,
  LiveStatusResponse,
  UserAttributesResponse,
  UserAttributesResponseForGetVisits,
  UserCreatorResponse,
  UserCreditCardResponse,
  UserIncludedElement,
  UserProfileResponse,
  UserResponse,
  UserResponseForGetVisits,
} from './responses';

function findAddress(
  addresses: AddressResponse[],
  id?: Id,
): AddressResponse | undefined {
  return _.find(addresses, address => sanitizeId(address.id) === id);
}

function findPharmacy(
  pharmacies: PharmacyResponse[],
  id?: Id,
): PharmacyResponse | undefined {
  return _.find(pharmacies, pharmacy => sanitizeId(pharmacy.id) === id);
}

export function userTransform(response: UserResponse): User {
  const included = _.groupBy(response.included, 'type');
  const user: User = {
    id: sanitizeId(response.data.id),
    type: response.data.type,
    attributes: userAttributesResponseTransform(response.data.attributes),
    dependents: response.data.attributes.is_dependent
      ? []
      : _.map(
          _.filter(included.dependent, isDependentResponseData),
          dependent => {
            return userTransform({
              data: dependent,
              included: response.included,
            });
          },
        ),
    pending_integration: pendingIntegrationTransform(
      included.pending_integration,
    ),
  };

  const addressId = response.data.relationships.address?.data?.id;
  if (addressId) {
    const userAddress = findAddress(
      _.filter(included.address, isAddressResponse),
      sanitizeId(addressId),
    );
    if (userAddress) {
      user.address = addressResponseTransform(userAddress);
    }
  }

  const pharmacyId = response.data.relationships.pharmacy?.data?.id;
  if (pharmacyId) {
    const userPharmacy = findPharmacy(
      _.filter(included.pharmacy, isPharmacyResponse),
      sanitizeId(pharmacyId),
    );

    if (userPharmacy) {
      user.attributes.pharmacy = pharmacyTransform(userPharmacy.attributes);
    }
  }

  return user;
}

export function userAttributesResponseTransform(
  response: UserAttributesResponse,
): UserAttributes {
  return {
    ...response,
    roles: _.map(response.roles, r => ({
      role: r.role,
      practice_id: sanitizeId(r.practice_id),
    })),
    card: creditCardResponseTransform(response.card),
    preferred_card: creditCardResponseTransform(response.preferred_card),
    creator: userCreatorResponseTransform(response.creator),
    creator_user_id: sanitizeNullableId(response.creator_user_id),
    live_status: userLiveStatusResponseTransform(response.live_status),
    notification_channels: notificationChannelsResponseTransform(
      response.notification_channels,
    ),
    current_visit_id: response.current_visit_id
      ? sanitizeId(response.current_visit_id)
      : null,
    id: sanitizeId(response.id),
    obfuscated_id: sanitizeId(response.obfuscated_id),
    pharmacy: response.pharmacy
      ? pharmacyTransform(response.pharmacy)
      : undefined,
    practice_ids: sanitizeIdArray(response.practice_ids),
    preferences: preferencesResponseTransform(response.preferences),
    primary_practice_id: sanitizeId(response.primary_practice_id),
    profile: userProfileResponseTransform(response.profile),
    target_practice_id: sanitizeId(response.target_practice_id),
    vault_health_doc_id: sanitizeId(response.vault_health_doc_id),
    vault_vault_id: sanitizeId(response.vault_vault_id),
    health_record_id: sanitizeNullableId(response.health_record_id),
    insurance_validation_result: response.insurance_validation_result
      ? insuranceValidationResultTransform(response.insurance_validation_result)
      : null,
  };
}

export function creditCardResponseTransform(
  response?: UserCreditCardResponse,
): UserCreditCard | undefined {
  if (!response) {
    return undefined;
  }

  return {
    ...response,
    id: sanitizeId(response.id),
    cardable_id: sanitizeId(response.cardable_id),
    customer_id: sanitizeId(response.customer_id),
    processor_id: sanitizeId(response.processor_id),
  };
}

export function userCreatorResponseTransform(
  response?: UserCreatorResponse,
): UserCreator | undefined {
  if (!response) {
    return undefined;
  }

  return {
    ...response,
    user_id: sanitizeId(response.user_id),
  };
}

export function userLiveStatusResponseTransform(
  response?: LiveStatusResponse,
): LiveStatus | undefined {
  if (!response) {
    return undefined;
  }

  return {
    ...response,
    visit_id: response.visit_id === null ? null : sanitizeId(response.visit_id),
  };
}

export function notificationChannelsResponseTransform(
  response: NotificationChannelResponse[],
): NotificationChannel[] {
  return _.map(response, notificationChannelResponseTransform);
}

export function notificationChannelResponseTransform(
  response: NotificationChannelResponse,
): NotificationChannel {
  return {
    ...response,
    id: sanitizeId(response.id),
    user_id: sanitizeId(response.id),
  };
}

export function preferencesResponseTransform(
  response: PreferenceResponse[],
): Preference[] {
  return _.map(response, preferenceResponseTransform);
}

export function preferenceResponseTransform(
  response: PreferenceResponse,
): Preference {
  return {
    ...response,
    id: sanitizeId(response.id),
    owner_id: sanitizeId(response.owner_id),
  };
}

export function userProfileResponseTransform(
  response: UserProfileResponse | null,
): UserProfile {
  if (!response) {
    return {
      body: null,
      published: null,
    };
  }
  return response;
}

export function addressResponseTransform(response: AddressResponse): Address {
  const { attributes } = response;

  return {
    ...attributes,
    id: sanitizeId(attributes.id),
    addressable_id: maybeSanitizeId(attributes.addressable_id),
  };
}

export function insuranceValidationResultTransform(response: {
  validated: boolean | string;
}): { validated: boolean; validationSkipped: boolean } {
  if (
    typeof response.validated === 'string' &&
    response.validated === 'passed'
  ) {
    return { validated: false, validationSkipped: true };
  } else if (typeof response.validated === 'boolean') {
    return { validated: response.validated, validationSkipped: false };
  } else {
    return { validated: false, validationSkipped: false };
  }
}

export function userAttributesForGetVisitsResponseTransformer(
  response: UserAttributesResponseForGetVisits,
): CondensedUserAttributes {
  return {
    ...response,
    id: sanitizeId(response.id),
  };
}

export function userForGetVisitsResponseTransformer(
  response: UserResponseForGetVisits,
): CondensedUser {
  const userForGetVisits: CondensedUser = {
    id: sanitizeId(response.attributes.id),
    type: response.type,
    attributes: userAttributesForGetVisitsResponseTransformer(
      response.attributes,
    ),
  };
  return userForGetVisits;
}

export function enrollmentUserTransform(
  response: GetEnrollmentUserSuccessResponse,
): EnrollmentUser {
  const { id, first_name, last_name, email } = response.data;

  return {
    type: EnrollmentUserType,
    id: sanitizeId(id),
    email,
    first_name,
    last_name,
  };
}

export function pendingIntegrationTransform(
  value: UserIncludedElement[],
): PendingIntegration | undefined {
  const pi = _.first(value);
  const isPendingIntegration =
    isObject(pi) && pi.type === 'pending_integration';
  return isPendingIntegration ? pi : undefined;
}
