import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from 'ev-types';

type SelectedPatient = User | null;
const selectedPatientInitialState = null as SelectedPatient;

export const selectedPatientSlice = createSlice({
  name: 'selectedPatient',
  initialState: selectedPatientInitialState,
  reducers: {
    selectPatient(state, action: PayloadAction<SelectedPatient>) {
      return action.payload;
    },
    clearSelectedPatient() {
      return null;
    },
  },
});
