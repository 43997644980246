import { EVColors } from 'ev-theme/styles';

export const MuiFormHelperText = {
  styleOverrides: {
    root: {
      transform: `translate(-14px, 0px)`,
      color: EVColors.mako,
    },
  },
};
