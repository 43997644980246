// Using module directly due to dependency tree error when bootstrapping
// eslint-disable-next-line no-restricted-imports
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';

import { EVColors } from 'ev-theme/styles';

import Icon from './Icon';

const Loading = styled.div<{ size: number; color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${p => p.size}px;
  flex-grow: 1;
  color: ${p => p.color};
  padding: 4px 0;
`;

type LoadingIndicatorProps = {
  size?: number;
  className?: string;
  color?: string;
};

const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const { className, size = 64, color = EVColors.cerulean } = props;
  const { t } = useTranslation();

  return (
    <Loading
      aria-label={t('Loading...')}
      className={className}
      color={color}
      data-testid="loading-indicator"
      role="status"
      size={size}
    >
      <Icon icon={faCircleNotch} spin />
    </Loading>
  );
};

export default LoadingIndicator;
