import { AuthParams } from 'ev-types';

import { isReactNative } from 'ev-config/config';
import { AUTH_PARAMS_STORAGE_KEY } from 'ev-static/login';
import ReactNativeBridge from 'ev-utils/ReactNativeBridge';

export const LocalStorage = {
  getItem: async (key: string) => {
    if (isReactNative()) {
      const result = await ReactNativeBridge.sendMessage({
        command: 'localStorageGetItem',
        key,
      });
      return JSON.stringify(result);
    }

    return localStorage.getItem(key);
  },
  setItem: (key: string, value: string) => {
    if (isReactNative()) {
      return ReactNativeBridge.sendMessage({
        command: 'localStorageSetItem',
        key,
        value,
      });
    }
    return localStorage.setItem(key, value);
  },
  removeItem: (key: string) => {
    if (isReactNative()) {
      return ReactNativeBridge.sendMessage({
        command: 'localStorageRemoveItem',
        key,
      });
    }
    return localStorage.removeItem(key);
  },
};

export const SessionStorage = {
  getItem: (key: string) => {
    return sessionStorage.getItem(key);
  },
  setItem: (key: string, value: string) => {
    return sessionStorage.setItem(key, value);
  },
  removeItem: (key: string) => {
    return sessionStorage.removeItem(key);
  },
};

export async function getAuthParams() {
  const data = (await LocalStorage.getItem(AUTH_PARAMS_STORAGE_KEY)) || '{}';

  return JSON.parse(data);
}

export const updateAuthParams = async (params: AuthParams) => {
  const parsedOldParams = await getAuthParams();

  const newParams: AuthParams = {
    ...parsedOldParams,
    ...params,
  };

  await LocalStorage.setItem(
    AUTH_PARAMS_STORAGE_KEY,
    JSON.stringify(newParams),
  );
};
