import { EVColors } from 'ev-theme/styles';

export const MuiInputLabel = {
  styleOverrides: {
    shrink: {
      color: EVColors.mako,
    },
    outlined: {
      // @deprecated to be removed
      transform: `translate(0px, -22px)`,
      color: EVColors.mako,
      fontSize: 14,
      '&.Mui-focused': {
        color: EVColors.mako,
      },
      '&.Mui-error': {
        color: EVColors.mako,
      },
    },
  },
};
