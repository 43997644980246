import _ from 'lodash';
import { SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import MUIDivider from '@mui/material/Divider';
import MUIPopover, {
  PopoverProps as MUIPopoverProps,
} from '@mui/material/Popover';
import Stack from '@mui/material/Stack';

import { faTimes } from '@fortawesome/pro-light-svg-icons';

import Button, { ButtonProps, ButtonTypes } from 'ev-components/Button';
import Icon from 'ev-components/Icon';
import { Text } from 'ev-components/Text';
import { EVColors } from 'ev-theme/styles';

export type PopoverProps = {
  actionButtons?: ButtonProps[];
  anchorButton: ButtonProps;
  anchorTextLeftAligned?: boolean;
  anchorOrigin?: MUIPopoverProps['anchorOrigin'];
  children?: React.ReactNode;
  description?: string;
  header?: {
    closeAction: boolean;
    divider: boolean;
    title: string;
  };
  id?: string;
  maxHeight?: string;
  onClose?: () => void;
  remoteClose?: boolean;
  transformOrigin?: MUIPopoverProps['transformOrigin'];
  width?: number;
  paperPadding?: string;
  visibleScrollbar?: boolean;
};

const Popover = ({
  actionButtons,
  anchorButton,
  anchorOrigin = { horizontal: 'right', vertical: 'bottom' },
  anchorTextLeftAligned = false,
  children,
  description,
  header,
  id,
  maxHeight = 'auto',
  onClose = _.noop,
  remoteClose = false,
  transformOrigin = { horizontal: 'right', vertical: 'top' },
  width = 280,
  paperPadding = '24px',
  visibleScrollbar,
}: PopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const largePopover = width >= 372;

  const toggleOpen = (event: {
    currentTarget: SetStateAction<HTMLElement | null>;
  }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  useEffect(() => {
    if (remoteClose) {
      handleClose();
    }
  });

  const renderHeader = () => {
    return (
      <>
        <TitleContainer>
          <Text.Title bold>{header?.title}</Text.Title>
          <CloseButton
            id="close-popover-btn"
            onClick={handleClose}
            variant={ButtonTypes.Tertiary}
          >
            <Icon color={EVColors.gunpowder} icon={faTimes} />
          </CloseButton>
        </TitleContainer>
        {header?.divider && <Divider />}
      </>
    );
  };

  const renderButtons = () => {
    return (
      <>
        {maxHeight !== 'auto' && <ButtonDivider />}
        <Stack
          data-testid="popover-action-container"
          direction={largePopover ? 'row-reverse' : 'column'}
          justifyContent={largePopover ? 'flex-start' : 'center'}
          marginTop="24px"
          spacing={1}
        >
          {_.map(actionButtons, button => {
            const handleClick: React.MouseEventHandler<HTMLElement> = ev => {
              setAnchorEl(null);
              if (button.onClick) {
                button.onClick(ev);
              }
            };

            return (
              <Button key={button.id} {...button} onClick={handleClick}>
                {button.label}
              </Button>
            );
          })}
        </Stack>
      </>
    );
  };

  return (
    <>
      <Button {...anchorButton} onClick={toggleOpen} open={open}>
        {anchorTextLeftAligned ? (
          <PopoverLabel>{anchorButton.label}</PopoverLabel>
        ) : (
          anchorButton.label
        )}
      </Button>
      <MUIPopover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        id={id}
        onClose={handleClose}
        open={open}
        sx={{
          marginTop: '8px',
          '.MuiPaper-root': {
            padding: paperPadding,
            width: `${width}px`,
            minHeight: '276px',
            overflow: 'hidden',
          },
        }}
        transformOrigin={transformOrigin}
      >
        <Stack maxHeight={maxHeight}>
          {header && renderHeader()}
          <ContentContainer
            $overflow={maxHeight !== 'auto'}
            $visibleScrollbar={visibleScrollbar}
          >
            {description && <Text.Description>{description}</Text.Description>}
            {children}
          </ContentContainer>
          {actionButtons && renderButtons()}
        </Stack>
      </MUIPopover>
    </>
  );
};

export default Popover;

const PopoverLabel = styled.div`
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  min-width: 0;
  top: 16px;
  right: 16px;
  padding: 8px;
`;

const Divider = styled(MUIDivider)`
  margin: 0 -24px 24px -24px;
`;

const ButtonDivider = styled(MUIDivider)`
  margin: 0 -24px;
`;

const ContentContainer = styled.div<{
  $overflow: boolean;
  $visibleScrollbar?: boolean;
}>`
  padding-top: 4px
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 124px;
  ${p => p.$overflow && `padding-right: 24px; margin-right: -24px;`}
  ${p =>
    p.$visibleScrollbar &&
    `::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${EVColors.aluminum};
    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 8px;
    box-shadow: none;
    min-height: 50px;
  }
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track:hover {
    background: none;
    border: none;
  }`}
`;
