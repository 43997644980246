enum LoginPaths {
  Login = 'login',
  ForgotPassword = 'forgot_password',
  UpdatePassword = 'update_password',
  AccountLocked = 'account_locked',
  RequestUnlockCode = 'request_unlock_code',
  VerifyUnlockCode = 'verify_unlock_code',
  CreatePatientAccount = 'create_patient_account',
  EnableBiometrics = 'biometrics',
}

export default LoginPaths;
