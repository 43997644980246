import { AppDispatch } from 'ev-store';

import { currentUserApi } from 'ev-api/core';

export function setUserTokenOptimisticUpdate(
  dispatch: AppDispatch,
  newToken: string,
) {
  const action = currentUserApi.util.updateQueryData(
    'getCurrentUser',
    undefined,
    currentUser => {
      currentUser.attributes.vault_access_token = newToken;
    },
  );

  return dispatch(action);
}
