import styled from 'styled-components/macro';

export const HeaderContainer = styled.header.attrs({ 'data-testid': 'header' })`
  display: flex;
  flex-shrink: 0;
  align-items: center;

  padding: 0 24px;
  height: 75px;
`;

export const HeaderLogo = styled.img.attrs({ alt: 'Logo' })`
  margin-right: auto;
  width: 110px;
  max-height: 33px;
`;
