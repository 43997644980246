import _ from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Md5 } from 'ts-md5';

import { Id, Practice, Timestamp, User } from 'ev-types';

import getInsuranceInfo from 'ev-common/Insurance/utils';
import { IS_JEST } from 'ev-config/config';
import Environments from 'ev-types/environments';
import { isProvider } from 'ev-utils/user';

import { useSSOInfo } from 'app-login/hooks/sso';

import {
  useEvaultForUser,
  useNullableCurrentPractice,
  useNullablePrimaryUser,
} from '../commonData';
import { useEnvironment } from '../environment';
import { Analytics, AnalyticsMethods } from './analyticsMethods';

export type GroupAnalyticsCall = {
  id: Id;
  createdAt: Timestamp;
  name: string;
  kind: string;
  status: string;
  parentGroupId?: Id;
};

export type IdentifyUser = {
  id: Id;
  role: string;
  createdAt: string;
  testMode: boolean;
  evApp: string;
  sessionID: string;
  isSSO: boolean;
};

export type IdentifyProviderUser = IdentifyUser & {
  discipline: string;
  email: string;
  fullName: string;
  specialty: string | null;
};

export type IdentifyPatientUser = IdentifyUser & {
  noInsurance?: boolean;
  insuranceVerified?: boolean;
};

export const AnalyticsContext = createContext<{
  trackEvent: (
    eventName: string,
    action?: string,
    actionTarget?: string,
    targetType?: string,
  ) => void;
  analytics: Analytics | null;
}>({
  trackEvent: _.noop,
  analytics: null,
});

export const useLoadAnalytics = () => {
  const location = useLocation();
  const currentUser = useNullablePrimaryUser();
  const currentPractice = useNullableCurrentPractice();
  const environment = useEnvironment();
  const [analytics, setAnalytics] = useState<Analytics | null>(null);
  const { loggedInWithSSO } = useSSOInfo();
  const { evaultRecord } = useEvaultForUser({
    user: currentUser,
    skip: IS_JEST,
  });

  const buildIdentifyCall = useCallback(
    (currentUser: User, currentPractice: Practice, isSSO: boolean) => {
      if (!analytics) {
        return;
      }
      const { role, test_mode, secure_authentication_token } =
        currentUser.attributes;

      const { handle, kind, status, ev_customer_id } =
        currentPractice.attributes;

      const traits: GroupAnalyticsCall = {
        id: currentPractice.id,
        createdAt: currentUser.attributes.created_at,
        name: handle,
        kind: kind,
        status: status,
      };

      let user: IdentifyUser = {
        id: currentUser.id,
        role: role,
        createdAt: currentPractice.attributes.created_at,
        testMode: test_mode,
        evApp: 'RAD',
        sessionID: Md5.hashStr(secure_authentication_token),
        isSSO: isSSO,
      };

      if (isProvider({ user: currentUser, practiceId: currentPractice.id })) {
        user = {
          ...user,
          email: currentUser.attributes.email,
          fullName: currentUser.attributes.full_name,
          specialty: currentUser.attributes.specialty,
          discipline: currentUser.attributes.discipline,
        } as IdentifyProviderUser;
      } else {
        const insuranceInfo = getInsuranceInfo(currentUser, currentPractice);
        user = {
          ...user,
          noInsurance:
            evaultRecord?.insurance.no_insurance ||
            _.isEmpty(evaultRecord?.insurance),
          insuranceVerified: insuranceInfo.insuranceValidated,
        } as IdentifyPatientUser;
      }

      if (ev_customer_id) {
        traits.parentGroupId = ev_customer_id;
      }
      void analytics.identify(user, traits);
    },
    [analytics, evaultRecord?.insurance],
  );

  useEffect(() => {
    if (
      environment !== Environments.Local &&
      environment !== Environments.Test
    ) {
      void (() => {
        const response = AnalyticsMethods.inititialize(currentPractice);
        setAnalytics(response);
      })();
    }
  }, [environment, currentPractice]);

  useEffect(() => {
    if (!currentUser || !currentPractice || !analytics) {
      return;
    }

    if (environment === Environments.Production) {
      buildIdentifyCall(currentUser, currentPractice, loggedInWithSSO);
    }
  }, [
    currentUser,
    currentPractice,
    analytics,
    environment,
    loggedInWithSSO,
    buildIdentifyCall,
  ]);

  useEffect(() => {
    if (environment === Environments.Production && analytics) {
      const pathnameArray = location.pathname.split('/').filter(n => n);
      void analytics.page(
        pathnameArray[1],
        `${pathnameArray[2]}-${pathnameArray[3]}`,
      );
    }
  }, [location.pathname, analytics, environment]);

  const trackEvent = useCallback(
    (
      eventName: string,
      action?: string,
      actionTarget?: string,
      targetType?: string,
    ) => {
      if (environment !== Environments.Local && analytics) {
        void analytics.track(eventName, {
          name: eventName,
          timestamp: new Date().getTime(),
          ...(action && { action: action }),
          ...(actionTarget && { actionTarget: actionTarget }),
          ...(targetType && { targetType: targetType }),
        });
      }
    },
    [analytics, environment],
  );

  return { trackEvent, analytics };
};

export const AnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { trackEvent, analytics } = useLoadAnalytics();

  return (
    <AnalyticsContext.Provider value={{ analytics, trackEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export function useTrackEvent() {
  const { trackEvent } = useContext(AnalyticsContext);
  return trackEvent;
}

export function useAnalytics() {
  const { analytics } = useContext(AnalyticsContext);
  return analytics;
}
