import _ from 'lodash';

import { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';

import { PersonalFilterView } from 'ev-types';

import { PFVDefaultValues } from './personal-filter-view';

export const transformPfv = (
  pfv: PersonalFilterView,
  initialColumnsVisibility: GridColumnVisibilityModel,
) => {
  const storePfv: typeof PFVDefaultValues = {
    selectedPfv: pfv,
    statusFilters: {
      arrived: pfv.attributes.filters.status.arrived,
      ready: pfv.attributes.filters.status.ready,
    },
    gridDensity: pfv.attributes.filters.gridDensity,
    sortBy: pfv.attributes.filters.sortBy,
    columnsProps: pfv.attributes.filters.columnsProps,
    columnFiltering: pfv.attributes.filters.columnFiltering,
    columnSorting: pfv.attributes.filters.columnSorting,
    providerFilters: {},
    columnsVisibility: {},
    initialColumnsVisibility: { ...initialColumnsVisibility },
  };

  _.each(pfv.attributes.filters.providerIds, id => {
    storePfv.providerFilters[id] = true;
  });

  // updated pfvs will use the new columnsVisibility model
  if (!_.isEmpty(pfv.attributes.filters.columnsVisibility)) {
    storePfv.columnsVisibility = {
      ...storePfv.initialColumnsVisibility,
      ...pfv.attributes.filters.columnsVisibility,
    };
  } else {
    storePfv.columnsVisibility = {};
    _.each(pfv.attributes.filters.hiddenFields, id => {
      storePfv.columnsVisibility[id] = false;
    });
  }
  return storePfv;
};
