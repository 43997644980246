// eslint-disable-next-line import/no-namespace
import * as FS from '@fullstory/browser';

import { Practice } from 'ev-types';

import { PracticePreference } from 'ev-types/preference';
import { getPracticePreference } from 'ev-utils/practice';

import {
  GroupAnalyticsCall,
  IdentifyPatientUser,
  IdentifyProviderUser,
} from '.';

export type Analytics = {
  track(...args: unknown[]): unknown;
  page(...args: unknown[]): unknown;
  identify(...args: unknown[]): unknown;
  group?(...args: unknown[]): unknown;
};

const inititialize = (currentPractice: Practice | undefined) => {
  const org_id = process.env.REACT_APP_FULLSTORY_ORG_ID;
  const enabled = getPracticePreference(
    currentPractice,
    PracticePreference.CaptureFullStory,
  );

  if (enabled && org_id) {
    FS.init({ orgId: org_id });
  }

  return {
    track: track,
    identify: identify,
    page: page,
  };
};

function identify(
  user: IdentifyPatientUser | IdentifyProviderUser,
  account: GroupAnalyticsCall,
) {
  window.aptrinsic && window.aptrinsic('identify', { ...user }, { ...account });
  FS.isInitialized() && FS.FullStory('setIdentity', { uid: user.id });
}

function track(eventName: string, args: unknown[]) {
  window.aptrinsic('track', eventName, args);
  FS.isInitialized() &&
    FS.FullStory('trackEvent', { name: eventName, properties: args });
}

function page(args: unknown[]) {
  window.aptrinsic && window.aptrinsic('pageView', args);
  FS.isInitialized() &&
    FS.FullStory('trackEvent', { name: 'pageView', properties: args });
}

export const AnalyticsMethods = {
  inititialize,
};
