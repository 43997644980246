import { ResponseId } from 'ev-types';

import { isObject } from 'ev-utils/types';

export type PharmacyResponse = {
  id: ResponseId;
  type: 'pharmacy';
  attributes: PharmacyAttributesResponse;
};

export const isPharmacyResponse = (
  value: unknown,
): value is PharmacyResponse => {
  return isObject(value) && value.type === 'pharmacy';
};

export type PharmacyAttributesResponse = {
  active: boolean;
  address1: string;
  address2?: string | null;
  city: string;
  dosespot_pharmacy_id: ResponseId;
  id: ResponseId;
  lat?: string;
  lng?: string;
  location?: {
    lat: number;
    lng: number;
  };
  name?: string;
  pharmacy_id?: ResponseId;
  pharmacy_specialties: string[] | string | null;
  primary_fax: string;
  primary_phone: string;
  state: string;
  store_name: string;
  zip_code: string;
  zip?: string;
};

export type PharmacyIdResponse = {
  pharmacy: PharmacyAttributesResponse;
};
