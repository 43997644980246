import { detect } from 'detect-browser';

import {
  Practice,
  PracticeEnablementConfig,
  PracticePreference,
} from 'ev-types';

import { IS_JEST } from 'ev-config/config';
import {
  getPracticeEnablementConfig,
  getPracticePreference,
} from 'ev-utils/practice';

// Supported browsers: https://docs.google.com/document/d/1EbekRrPKi2MroBsNHm4mwJcWl85UCxnTojGLazq3Qq8/edit#heading=h.3hblry5lc636
// RA version: app/evisit.js:isBrowserUnsupported()

function hasTorBehavior() {
  return [
    window.innerWidth,
    window.innerHeight,
    window.outerWidth,
    window.outerHeight,
    window.screen.width,
  ].every(val => val % 100 === 0);
}

const useBrowserCompatibilityCheck = (practice?: Practice) => {
  const browserInfo = detect() || { os: '', type: '', name: '', version: '' };
  const { os, type, name, version } = browserInfo;

  const allowUnsupportedBrowsers = getPracticePreference(
    practice,
    PracticePreference.AllowUnsupportedBrowsers,
  );
  const blockSafari12 = getPracticeEnablementConfig(
    practice,
    PracticeEnablementConfig.BlockSafari12,
  );

  const isBrowserSupported = (): boolean => {
    if (allowUnsupportedBrowsers || /bot|bot-device/.test(type)) {
      return true;
    }

    if (type !== 'browser') {
      return IS_JEST;
    }

    if (isUnsupportedSafari()) {
      return false;
    }

    if (isTorBrowser()) {
      return false;
    }

    return isSupportedOS();
  };

  const isUnsupportedSafari = () => {
    return (
      blockSafari12 &&
      /safari/i.test(name) &&
      parseInt(version ?? '0', 10) <= 12
    );
  };

  const isTorBrowser = () => {
    return /firefox/i.test(name) && hasTorBehavior();
  };

  const isSupportedOS = () => {
    if (os) {
      if (/Mac OS/i.test(os)) {
        return /chrome|firefox|brave|safari|ios-webview/i.test(name);
      } else if (/Windows/i.test(os)) {
        return /chrome|firefox|brave|edge/i.test(name);
      } else if (/iOS/i.test(os)) {
        return /safari|ios|crios/i.test(name) && !/fxios/i.test(name);
      } else if (/Android OS/i.test(os)) {
        return /chrome|chromium|brave/i.test(name);
      } else if (/Linux/i.test(os) || /Chrome OS/i.test(os)) {
        return /chrome|firefox/i.test(name);
      }
    }

    // Unrecognized OS
    return false;
  };

  return isBrowserSupported();
};

export default useBrowserCompatibilityCheck;
