import { ToggleSize } from 'ev-components/Toggle';
import { EVColors } from 'ev-theme/styles';

declare module '@mui/material/Switch' {
  interface SwitchPropsSizeOverrides {
    large: true;
    extraLarge: true;
  }
}

export const MuiSwitch = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      padding: 0,
      height: 32,
      width: 58,
      '& .MuiSwitch-track': {
        backgroundColor: EVColors.aluminum,
        borderRadius: 100,
        opacity: 1,
        margin: 'auto',
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: `translateY(-50%)`,
          height: 16,
          width: 16,
        },
        '&:before': {
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url('data:image/svg+xml;utf8,<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="white" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>')`,
          left: 10,
        },
        '&:after': {
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" class="svg-inline--fa fa-times fa-w-10 fa-fw Icon__StyledIcon-sc-ydqv6k-0 ecRsnr" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" margin="0"><path fill="${encodeURIComponent(
            '#4F6077',
          )}" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>')`,
          right: 12,
        },
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: EVColors.white,
        color: EVColors.cerulean,
        boxShadow: 'none',
      },
      '&.display-focus, & .Mui-focusVisible': {
        '& .MuiSwitch-track': {
          boxShadow: `0 0 0 4px ${EVColors.cornflower}`,
          borderRadius: 100,
        },
      },
      '&.display-hover, &:hover': {
        '& .MuiSwitch-thumb': {
          boxShadow: `0 0 0 4px ${EVColors.zircon}`,
        },
      },
    },
    switchBase: {
      padding: 0,
      transitionDuration: `300ms`,
      '&.Mui-disabled': {
        '& .MuiSwitch-track': {
          backgroundColor: EVColors.concrete,
          color: EVColors.mercury,
        },
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: EVColors.concrete,
          color: EVColors.mercury,
        },
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        '&:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="${encodeURIComponent(
            '#B6BECA',
          )}" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"></path></svg>')`,
        },
        '&:after': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="${encodeURIComponent(
            '#000000',
          )}" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>')`,
        },
      },
      '&.Mui-checked + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: EVColors.mediumGreen,
      },
      '&.Mui-checked': {
        transform: 'translateX(26px)',
      },
      '&.Mui-focusVisible + .MuiSwitch-track': {
        boxShadow: `0 0 0 4px ${EVColors.cornflower}`,
        borderRadius: 100,
      },
    },
  },
  variants: [
    {
      props: {
        size: ToggleSize.Small,
      },
      style: {
        width: 40,
        height: 24,
        '& .MuiSwitch-thumb': {
          height: 12,
          width: 12,
          margin: 2,
        },
        '& .MuiSwitch-track': {
          height: 16,
          width: 32,
          '&:before, &:after': {
            height: 10,
            width: 10,
          },
          '&:before': {
            left: 8,
          },
          '&:after': {
            right: 8,
          },
        },
      },
    },
    {
      props: {
        size: ToggleSize.Medium,
      },
      style: {
        width: 45,
        height: 28,
        '& .MuiSwitch-thumb': {
          height: 14,
          width: 14,
          margin: '7px 6px',
        },
        '& .MuiSwitch-track': {
          height: 18,
          width: 36,
          '&:before, &:after': {
            height: 11,
            width: 11,
            right: 8,
          },
        },
        '& .MuiSwitch-switchBase': {
          '&.Mui-checked': {
            transform: 'translateX(18px)',
          },
        },
      },
    },
    {
      props: {
        size: ToggleSize.Large,
      },
      style: {
        width: 55,
        height: 33,
        '& .MuiSwitch-thumb': {
          height: 19,
          width: 19,
          margin: 7,
        },
        '& .MuiSwitch-track': {
          height: 23,
          width: 46,
          '&:before, &:after': {
            height: 14,
            width: 14,
          },
        },
        '& .MuiSwitch-switchBase': {
          '&.Mui-checked': {
            transform: 'translateX(22px)',
          },
        },
      },
    },
    {
      props: {
        size: ToggleSize.ExtraLarge,
      },
      style: {
        width: 65,
        height: 35,
        '& .MuiSwitch-thumb': {
          height: 20,
          width: 20,
          margin: '8px 9px',
        },
        '& .MuiSwitch-track': {
          height: 24,
          width: 50,
          '&:before, &:after': {
            height: 16,
            width: 16,
            marginLeft: '3px',
          },
        },
      },
    },
  ],
};
