import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import {
  convertToDateRangeValue,
  DateRangeShortcutType,
  DateRangeValue,
  getDateRangeShortcutValue,
} from 'ev-components/DateRange';

import {
  chartFilters,
  chartInitialFilter,
  commonFilters,
  commonInitialState,
  DateFormat,
  initialProviderFilter,
  providersFilter,
} from './common';

const initialState = {
  ...commonInitialState,
  ...chartInitialFilter,
  ...initialProviderFilter,
  kindFilter: {
    onDemand: false,
    scheduled: false,
  },
  statusFilter: {
    completed: false,
    expired: false,
    incomplete: false,
  },
  dateRangeFilter: {
    format: DateFormat,
    value: convertToDateRangeValue(
      getDateRangeShortcutValue(DateRangeShortcutType.Past14Days),
      DateFormat,
    ),
  },
};

export const visitHistoryViewSlice = createSlice({
  name: 'visitHistoryViewSlice',
  initialState,
  reducers: {
    ...commonFilters,
    ...chartFilters,
    ...providersFilter,
    setKindScheduledFilter(state, { payload }: PayloadAction<boolean>) {
      state.kindFilter.scheduled = payload;
    },
    setKindDemandFilter(state, { payload }: PayloadAction<boolean>) {
      state.kindFilter.onDemand = payload;
    },
    clearKindFilter(state) {
      state.kindFilter = {
        onDemand: false,
        scheduled: false,
      };
    },
    setStatusCompleteFilter(state, action: PayloadAction<boolean>) {
      state.statusFilter.completed = action.payload;
    },
    setStatusExpiredFilter(state, action: PayloadAction<boolean>) {
      state.statusFilter.expired = action.payload;
    },
    setStatusIncompleteFilter(state, action: PayloadAction<boolean>) {
      state.statusFilter.incomplete = action.payload;
    },

    clearVisitStatusFilter(state) {
      state.statusFilter = {
        completed: false,
        expired: false,
        incomplete: false,
      };
    },
    setDateRangeFilter(state, action: PayloadAction<DateRangeValue>) {
      state.dateRangeFilter.value = action.payload;
    },
    clearDateRangeFilter(state) {
      state.dateRangeFilter.value = convertToDateRangeValue(
        getDateRangeShortcutValue(DateRangeShortcutType.Past14Days),
        DateFormat,
      );
    },
    resetFilters(state) {
      _.each(initialState, (object, key) => _.set(state, key, object));
    },
  },
});

export const {
  clearChartActions,
  clearKindFilter,
  clearProvidersFilter,
  clearVisitStatusFilter,
  resetFilters,
  selectProvider,
  setChartCoSignFilter,
  setChartSignFilter,
  setColumnFiltering,
  setColumnsVisibility,
  setColumnSorting,
  setDateRangeFilter,
  setDensity,
  setKindDemandFilter,
  setKindScheduledFilter,
  setSearchFilter,
  setStatusCompleteFilter,
  setStatusExpiredFilter,
  setStatusIncompleteFilter,
  toggleFieldVisibility,
} = visitHistoryViewSlice.actions;
