import { MIMIC_SIGN_IN_URL } from 'ev-config/config';

export function redirect(location: string) {
  window.location.assign(location);
}

export function refresh() {
  window.location.reload();
}

export function newWindow(
  location: string,
  target?: '_self' | '_blank' | '_parent' | '_top',
) {
  return window.open(location, target);
}

type BuildParamsOptions = {
  exclude?: string;
  include?: string;
};

export function buildParamsMap(
  params: URLSearchParams,
  { exclude, include }: BuildParamsOptions = {},
) {
  const result: Record<string, string> = {};
  params.forEach((value, key) => {
    if ((exclude && key !== exclude) || (include && key === include)) {
      result[key] = value;
    }
  });

  return result;
}

export function mimicUserInOtherTab(authToken: string) {
  const formElement = window.document.createElement('form');

  formElement.setAttribute('action', MIMIC_SIGN_IN_URL);
  formElement.setAttribute('method', 'POST');
  formElement.setAttribute('target', '_blank');

  formElement.innerHTML = `
            <input type="hidden" name="user[secure_authentication_token]" value="${authToken}" />
            <input type="text" name="redirect_to" value="/" />
          `;

  window.document.body.append(formElement);
  formElement.submit();
  formElement.remove();
}

export function windowIsTop() {
  return window === window.top;
}
