import { EVColors } from 'ev-theme/styles';

export const MuiFormControlLabel = {
  styleOverrides: {
    label: {
      color: EVColors.gunpowder,
      textAlign: 'left' as const,
      '&.Mui-disabled': {
        color: EVColors.mercury,
      },
    },
  },
};
