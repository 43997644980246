import { EVColors } from 'ev-theme/styles';

export const MuiListSubheader = {
  styleOverrides: {
    root: {
      fontWeight: 600,
      color: EVColors.gunpowder,
    },
  },
};
