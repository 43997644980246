import { createLinearGradient, rgbToRgba } from 'ev-utils/color';

import { PrimitiveColors } from './PrimitiveColors';

export const EVColors = {
  black: 'rgb(0,0,0)',
  white: PrimitiveColors.primary.neutral[0],
  vulcan: PrimitiveColors.primary.neutral[800],
  asphalt: PrimitiveColors.primary.neutral[700],
  gunpowder: PrimitiveColors.primary.neutral[600],
  mako: PrimitiveColors.primary.neutral[500],
  storm: PrimitiveColors.primary.neutral[400],
  stone: PrimitiveColors.primary.neutral[300],
  mercury: PrimitiveColors.primary.neutral[200],
  aluminum: PrimitiveColors.primary.neutral[100],
  silverBullet: PrimitiveColors.primary.neutral[75],
  concrete: PrimitiveColors.primary.neutral[50],
  alabaster: PrimitiveColors.primary.neutral[25],
  openBlue: PrimitiveColors.primary.brandBlue[700],
  cobalt: PrimitiveColors.primary.brandBlue[500],
  cerulean: PrimitiveColors.primary.brandBlue[400],
  cornflower: PrimitiveColors.primary.brandBlue[300],
  selectedHover: PrimitiveColors.primary.brandBlue[50],
  zircon: PrimitiveColors.primary.brandBlue[25],
  darkBrown: PrimitiveColors.status.yellow[800],
  bronze: PrimitiveColors.status.yellow[700],
  appointmentYellow: PrimitiveColors.status.yellow[400],
  sunflower: PrimitiveColors.status.yellow[300],
  sorbet: PrimitiveColors.status.yellow[50],
  evisitTeal: PrimitiveColors.secondary.teal[500],
  periwinkle: PrimitiveColors.secondary.teal[25],
  darkRed: PrimitiveColors.status.red[400],
  maraschino: PrimitiveColors.status.red[300],
  lightRed: PrimitiveColors.status.red[200],
  sakura: PrimitiveColors.status.red[25],
  hotPink: PrimitiveColors.secondary.pink[400],
  shortcake: PrimitiveColors.secondary.pink[25],
  darkGreen: PrimitiveColors.status.green[700],
  mediumGreen: PrimitiveColors.status.green[500],
  lightGreen: PrimitiveColors.status.green[200],
  mint: PrimitiveColors.status.green[25],
  mediumOrange: PrimitiveColors.secondary.orange[400],
  paleOrange: PrimitiveColors.secondary.orange[25],
  onDemandPurple: PrimitiveColors.secondary.purple[500],
  avatar: {
    purple: PrimitiveColors.secondary.purple[700],
    purpleDark: PrimitiveColors.secondary.purple[800],
    purpleLight: PrimitiveColors.secondary.purple[500],
    pink: PrimitiveColors.secondary.pink[700],
    pinkDark: PrimitiveColors.secondary.pink[800],
    pinkLight: PrimitiveColors.secondary.pink[500],
    teal: PrimitiveColors.secondary.teal[700],
    tealDark: PrimitiveColors.secondary.teal[800],
    tealLight: PrimitiveColors.secondary.teal[500],
    orange: PrimitiveColors.secondary.orange[700],
    orangeDark: PrimitiveColors.secondary.orange[800],
    orangeLight: PrimitiveColors.secondary.orange[500],
  },
  decorative: {
    blue: PrimitiveColors.primary.brandBlue[400],
    pink: PrimitiveColors.secondary.pink[400],
    teal: PrimitiveColors.secondary.teal[400],
    orange: PrimitiveColors.secondary.orange[400],
    blueGradient: createLinearGradient(
      PrimitiveColors.primary.brandBlue[200],
      PrimitiveColors.primary.brandBlue[400],
    ),
    purpleGradient: createLinearGradient(
      PrimitiveColors.secondary.purple[200],
      PrimitiveColors.secondary.purple[400],
    ),
    pinkGradient: createLinearGradient(
      PrimitiveColors.secondary.pink[200],
      PrimitiveColors.secondary.pink[400],
    ),
    tealGradient: createLinearGradient(
      PrimitiveColors.secondary.teal[200],
      PrimitiveColors.secondary.teal[400],
    ),
    orangeGradient: createLinearGradient(
      PrimitiveColors.secondary.orange[200],
      PrimitiveColors.secondary.orange[400],
    ),
    yellowGradient: createLinearGradient(
      PrimitiveColors.status.yellow[200],
      PrimitiveColors.status.yellow[400],
    ),
    greenGradient: createLinearGradient(
      PrimitiveColors.status.green[200],
      PrimitiveColors.status.green[400],
    ),
  },
  surface: {
    background: PrimitiveColors.primary.neutral[0],
    backgroundHover: PrimitiveColors.primary.neutral[50],
    subtle: PrimitiveColors.primary.neutral[25],
    strong: PrimitiveColors.primary.neutral[50],
    selected: PrimitiveColors.primary.brandBlue[25],
    disabled: PrimitiveColors.primary.neutral[75],
    input: PrimitiveColors.primary.neutral[0],
    inputHover: PrimitiveColors.primary.neutral[50],
    inverse: PrimitiveColors.primary.neutral[800],
    inverseHover: PrimitiveColors.primary.neutral[600],
    brand: PrimitiveColors.primary.brandBlue[25],
    brandInverse: PrimitiveColors.primary.brandBlue[400],
    brandInverseHover: PrimitiveColors.primary.brandBlue[500],
    brandContrast: PrimitiveColors.primary.brandBlue[500],
    information: PrimitiveColors.primary.neutral[0],
    danger: PrimitiveColors.status.red[25],
    dangerHover: PrimitiveColors.status.red[200],
    dangerInverse: PrimitiveColors.status.red[400],
    warning: PrimitiveColors.status.yellow[50],
    warningHover: PrimitiveColors.status.yellow[200],
    warningInverse: PrimitiveColors.status.yellow[300],
    success: PrimitiveColors.status.green[25],
    successHover: PrimitiveColors.status.green[200],
    successInverse: PrimitiveColors.status.green[700],
    neutralGradient: createLinearGradient(
      rgbToRgba(PrimitiveColors.primary.neutral[500], 0.1),
      rgbToRgba(PrimitiveColors.primary.neutral[500], 0),
    ),
    infoGradient: createLinearGradient(
      rgbToRgba(PrimitiveColors.primary.brandBlue[400], 0.1),
      rgbToRgba(PrimitiveColors.primary.brandBlue[400], 0),
    ),
    successGradient: createLinearGradient(
      rgbToRgba(PrimitiveColors.status.green[500], 0.1),
      rgbToRgba(PrimitiveColors.status.green[500], 0),
    ),
    warningGradient: createLinearGradient(
      rgbToRgba(PrimitiveColors.status.yellow[400], 0.1),
      rgbToRgba(PrimitiveColors.status.yellow[400], 0),
    ),
    dangerGradient: createLinearGradient(
      rgbToRgba(PrimitiveColors.status.red[300], 0.1),
      rgbToRgba(PrimitiveColors.status.red[300], 0),
    ),
    overlay: rgbToRgba(PrimitiveColors.primary.neutral[800], 0.2),
    ptzControls: rgbToRgba(PrimitiveColors.primary.neutral[800], 0.6),
    opaqueBlack: rgbToRgba(PrimitiveColors.primary.neutral[800], 0.8),
  },
  border: {
    default: PrimitiveColors.primary.neutral[100],
    strong: PrimitiveColors.primary.neutral[200],
    subtle: PrimitiveColors.primary.neutral[75],
    disabled: PrimitiveColors.primary.neutral[50],
    inputField: PrimitiveColors.primary.neutral[200],
    focus: PrimitiveColors.primary.brandBlue[300],
    focusDark: PrimitiveColors.primary.brandBlue[500],
    focusLight: PrimitiveColors.primary.brandBlue[50],
    selected: PrimitiveColors.primary.brandBlue[400],
    danger: PrimitiveColors.status.red[300],
  },
  text: {
    default: PrimitiveColors.primary.neutral[600],
    defaultHover: PrimitiveColors.primary.neutral[800],
    subtle: PrimitiveColors.primary.neutral[500],
    subtleHover: PrimitiveColors.primary.neutral[600],
    strong: PrimitiveColors.primary.neutral[700],
    contrast: PrimitiveColors.primary.neutral[800],
    selected: PrimitiveColors.primary.brandBlue[400],
    placeholder: PrimitiveColors.primary.neutral[400],
    disabled: PrimitiveColors.primary.neutral[200],
    inverse: PrimitiveColors.primary.neutral[25],
    brand: PrimitiveColors.primary.brandBlue[400],
    brandHover: PrimitiveColors.primary.brandBlue[500],
    brandContrast: PrimitiveColors.primary.brandBlue[500],
    brandContrastHover: PrimitiveColors.primary.brandBlue[600],
    link: PrimitiveColors.primary.brandBlue[400],
    linkHover: PrimitiveColors.primary.brandBlue[500],
    informational: PrimitiveColors.primary.brandBlue[800],
    danger: PrimitiveColors.status.red[600],
    dangerContrast: PrimitiveColors.status.red[800],
    warning: PrimitiveColors.status.yellow[600],
    warningContrast: PrimitiveColors.status.yellow[800],
    success: PrimitiveColors.status.green[600],
    successContrast: PrimitiveColors.status.green[800],
  },
  icon: {
    default: PrimitiveColors.primary.neutral[500],
    contrast: PrimitiveColors.primary.neutral[600],
    hovered: PrimitiveColors.primary.neutral[700],
    subtle: PrimitiveColors.primary.neutral[400],
    disabled: PrimitiveColors.primary.neutral[400],
    inverse: PrimitiveColors.primary.neutral[0],
    brand: PrimitiveColors.primary.brandBlue[400],
    selected: PrimitiveColors.primary.brandBlue[400],
    informational: PrimitiveColors.primary.neutral[400],
    danger: PrimitiveColors.status.red[400],
    dangerHover: PrimitiveColors.status.red[600],
    warning: PrimitiveColors.status.yellow[500],
    warningHover: PrimitiveColors.status.yellow[600],
    success: PrimitiveColors.status.green[500],
    successHover: PrimitiveColors.status.green[600],
  },
} as const;

export const PaletteTheme = {
  mode: 'light',
  common: {
    black: EVColors.black,
    white: EVColors.white,
  },
  primary: {
    main: EVColors.cerulean,
    dark: EVColors.cobalt,
    light: EVColors.cornflower,
  },
  error: {
    main: EVColors.maraschino,
    contrastText: EVColors.shortcake,
  },
  warning: {
    main: EVColors.sorbet,
    contrastText: EVColors.bronze,
  },
  success: {
    main: EVColors.mint,
    contrastText: EVColors.mediumGreen,
  },
  info: {
    main: EVColors.concrete,
    contrastText: EVColors.mako,
  },
  text: {
    primary: EVColors.vulcan,
    secondary: EVColors.storm,
    disabled: EVColors.mercury,
  },
  background: {
    default: EVColors.white,
  },
} as const;
