import { CondensedVisits, VisitsForPatient } from 'ev-types';

import api, { Base, Tags } from 'ev-api/api';
import { sanitizeAndTransformResponseData } from 'ev-api/common/transformers';
import { getVisitsTransformer } from 'ev-api/core/visits/transformers';
import { clientDateFormatter } from 'ev-utils/formatters';

import {
  GetScheduledVisitsParams,
  ScheduleAppointmentParams,
  UpdateScheduleAppointmentParams,
  VisitsForPatientParams,
} from './params';
import { ScheduleAppointmentResponse } from './responses';

export const schedulingApi = api.injectEndpoints({
  endpoints: builder => ({
    getVisitsForPatient: builder.query<
      VisitsForPatient,
      VisitsForPatientParams
    >({
      query: ({ practiceId, withInitiator }) => ({
        url: `${Base.V2}/scheduling/visits_for_patient`,
        params: {
          practice_id: practiceId,
          with_initiator: withInitiator,
        },
      }),
      providesTags: [Tags.Visits],
      transformResponse: sanitizeAndTransformResponseData,
    }),
    getScheduledVisits: builder.query<
      CondensedVisits,
      GetScheduledVisitsParams
    >({
      query: ({
        offset,
        status,
        search,
        beginning,
        through,
        providerIds,
        patientId,
        limit,
        sortBy,
        filters,
        actionsRequired,
      }) => {
        return {
          url: `${Base.V3}/scheduling/visits`,
          body: {
            offset,
            status,
            search,
            beginning: beginning ? clientDateFormatter(beginning) : null,
            through: through ? clientDateFormatter(through) : null,
            physician_id: providerIds,
            patient_id: patientId,
            sort_by: sortBy,
            limit,
            filters,
            actions_required: actionsRequired,
          },
          method: 'POST',
        };
      },
      providesTags: [Tags.Visits, Tags.CustomNotes],
      transformResponse: getVisitsTransformer,
    }),
    scheduleAppointment: builder.mutation<
      ScheduleAppointmentResponse,
      ScheduleAppointmentParams
    >({
      query: ({
        date,
        healthRecord,
        patientId,
        patientLocation,
        practiceId,
        providerId,
        visitTypeId,
        chartFromVisitId,
      }) => ({
        url: `${Base.V3}/scheduling/make_appointment.json`,
        body: {
          date_time: date,
          health_record: healthRecord,
          patient_id: patientId,
          patient_location: patientLocation,
          practice_id: practiceId,
          provider_id: providerId,
          visit_type_id: visitTypeId,
          chart_from_visit_id: chartFromVisitId,
        },
        method: 'POST',
      }),
      invalidatesTags: [Tags.Visits, Tags.Schedule],
      transformResponse: sanitizeAndTransformResponseData,
    }),
    updateScheduledAppointment: builder.mutation<
      ScheduleAppointmentResponse,
      UpdateScheduleAppointmentParams
    >({
      query: ({
        date,
        patientId,
        patientLocation,
        practiceId,
        providerId,
        visitId,
        visitTypeId,
      }) => ({
        url: `${Base.V3}/scheduling/appointments/${visitId}`,
        body: {
          date_time: date,
          patient_id: patientId,
          patient_location: patientLocation,
          practice_id: practiceId,
          provider_id: providerId,
          visit_type_id: visitTypeId,
        },
        method: 'PUT',
      }),
      invalidatesTags: [Tags.Visits, Tags.Schedule],
      transformResponse: sanitizeAndTransformResponseData,
    }),
  }),
});

export const {
  useGetVisitsForPatientQuery,
  useGetScheduledVisitsQuery,
  useScheduleAppointmentMutation,
  useUpdateScheduledAppointmentMutation,
} = schedulingApi;
