import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Id, PharmacyAttributes } from 'ev-types';

type SelectedVisit = {
  visitId: Id | null;
  preferredPharmacy?: PharmacyAttributes;
};
const selectedVisitInitialState = { visitId: null } as SelectedVisit;

export const selectedVisitSlice = createSlice({
  name: 'selectedVisit',
  initialState: selectedVisitInitialState,
  reducers: {
    selectVisit(state, action: PayloadAction<SelectedVisit>) {
      state.visitId = action.payload.visitId;
    },
    setVisitPreferredPharmacy(
      state,
      action: PayloadAction<PharmacyAttributes>,
    ) {
      state.preferredPharmacy = action.payload;
    },
    clearSelectedVisit(state) {
      state.visitId = null;
      state.preferredPharmacy = undefined;
    },
  },
});
