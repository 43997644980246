import styled from 'styled-components/macro';

import IconButton from '@mui/material/IconButton';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { EVColors } from 'ev-theme/styles';

import Icon from './Icon';

// DEPRECATED, USE BUTTON ON IT'S TERTIARY VARIANT INSTEAD
export type ActionButtonProps = {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon: IconDefinition;
  iconSize?: SizeProp;
  id: string;
  label: string;
  color?: 'primary' | 'success';
  className?: string;
  disabled?: boolean;
  displayLabel?: boolean;
  iconColor?: string;
  padding?: string;
};

/**
 * @deprecated USE BUTTON ON IT'S TERTIARY VARIANT INSTEAD
 */
const ActionButtonDeprecated = ({
  children,
  onClick,
  icon,
  id,
  label,
  color = 'primary',
  className = '',
  iconSize,
  disabled = false,
  displayLabel = true,
  iconColor,
  padding = '25px',
}: ActionButtonProps) => {
  return (
    <Button
      $padding={padding}
      aria-label={label}
      className={className}
      color={color}
      data-testid={id}
      disabled={disabled}
      id={id}
      onClick={onClick}
      disableRipple
    >
      <Icon color={iconColor} icon={icon} size={iconSize} />
      {(children || displayLabel) && (
        <Inner>{children || (displayLabel ? label : '')}</Inner>
      )}
    </Button>
  );
};

export default ActionButtonDeprecated;

const Button = styled(IconButton)<{ $padding: string; disabled: boolean }>`
  flex-direction: column;
  font-size: 18px;
  border-radius: 0;
  padding: ${p => p.$padding};

  &.Mui-focusVisible {
    border: 2px dashed ${EVColors.cerulean};
  }
  // class used to make an X flip on an icon
  &.ev-turn-down {
    svg {
      transform: scaleY(-1);
    }
  }
  ${({ disabled }) =>
    disabled &&
    `
    svg {
      color: ${EVColors.aluminum};
    }
  `}
`;

const Inner = styled.div`
  margin-top: 8px;
  font-size: 14px;
`;
