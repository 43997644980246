export type RGBString = `rgb(${number},${number},${number})`;

export const rgbToRgba = (rgb: RGBString, opacity: number) => {
  const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

  if (!match) {
    console.error('Invalid RGB string');
    return rgb;
  } else if (opacity < 0 || opacity > 1) {
    console.error('Invalid opacity value');
    return rgb;
  }

  const [, r, g, b] = match.map(val => parseInt(val));
  return `rgba(${r},${g},${b},${opacity})`;
};

export const createLinearGradient = (
  color1: string,
  color2: string,
  direction?: string,
) => `linear-gradient(${direction ?? '90deg'},${color1},${color2})`;
