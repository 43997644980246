import { IS_JEST, isAndroid } from 'ev-config/config';

type Request = {
  id: number;
  data: object;
  resolve: CallableFunction;
  timer: 0 | undefined | ReturnType<typeof setTimeout>;
};

type Response = {
  id?: number;
  result?: object | string;
};

class ReactNativeBridge {
  private queue = new Map<number, Request>();
  private counter = 0;
  private initialized = false;

  private init() {
    if (this.initialized) {
      return;
    }

    this.initialized = !IS_JEST;

    (isAndroid ? document : window).addEventListener('message', e => {
      const event = e as MessageEvent;

      if (!event.data || typeof event.data !== 'string') {
        return;
      }

      let response: Response = {};

      try {
        response = JSON.parse(event.data);
      } catch (e) {
        /* empty */
      }

      const { id, result } = response;

      if (!id) {
        return;
      }

      const request = this.queue.get(id);

      if (!request) {
        return;
      }

      request.timer && clearTimeout(request.timer);
      request.resolve(result);
      this.queue.delete(id);
    });
  }

  public sendMessage(data: object, timeout?: number): Promise<string | object> {
    this.init();
    return new Promise((resolve, reject) => {
      const id = ++this.counter;
      const timer =
        timeout &&
        setTimeout(() => {
          this.queue.delete(id);
          reject(new Error('Request timed out'));
        }, timeout);
      const request: Request = {
        id,
        data,
        resolve,
        timer,
      };
      this.queue.set(id, request);
      window.ReactNativeWebView.postMessage(JSON.stringify(request));
    });
  }
}

const Bridge = new ReactNativeBridge();
export default Bridge;
