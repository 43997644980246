import { Components } from '@mui/material/styles/components';

import { EVColors } from 'ev-theme/styles';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    toggleBadge: true;
    toggleStatus: true;
  }
}

export const MuiChip: Components['MuiChip'] = {
  variants: [
    {
      props: {
        variant: 'toggleBadge',
      },
      style: {
        height: 16,
        padding: `0 6px`,
        backgroundColor: EVColors.concrete,
        color: EVColors.mako,
        marginLeft: 10,
        fontSize: 14,
        '& .MuiChip-label': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    {
      props: {
        variant: 'toggleStatus',
      },
      style: {
        width: 6,
        height: 6,
        marginRight: 6,
        borderRadius: `100%`,
        '&.MuiChip-colorSuccess': {
          backgroundColor: EVColors.mediumGreen,
        },
        '&.MuiChip-colorError': {
          backgroundColor: EVColors.maraschino,
        },
      },
    },
  ],
};
