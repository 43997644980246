export const PrimitiveColors = {
  primary: {
    neutral: {
      800: 'rgb(17,24,39)',
      700: 'rgb(31,41,55)',
      600: 'rgb(55,65,81)',
      500: 'rgb(79,96,119)',
      400: 'rgb(132,144,171)',
      300: 'rgb(153,163,180)',
      200: 'rgb(182,190,202)',
      100: 'rgb(209,213,219)',
      75: 'rgb(235,236,240)',
      50: 'rgb(243,244,246)',
      25: 'rgb(249,250,251)',
      0: 'rgb(255,255,255)',
    },
    brandBlue: {
      800: 'rgb(4,4,64)',
      700: 'rgb(7,13,111)',
      600: 'rgb(12,30,160)',
      500: 'rgb(22,57,206)',
      400: 'rgb(40,95,245)',
      300: 'rgb(78,126,255)',
      200: 'rgb(127,166,255)',
      100: 'rgb(184,209,255)',
      75: 'rgb(213,228,255)',
      50: 'rgb(227,236,255)',
      25: 'rgb(240,244,255)',
    },
  },
  secondary: {
    pink: {
      800: 'rgb(64,4,24)',
      700: 'rgb(113,8,43)',
      600: 'rgb(165,16,66)',
      500: 'rgb(214,30,91)',
      400: 'rgb(255,54,121)',
      300: 'rgb(255,91,150)',
      200: 'rgb(255,139,184)',
      100: 'rgb(255,194,221)',
      75: 'rgb(255,220,236)',
      50: 'rgb(255,230,241)',
      25: 'rgb(255,240,245)',
    },
    purple: {
      800: 'rgb(50,4,64)',
      700: 'rgb(89,14,111)',
      600: 'rgb(131,33,160)',
      500: 'rgb(159,49,194)',
      400: 'rgb(183,73,221)',
      300: 'rgb(201,105,239)',
      200: 'rgb(219,147,250)',
      100: 'rgb(236,197,255)',
      75: 'rgb(244,221,255)',
      50: 'rgb(246,230,255)',
      25: 'rgb(249,240,255)',
    },
    teal: {
      800: 'rgb(4,62,64)',
      700: 'rgb(11,101,104)',
      600: 'rgb(22,142,146)',
      500: 'rgb(41,181,185)',
      400: 'rgb(64,209,213)',
      300: 'rgb(97,228,232)',
      200: 'rgb(139,242,245)',
      100: 'rgb(191,251,253)',
      75: 'rgb(216,253,255)',
      50: 'rgb(228,254,255)',
      25: 'rgb(240,254,255)',
    },
    orange: {
      800: 'rgb(64,33,4)',
      700: 'rgb(111,55,2)',
      600: 'rgb(161,77,0)',
      500: 'rgb(207,100,0)',
      400: 'rgb(246,118,0)',
      300: 'rgb(255,147,43)',
      200: 'rgb(255,179,98)',
      100: 'rgb(255,214,162)',
      75: 'rgb(255,231,199)',
      50: 'rgb(255,239,220)',
      25: 'rgb(255,247,240)',
    },
  },
  status: {
    green: {
      800: 'rgb(4,64,44)',
      700: 'rgb(4,103,68)',
      600: 'rgb(4,144,91)',
      500: 'rgb(6,167,100)',
      400: 'rgb(29,210,122)',
      300: 'rgb(65,230,135)',
      200: 'rgb(112,244,152)',
      100: 'rgb(169,252,182)',
      75: 'rgb(202,254,206)',
      50: 'rgb(221,254,222)',
      25: 'rgb(240,255,240)',
    },
    yellow: {
      800: 'rgb(64,48,4)',
      700: 'rgb(111,83,7)',
      600: 'rgb(160,120,12)',
      500: 'rgb(206,156,22)',
      400: 'rgb(245,188,40)',
      300: 'rgb(255,207,78)',
      200: 'rgb(255,225,127)',
      100: 'rgb(255,242,184)',
      75: 'rgb(255,248,213)',
      50: 'rgb(255,249,227)',
      25: 'rgb(255,250,240)',
    },
    red: {
      800: 'rgb(64,4,9)',
      700: 'rgb(106,2,11)',
      600: 'rgb(150,0,12)',
      500: 'rgb(191,0,15)',
      400: 'rgb(225,0,17)',
      300: 'rgb(243,40,55)',
      200: 'rgb(253,93,105)',
      100: 'rgb(255,156,164)',
      75: 'rgb(255,194,199)',
      50: 'rgb(255,217,220)',
      25: 'rgb(255,240,241)',
    },
  },
} as const;
