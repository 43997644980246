import { createContext, useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { isIOSChrome, isReactNative } from 'ev-config/config';
import { BreakPoints } from 'ev-static/breakpoints';

import useEvents from './events';

export const layoutContext = createContext({
  width: 0,
  height: 0,
});

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEvents({
    resize() {
      // There seems to be a bug related to innerWidth / innerHeight values in iOS / Chrome that has not yet been fixed (although claimed to be fixed)
      // https://bugs.chromium.org/p/chromium/issues/detail?id=1174435
      // https://stackoverflow.com/questions/73493417/ios-chrome-yields-incorrect-window-innerwidth-and-innerheight

      setWidth(
        isIOSChrome || isReactNative()
          ? window.document.body.clientWidth
          : window.innerWidth,
      );

      setHeight(
        isIOSChrome || isReactNative()
          ? window.document.body.clientHeight
          : window.innerHeight,
      );
    },
  });

  return (
    <layoutContext.Provider value={{ width, height }}>
      {children}
    </layoutContext.Provider>
  );
};

export default function useLayout() {
  const { width, height } = useContext(layoutContext);
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    const { layout, hide, embedded } = Object.fromEntries(searchParams);
    const hidden = ('' + hide).split(',');
    const hideTopbar = hidden.includes('topbar');
    const hideHeader = hidden.includes('header');

    const defaultParams = {
      width,
      height,
      hideHeader,
      hideTopbar,
      isEmbedded: !!embedded,
    };

    if (layout) {
      return {
        ...defaultParams,
        isMobile: layout === 'mobile',
        isDesktop: layout === 'desktop',
        isTablet: layout === 'tablet',
        isLargeMobile: layout === 'largeMobile',
      };
    }

    const isMobile = width <= BreakPoints.Mobile;
    const isTablet = !isMobile && width <= BreakPoints.Tablet;
    const isLargeMobile =
      (isMobile && width >= BreakPoints.MobileSmall) || isTablet;
    const isDesktop = !isMobile && !isTablet;

    return {
      ...defaultParams,
      isMobile,
      isLargeMobile,
      isTablet,
      isDesktop,
    };
  }, [width, height, searchParams]);
}
