import { PharmacyAttributes } from 'ev-types';

import api, { Base } from 'ev-api/api';

import { PharmacyIdParam, PharmacySearchParams } from './params';
import {
  pharmacyIdResponseTransform,
  pharmacyResponseTransform,
} from './transformers';

export const pharmacyApi = api.injectEndpoints({
  endpoints: builder => ({
    searchPharmacies: builder.query<PharmacyAttributes[], PharmacySearchParams>(
      {
        query: ({ name, coordinates, miles, retail, mailOrder }) => ({
          url: `${Base.V2}/pharmacies/new_search`,
          method: 'POST',
          body: {
            name,
            coordinates,
            miles,
            retail,
            mail_order: mailOrder,
          },
        }),
        transformResponse: pharmacyResponseTransform,
      },
    ),
    getPharmacyById: builder.query<PharmacyAttributes, PharmacyIdParam>({
      query: ({ pharmacyId }) => ({
        url: `${Base.V2}/pharmacies/${pharmacyId}`,
        method: 'GET',
      }),
      transformResponse: pharmacyIdResponseTransform,
    }),
  }),
});

export const { useLazySearchPharmaciesQuery, useGetPharmacyByIdQuery } =
  pharmacyApi;
