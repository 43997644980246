import { Components } from '@mui/material/styles/components';

import { EVColors } from 'ev-theme/styles';

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsSizeOverrides {
    large: true;
    extraLarge: true;
  }
}

export const MuiCheckbox: Components['MuiCheckbox'] = {
  styleOverrides: {
    root: {
      margin: 7,
      color: EVColors.stone,
      border: '4px solid transparent',
      borderRadius: '5px',
      svg: {
        borderRadius: '3px',
      },
      '&.Mui-checked': {
        svg: {
          backgroundColor: EVColors.cerulean,
          color: EVColors.white,
        },
      },
      '&.Mui-checked.Mui-disabled': {
        svg: {
          backgroundColor: EVColors.aluminum,
          color: EVColors.white,
        },
      },
      '&.is-error': {
        color: EVColors.maraschino,
      },
      '&.Mui-disabled': {
        color: EVColors.aluminum,
      },
      '&&:focus-within, &&.display-focus': {
        borderColor: EVColors.cornflower,
      },
      '&:not(.Mui-checked):not(.Mui-disabled):not(.is-error)': {
        '.fa-square': {
          color: EVColors.stone,
        },
      },
    },
  },
  variants: [
    {
      props: {
        size: 'small',
      },
      style: {
        width: 16,
        height: 16,
        '.fa-check, .fa-minus-square': {
          width: 18,
          height: 18,
        },
        '.fa-square': {
          width: 20,
          height: 20,
        },
        padding: '8px',
      },
    },
    {
      props: {
        size: 'medium',
      },
      style: {
        width: 18,
        height: 18,
        padding: '9px',
        '.fa-check, .fa-minus-square ': {
          width: 20,
          height: 20,
        },
        '.fa-square': {
          width: 22,
          height: 22,
        },
      },
    },
    {
      props: {
        size: 'large',
      },
      style: {
        width: 22,
        height: 22,
        padding: '11px',
        '.fa-check, .fa-minus-square': {
          width: 24,
          height: 24,
        },
        '.fa-square': {
          width: 28,
          height: 28,
        },
      },
    },
    {
      props: {
        size: 'extraLarge',
      },
      style: {
        width: 24,
        height: 24,
        padding: '12px',
        '.fa-check, .fa-minus-square': {
          width: 26,
          height: 26,
        },
        '.fa-square': {
          width: 30,
          height: 30,
          display: 'flex',
          flex: 'none',
        },
      },
    },
  ],
};
