import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_API_URL } from 'ev-config/config';

import { buildQueryParams } from '../utils';
import { transformGeocodeResponse } from './transformers';
import {
  AddressCoordinatesRequestParams,
  AddressCoordinatesResponse,
} from './types';

const googleMapsQuery = (baseUrl = GOOGLE_MAPS_API_URL) => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      return headers;
    },
  });
};

export const googleMapsApi = createApi({
  reducerPath: 'googleMapsApi',
  baseQuery: googleMapsQuery(),
  endpoints: builder => ({
    getAddressCoordinates: builder.query<
      AddressCoordinatesResponse,
      AddressCoordinatesRequestParams
    >({
      query: ({ address }) => {
        const formattedAddress = [
          address.line1,
          address.line2,
          address.city,
          address.state,
          address.zip,
          address.country,
        ]
          .filter(item => !!item)
          .join(' ');
        const queryParams = buildQueryParams([
          { key: 'address', value: formattedAddress },
          { key: 'key', value: GOOGLE_MAPS_API_KEY },
        ]);
        return {
          url: `/geocode/json?${queryParams}`,
        };
      },
      transformResponse: transformGeocodeResponse,
    }),
  }),
});

export const { useGetAddressCoordinatesQuery } = googleMapsApi;
