export enum VisitStates {
  Unsubmitted = 'unsubmitted',
  UnsubmittedCanceled = 'unsubmitted_canceled',
  UnsubmittedCanceledAuto = 'unsubmitted_canceled_auto',
  Unscheduled = 'unscheduled',
  UnscheduledCanceled = 'unscheduled_canceled',
  UnscheduledCanceledAuto = 'unscheduled_canceled_auto',
  Submitted = 'submitted',
  Submit = 'submit', // TODO = some visits come with state = submit instead of submitted. Probably, need to fix on server side.
  Scheduled = 'scheduled',
  Impending = 'impending',
  Pending = 'pending',
  Active = 'active',
  Completed = 'completed',
  Canceled = 'canceled',
  Confirmed = 'confirmed',
  Unprescribed = 'unprescribed',
  Paid = 'paid',
  Expired = 'expired',
}

export enum GridDensities {
  Compact = 'compact',
  Standard = 'standard',
  Comfortable = 'comfortable',
}

export enum SortingOptions {
  EarliestToLatest = 'default',
  LongestWaitTime = 'wait_time',
  NewestStatusChange = 'newest_status_change',
  OldestStatusChange = 'oldest_status_change',
}

export enum LiveStatuses {
  Present = 'present',
  NotPresent = 'not_present',
  InVisit = 'in_visit',
}

export type ErrorResponse = {
  status: number;
  error?: string;
  data: {
    error: string;
  };
};
