import { DateTime } from 'luxon';

import { AppDispatch } from 'ev-store';

import { currentUserApi } from 'ev-api/core';

export function availabilityOptimisticUpdate(
  dispatch: AppDispatch,
  available: boolean,
) {
  const action = currentUserApi.util.updateQueryData(
    'getCurrentUser',
    undefined,
    currentUser => {
      currentUser.attributes.available_last_confirmed_at = available
        ? DateTime.now().toISO()
        : null;
      currentUser.attributes.is_available = available;
    },
  );

  return dispatch(action);
}
