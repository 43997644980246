import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

import { RootState } from 'ev-store';

import { BACKEND_PROXY } from 'ev-config/config';

const baseQuery = (baseUrl = '/') => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Secure-Authentication-Token', token);
      }
      if (BACKEND_PROXY) {
        headers.set('x-target-host', BACKEND_PROXY);
      }
      return headers;
    },
    paramsSerializer: params => {
      return queryString.stringify(params, { arrayFormat: 'bracket' });
    },
  });
};

export enum Tags {
  User = 'User',
  Schedule = 'Schedule',
  PersonalFilterViews = 'PersonalFilterViews',
  Visits = 'Visits',
  FavoriteMedications = 'FavoriteMedications',
  VisitPrescriptions = 'VisitPrescriptions',
  SupportedInsuranceDynamicFields = 'SupportedInsuranceDynamicFields',
  ManualInsuranceDynamicFields = 'ManualInsuranceDynamicFields',
  SearchPatients = 'search-patients',
  Providers = 'Providers',
  VisitTypeMemberships = 'VisitTypeMemberships',
  ServesLocation = 'ServesLocation',
  VisitTypes = 'VisitTypes',
  EvaultDocument = 'EvaultDocument',
  CustomColumns = 'CustomColumns',
  Segments = 'Segments',
  CustomNotes = 'CustomNotes',
  VisitStatuses = 'VisitStatuses',
  VisitParticipants = 'VisitParticipants',
  CustomActions = 'CustomActions',
  PracticeDevices = 'PracticeDevices',
  Customer = 'Customer',
  AdminPractices = 'AdminPractices',
  AdminLanguageQuickSelects = 'AdminLanguageQuickSelects',
}

export const AllAvailableTags = Object.values(Tags);

export enum Base {
  V1 = 'api/v1',
  V2 = 'api/v2',
  V3 = 'api/v3',
  V4 = 'api/v4',
  None = '',
}

const api = createApi({
  baseQuery: baseQuery(BACKEND_PROXY ? '/r-static/proxy/' : '/'),
  tagTypes: AllAvailableTags,
  endpoints: () => ({}),
});

export default api;
