import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const totalVisitsSlice = createSlice({
  name: 'totalVisits',
  initialState: 0,
  reducers: {
    updateTotalVisits(state, action: PayloadAction<number>) {
      return action.payload;
    },
  },
});
