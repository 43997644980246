import api, { Base } from 'ev-api/api';
import { sanitizeAndTransformResponseData } from 'ev-api/common/transformers';
import { TradingPartners } from 'ev-types/trading-partners';

export const tradingPartnersApi = api.injectEndpoints({
  endpoints: builder => ({
    getTradingPartners: builder.query<TradingPartners, void>({
      query: () => ({ url: `${Base.V2}/trading_partners` }),
      transformResponse: sanitizeAndTransformResponseData,
    }),
  }),
});

export const { useLazyGetTradingPartnersQuery } = tradingPartnersApi;
