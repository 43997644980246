import { EVColors } from 'ev-theme/styles';

export const MuiOutlinedInput = {
  variants: [
    {
      props: { className: 'time-input' },
      style: {
        fontStyle: 'italic',
        borderRight: 0,
        borderRadius: `4px 0 0 4px`,
      },
    },
  ],
  styleOverrides: {
    root: {
      border: `1px solid ${EVColors.aluminum}`,
      borderRadius: 4,
      color: EVColors.gunpowder,
      '&.Mui-focused, &.focused': {
        border: `1px solid ${EVColors.cerulean}`,
      },
      '&:hover:not(.Mui-disabled):not(.Mui-focused), &.hover': {
        backgroundColor: EVColors.alabaster,
        border: `1px solid ${EVColors.aluminum}`,
      },
      '.MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
      '&.Mui-disabled': {
        backgroundColor: EVColors.concrete,
      },
      '&.Mui-error': {
        border: `1px solid ${EVColors.maraschino}`,
      },
    },
    input: {
      padding: `4px`,
      '&::placeholder': {
        fontStyle: 'italic',
      },
    },
  },
};
