import {
  PendingIntegration,
  ResponseId,
  Timestamp,
  UserLanguages,
} from 'ev-types';

import { AddressResponse } from 'ev-api/common/AddressResponse';
import { PreferenceResponse } from 'ev-api/common/PreferenceResponse';
import {
  NotificationChannelResponse,
  PharmacyAttributesResponse,
  PharmacyResponse,
} from 'ev-api/core';
import { CreditCards } from 'ev-types/credit-card';
import { isObject } from 'ev-utils/types';

export type SignInUserResponse = {
  attributes: {
    id: ResponseId;
    // is_location_blocked: boolean;
    role: string;
    secure_authentication_token: string;
  };
  id: ResponseId;
  type: string;
};

export type SignInUserErrorResponse = {
  status: number;
  data: SignInUserError;
};

export type CreateUserErrorResponse = {
  data: {
    success: boolean;
    errors: {
      email?: string[];
    };
  };
};

export type CreateUserFromInvitationErrorResponse = {
  data: {
    success: boolean;
    error: string;
  };
};

export type UpdatePasswordErrorResponse = {
  data: {
    success: boolean;
    errors?: string[];
  };
};

export type SignInUserError = {
  error: string;
  error_codes?: string[];
  errors?: string[];
};

export type ResetPasswordResponse = {
  status?: string;
  error?: string;
};

export type UpdatePasswordSuccessResponse = {
  success: boolean;
};

export type UpdatePaymentMethodResponse = {
  success?: boolean;
  error?: { message: string };
  card?: {
    kind: string;
    last4: string;
  };
};

export type AgreementResponse = {
  attributes: AgreementAttributesResponse;
  type: string;
  id: ResponseId;
};

export type AgreementAttributesResponse = {
  content: string;
  name: string;
  practice_name: string;
  id: ResponseId;
  practice_id: ResponseId;
  user_id: ResponseId;
  friendly_terms_name: string;
  created_at: string;
};

export type CreateUserTermsAgreementResponse = {
  data: Array<{ id: ResponseId }>;
};

export type UserRelationships = {
  address?: { data: { type: 'address'; id: ResponseId } | null };
  chart_questions?: { data: object[] };
  dependents?: { data: Array<{ type: 'dependent'; id: ResponseId }> };
  integration?: { data: object };
  pending_integration?: { data: object };
  pharmacy?: { data: { type: 'pharmacy'; id: ResponseId } | null };
  preferred_card?: { data: object };
  user_providers?: { data: object[] };
};

export type UserResponseData = {
  attributes: UserAttributesResponse;
  id: ResponseId;
  type: 'user' | 'practice_provider' | 'dependent' | 'patient';
  relationships: UserRelationships;
};

export type DependentResponseData = {
  attributes: UserAttributesResponse;
  id: ResponseId;
  type: 'dependent';
  relationships: UserRelationships;
};

export function isDependentResponseData(
  value: unknown,
): value is DependentResponseData {
  return isObject(value) && value.type === 'dependent';
}

export type UserResponse = {
  data: UserResponseData;
  included: UserIncludedElement[];
};

export type UserIncludedElement =
  | UserResponseData
  | AddressResponse
  | PharmacyResponse
  | PendingIntegration;

export type UserAttributesResponse = {
  age: number;
  auxiliary_datapoints: Record<string, string>;
  available_last_confirmed_at: Timestamp;
  can_analyze: boolean;
  card?: UserCreditCardResponse;
  created_at: string;
  creator?: UserCreatorResponse;
  creator_user_id: ResponseId | null;
  current_visit_id: ResponseId | null;
  dob: string | null;
  email: string;
  eprescribe_enabled?: boolean;
  first_name: string;
  full_name: string;
  full_name_with_prefix: string;
  gender: 'male' | 'female' | null;
  has_avatar: boolean;
  id: ResponseId;
  is_available: boolean;
  is_schedulable: boolean;
  is_dependent: boolean;
  // is_location_blocked: boolean | null;
  insurance_enabled?: boolean;
  insurance_last_validated_at: string | null;
  insurance_validation_result: {
    validated: boolean | string;
  } | null;
  has_overridden_timezone: boolean | null;
  has_reports_role?: boolean;
  health_record_enabled_items?: string[];
  health_record_id: string | null;
  large_url: string;
  last_name: string;
  live_status: LiveStatusResponse;
  locale: UserLanguages | null;
  // location_state_code: string;
  medium_url: string;
  middle_name: string;
  notification_channel: string;
  notification_channels: NotificationChannelResponse[];
  npi?: string | null;
  occupied_time?: number;
  obfuscated_id: ResponseId;
  payment_method_enabled?: boolean;
  pharmacy?: PharmacyAttributesResponse;
  phone_cell: string;
  phone_home: string;
  phone_work: string;
  post_sign_out_target_url: string | null;
  practice_ids: ResponseId[];
  preferences: PreferenceResponse[];
  preferred_card?: UserCreditCardResponse;
  prefix: string | null;
  primary_practice_id: ResponseId;
  processor_mode: 'test' | 'live'; // 'alt' might be an option as well (?)
  profile_valid_at: string | null;
  profile_valid: boolean;
  profile: UserProfileResponse | null;
  remaining_eligibility_third_party_requests: number;
  role: string;
  roles?: RoleResponse[];
  schedulability: boolean;
  secure_authentication_token: string;
  specialty?: string;
  suffix: string | null;
  target_practice_id: ResponseId;
  test_mode: boolean;
  thumb_url: string;
  timeout: number; // In seconds
  timezone: string;
  tiny_url: string;
  vault_access_key: string;
  vault_access_token: string;
  vault_health_doc_id: ResponseId;
  vault_secret_key: string;
  vault_vault_id: ResponseId;
  waiting_visit_count?: number;
  practice_handles: string[];
};

export type UserCreditCardResponse = {
  id: ResponseId;
  cardable_id: ResponseId;
  customer_id: ResponseId;
  kind: CreditCards;
  last4: string;
  name: string;
  processor: string;
  processor_id: ResponseId;
  created_at: string;
  updated_at: string;
  exp_month: number;
  exp_year: number;
  data: unknown;
};

export type UserCreatorResponse = {
  email: string;
  full_name: string;
  medium_url: string;
  thumb_url: string;
  phone: string;
  role: string;
  user_id: ResponseId;
};

export type LiveStatusResponse = {
  status: string;
  status_updated_at_ms: Timestamp;
  visit_id: ResponseId | null;
};

export type UserProfileResponse = {
  body: string | null;
  published: boolean | null;
};

export type RoleResponse = { role: string; practice_id: ResponseId };

export type UserAttributesResponseForGetVisits = {
  age: number;
  email: string;
  first_name: string;
  gender: 'male' | 'female' | null;
  has_avatar: boolean;
  id: ResponseId;
  is_dependent: boolean;
  last_name: string;
  tiny_url: string;
  thumb_url: string;
  medium_url: string;
};

export type UserResponseForGetVisits = {
  attributes: UserAttributesResponseForGetVisits;
  id: ResponseId;
  type: 'user' | 'practice_provider' | 'dependent' | 'patient';
};

export type CreateUserResponse = {
  current_user: {
    id: ResponseId;
    secure_authentication_token: string;
  };
};

export type GetEnrollmentUserSuccessResponse = {
  data: {
    id: ResponseId;
    first_name: string;
    last_name: string;
    email: string;
  };
};

export type GetEnrollmentUserFailureResponse = {
  success: boolean;
  reason: string;
};
