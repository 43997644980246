import { MatchedIntegration } from 'ev-types';

import { GetIntegrationMatchDataResponse } from '../current-user';

export const transformMatchedIntegration = (
  response: GetIntegrationMatchDataResponse,
): MatchedIntegration[] => {
  const { data } = response;
  return data.map(d => ({
    patientid: d.patientid,
    auth: { ...d.auth },
    user_id: d.user_id,
  }));
};
