import api, { Base, Tags } from 'ev-api/api';
import {
  InsuranceStatusResponse,
  InsuranceValidationResponse,
} from 'ev-api/core';

import { BaseInsuranceParams, InsuranceValidationParams } from './params';

export const insuranceValidationsApi = api.injectEndpoints({
  endpoints: builder => ({
    eligibilityLookup: builder.mutation<
      InsuranceValidationResponse,
      InsuranceValidationParams
    >({
      query: ({ practiceId, userId, insurance }) => {
        return {
          url: `${Base.V3}/insurance_validations`,
          method: 'POST',
          body: {
            patient_id: userId,
            practice_id: practiceId,
            insurance: insurance,
          },
        };
      },
      invalidatesTags: [Tags.User, Tags.Visits, Tags.EvaultDocument],
    }),
    clearValidation: builder.mutation<
      InsuranceStatusResponse,
      BaseInsuranceParams
    >({
      query: ({ practiceId, userId }) => {
        return {
          url: `${Base.V3}/insurance_validations/clear`,
          method: 'PUT',
          body: {
            patient_id: userId,
            practice_id: practiceId,
          },
        };
      },
      invalidatesTags: [Tags.User, Tags.Visits],
    }),
    skipInsuranceValidation: builder.mutation<
      InsuranceStatusResponse,
      BaseInsuranceParams
    >({
      query: ({ practiceId, userId }) => {
        return {
          url: `${Base.V3}/insurance_validations/opt_out`,
          method: 'PUT',
          body: {
            patient_id: userId,
            practice_id: practiceId,
          },
        };
      },
      invalidatesTags: [Tags.User, Tags.Visits],
    }),
  }),
});

export const {
  useEligibilityLookupMutation,
  useClearValidationMutation,
  useSkipInsuranceValidationMutation,
} = insuranceValidationsApi;
