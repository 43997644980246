import { DateTime } from 'luxon';

export const numberFormatter = (value: string | null) => {
  return value ? value.replace(/\D/g, '') : '';
};

export const alphanumericFormatter = (value: string | null) => {
  return value ? value.replace(/\W/g, '') : '';
};

export const numericWithDecimalsFormatter = (value: string | null) => {
  const regex = /^[1-9][0-9]*\.?\d?\d?$/;
  return value
    ? regex.test(value)
      ? value
      : value.substring(0, value.length - 1)
    : '';
};

export type PhoneFormatTypes = 'dashes' | 'parentheses' | 'plain';

// dashes      123-456-7890
// parentheses (123) 456-7890
// plain       123 4567890
export const phoneFormatter = (
  value: string,
  format: PhoneFormatTypes = 'dashes',
) => {
  value = numberFormatter(value);

  const countryCodelessNumber = value.slice(-10);
  const countryCode = value.substring(
    0,
    value.length - countryCodelessNumber.length,
  );

  let [areaCode, telephonePrefix, lineNumber] = [
    countryCodelessNumber.substring(0, 3),
    countryCodelessNumber.substring(3, 6),
    countryCodelessNumber.substring(6, 10),
  ];

  switch (format) {
    case 'dashes':
      if (telephonePrefix) {
        telephonePrefix = '-' + telephonePrefix;
      }
      if (lineNumber) {
        lineNumber = '-' + lineNumber;
      }
      break;
    case 'plain':
      if (telephonePrefix) {
        areaCode = areaCode + ' ';
      }
      break;
    case 'parentheses':
      if (telephonePrefix) {
        areaCode = '(' + areaCode + ') ';
      }
      if (lineNumber) {
        lineNumber = '-' + lineNumber;
      }
  }
  const localNumber = areaCode + telephonePrefix + lineNumber;

  return value.length > 10
    ? '+' + countryCode + ' ' + localNumber
    : localNumber;
};

export const creditCardFormatter = (value: string) => {
  let formattedValue = numberFormatter(value);
  formattedValue =
    formattedValue.length > 16
      ? formattedValue.substring(0, 16)
      : formattedValue;
  const a = formattedValue.substring(0, 4);
  let b = formattedValue.substring(4, 8);
  let c = formattedValue.substring(8, 12);
  let d = formattedValue.substring(12, 16);

  if (b) {
    b = ' ' + b;
  }
  if (c) {
    c = ' ' + c;
  }
  if (d) {
    d = ' ' + d;
  }

  return a + b + c + d;
};

export const creditCardExpirationFormatter = (value: string) => {
  let formattedValue = numberFormatter(value);
  formattedValue =
    formattedValue.length > 4 ? formattedValue.substring(0, 4) : formattedValue;
  const a = formattedValue.substring(0, 2);
  let b = formattedValue.substring(2, 4);

  if (b) {
    b = '/' + b;
  }

  return a + b;
};

export const serverDateFormatter = (value: string | null): string | null => {
  if (!value) {
    return value;
  }
  let date = DateTime.fromFormat(value, 'yyyy-MM-dd');

  if (date.isValid) {
    return value;
  }

  date = DateTime.fromFormat(value, 'MM/dd/yyyy');

  if (date.isValid) {
    return date.toFormat('yyyy-MM-dd');
  }

  return '';
};

export const clientDateFormatter = (value: string | null): string => {
  if (!value) {
    return '';
  }

  let date = DateTime.fromFormat(value, 'MM/dd/yyyy');

  if (date.isValid) {
    return value;
  }

  date = DateTime.fromFormat(value, 'yyyy-MM-dd');

  if (date.isValid) {
    return date.toFormat('MM/dd/yyyy');
  }

  return '';
};

export const maskPhoneFormatter = (phone: string) => {
  return phone.replace(/\d(?!\d{0,3}$)/g, 'x');
};

export const maskPhoneListFormatter = (phone: string) => {
  const separatorPhone = +phone
    ? phoneFormatter(phone).split('-')
    : phone.split('-');
  return `${separatorPhone[0]} ***-**${separatorPhone[2].slice(2)}`;
};

export const maskMailFormatter = (mail: string) => {
  const separatorMail = mail.split('@');
  const user = separatorMail[0];
  let ocultedUser = '';
  if (user.length <= 4) {
    for (let i = 0; i < user.length; i++) {
      ocultedUser = ocultedUser + '*';
    }
    return `${ocultedUser}@${separatorMail[1]}`;
  } else {
    for (let i = 0; i < user.length - 2; i++) {
      ocultedUser = ocultedUser + '*';
    }
    return `${user.substring(0, 2)}${ocultedUser}@${separatorMail[1]}`;
  }
};

export const timeZoneFormatter = (timezone: string) => {
  return timezone.replace('_', ' ');
};

export const timeFormatter = (value: string) => {
  let separatorIndex = value.indexOf(':');
  const isOneDigitHour = separatorIndex === 1;
  if (separatorIndex === -1 || separatorIndex > 2) {
    separatorIndex = 2;
  }
  const numbers = numberFormatter(value);
  const hours = numbers.substring(0, separatorIndex);
  const minutes = numbers.substring(separatorIndex, separatorIndex + 2);
  let time = parseInt(hours) > 12 ? '12' : hours;
  if (minutes || isOneDigitHour) {
    time += ':' + (parseInt(minutes) > 59 ? '59' : minutes);
  }
  return time;
};
