import _ from 'lodash';

import {
  CondensedVisit,
  CondensedVisits,
  Id,
  MedicationResult,
  ResponseId,
  SearchVisitsResult,
  VisitParticipant,
  VisitWithPatient,
} from 'ev-types';

import {
  IncludedElementResponseAddress,
  IncludedElementResponsePatient,
} from 'ev-api/common/IncludedElementResponse';
import {
  CreatedPrescriptions,
  CreatePrescriptionResponse,
  GetVisitByIdResponse,
  GetVisitPrescriptionsResponse,
  GetVisitsResponse,
  SendPrescriptionResponse,
  SendPrescriptionResults,
  UserResponseData,
  UserResponseForGetVisits,
  VisitParticipantsResponse,
  VisitTypeResponseForGetVisits,
} from 'ev-api/core';
import { pharmacyTransform } from 'ev-api/core/pharmacies/transformers';
import {
  preferencesResponseTransform,
  userForGetVisitsResponseTransformer,
  userTransform,
} from 'ev-api/core/users/transformers';
import { visitTypeForGetVisitsTransformer } from 'ev-api/core/visit-types/transformers';
import {
  Sanitized,
  sanitizeId,
  sanitizeIds,
  sanitizeNullableId,
} from 'ev-api/utils';
import { PrescriptionStatus } from 'ev-common/Sidebar/Prescribe/constants';
import { isNumber } from 'ev-utils/types';

import { SearchVisitsResponse } from './responses';

export const visitByIdTransformer = (
  response: GetVisitByIdResponse,
): VisitWithPatient => {
  const { data } = response;

  const patient = _.find(
    response.included,
    inc => inc.type === 'patient',
  ) as unknown as UserResponseData;

  return {
    ...data,
    id: sanitizeId(data.id),
    attributes: {
      ...data.attributes,
      id: sanitizeId(data.attributes.id),
      patient_id: sanitizeId(data.attributes.patient_id),
      practice_id: sanitizeId(data.attributes.practice_id),
      vault_id: sanitizeId(data.attributes.vault_id),
      visit_type_id: sanitizeId(data.attributes.visit_type_id),
      preferred_physician_id: sanitizeNullableId(
        data.attributes.preferred_physician_id,
      ),
      chat_session_id: sanitizeNullableId(data.attributes.chat_session_id),
      cosigned_by_user_id: sanitizeNullableId(
        data.attributes.cosigned_by_user_id,
      ),
      signed_by_user_id: sanitizeNullableId(data.attributes.signed_by_user_id),
      current_segment_id: sanitizeNullableId(
        data.attributes.current_segment_id,
      ),
      health_doc_id: sanitizeId(data.attributes.health_doc_id),
      preferences: preferencesResponseTransform(data.attributes.preferences),
      verbiages: preferencesResponseTransform(data.attributes.verbiages),
      provider_group_id: sanitizeNullableId(data.attributes.provider_group_id),
    },
    patient: userTransform({ data: patient, included: response.included }),
  };
};

export const searchVisitsTransformer = (
  response: SearchVisitsResponse,
): SearchVisitsResult => {
  const sanitizedResponse = sanitizeIds<SearchVisitsResponse>(response);
  const responseIncludedGroupedByType = _.groupBy(
    sanitizedResponse.included,
    'type',
  );
  const addressesById = _.groupBy(responseIncludedGroupedByType.address, 'id');

  const dataWithPatientInfo = sanitizedResponse.data.map(visit => {
    const patientId = visit.relationships.patient.data.id;
    const type = visit.relationships.patient.data.type;
    const patient = responseIncludedGroupedByType[type].find(
      includedElement => includedElement.id === patientId,
    ) as Sanitized<IncludedElementResponsePatient>;
    const addressId = patient.relationships?.address.data?.id as ResponseId;
    const patientAddress = addressId
      ? addressesById[addressId][0]
      : ({} as Sanitized<IncludedElementResponseAddress>);
    return {
      ...visit.attributes,
      patientAddress: patientAddress.attributes,
      patient,
    };
  });

  return {
    visits: dataWithPatientInfo,
    meta: response.meta,
  };
};

export const getVisitParticipantsTransformer = (
  response: VisitParticipantsResponse,
): VisitParticipant[] => {
  return _.map(response.data, data => ({
    id: sanitizeId(data.id),
    attributes: {
      ...data.attributes,
      id: sanitizeId(data.attributes.id),
    },
    address: _.find(response.included, { id: data.relationships.address?.id }),
  }));
};

export const createPrescriptionTransformer = (
  response: CreatePrescriptionResponse,
): CreatedPrescriptions => {
  const ids: Id[] = [];
  response.entered_dosespot_prescription_ids.forEach(id =>
    ids.push(sanitizeId(id)),
  );

  return { enteredDoseSpotPrescriptionIds: ids };
};

export const sendPrescriptionTransformer = (
  response: SendPrescriptionResponse,
): SendPrescriptionResults[] => {
  const results: SendPrescriptionResults[] = [];
  response.results.forEach(outcome =>
    results.push({
      prescriptionId: sanitizeId(outcome.prescription_id),
      result: {
        resultCode: outcome.result.result_code,
        resultDescription: outcome.result.result_description,
      },
    }),
  );

  return results;
};

export const getVisitPrescriptionTransformer = (
  response: GetVisitPrescriptionsResponse,
): MedicationResult[] => {
  const parsedPrescriptions = _.map(response.prescriptions, prescription => ({
    ...prescription,
    days_supply: isNumber(prescription.days_supply)
      ? prescription.days_supply.toString()
      : '',
    dispense_unit_id: sanitizeId(prescription.dispense_unit_id),
    dosespot_pharmacy_id: sanitizeId(prescription.dosespot_pharmacy_id),
    dosespot_prescription_id: sanitizeId(prescription.dosespot_prescription_id),
    id: sanitizeId(prescription.id),
    is_dme: prescription.is_dme,
    lexi_drug_syn_id: sanitizeNullableId(prescription.lexi_drug_syn_id),
    lexi_gen_product_id: sanitizeNullableId(prescription.lexi_gen_product_id),
    lexi_synonym_type_id: sanitizeNullableId(prescription.lexi_synonym_type_id),
    pharmacy: pharmacyTransform(prescription.pharmacy),
    pharmacy_id: sanitizeId(prescription.pharmacy_id),
    visit_id: sanitizeId(prescription.visit_id),
    status: prescription.status.toLowerCase(),
    representative_ndc: prescription.representative_ndc,
    dispensable_drug_id: sanitizeNullableId(prescription.dispensable_drug_id),
    rx_cui: prescription.rx_cui,
  }));

  return _.filter(
    parsedPrescriptions,
    p => p.status !== PrescriptionStatus.Deleted,
  );
};

export const getVisitsTransformer = (
  response: GetVisitsResponse,
): CondensedVisits => {
  const { data, meta, included } = sanitizeIds(response);
  const sanitizedResponse = {} as CondensedVisits;
  const visits = _.map(data, visitData => {
    const visit: CondensedVisit = { ...visitData };
    if (included) {
      const patientResponse = _.find(included, {
        id: visitData.relationships?.patient?.data.id,
        type: 'user',
      });
      const preferredPhysicianResponse = _.find(included, {
        id: visitData.relationships?.preferred_physician?.data?.id,
        type: 'user',
      });
      const visitTypeResponse = _.find(included, {
        id: visitData.relationships?.visit_type?.data.id,
        type: 'visit_type',
      });

      if (patientResponse) {
        visit.patient = userForGetVisitsResponseTransformer(
          patientResponse as UserResponseForGetVisits,
        );
      }
      if (preferredPhysicianResponse) {
        visit.preferred_physician = userForGetVisitsResponseTransformer(
          preferredPhysicianResponse as UserResponseForGetVisits,
        );
      }
      if (visitTypeResponse) {
        visit.visit_type = visitTypeForGetVisitsTransformer(
          visitTypeResponse as VisitTypeResponseForGetVisits,
        );
      }
    }
    return visit;
  });
  _.assign(sanitizedResponse, { visits });
  _.assign(sanitizedResponse, { meta });
  return sanitizedResponse;
};
