import _ from 'lodash';

import {
  ChatEntry,
  PatientEvaultDocument,
  VisitEvaultDocument,
} from 'ev-types';

import api, { Base, Tags } from 'ev-api/api';
import { sanitizeId } from 'ev-api/utils';
import { base64Decode } from 'ev-utils/base64';

import {
  AddEvaultDocumentParams,
  GetChatEvaultDocumentParams,
  GetEvaultDocumentParams,
  UpdateEvaultDocumentParams,
} from './params';
import {
  AddEvaultDocumentResponse,
  ChatEvaultResponse,
  GetBlobParams,
  UpdateEvaultDocumentResponse,
  UploadBlobParams,
  UploadBlobResponse,
} from './responses';
import { eVaultFileTransform } from './transformers';
import { encodeEvaultDocument, mergeEvaultDocuments } from './utils';

export const newEvaultApi = api.injectEndpoints({
  endpoints: builder => ({
    getPatientEvaultDocument: builder.query<
      PatientEvaultDocument,
      GetEvaultDocumentParams
    >({
      query: ({ vaultId, vaultHealthDocId, healthRecordId }) => ({
        url: `${Base.V3}/evault_documents/${vaultHealthDocId}`,
        method: 'GET',
        params: {
          vault_id: vaultId,
          vault_health_doc_id: vaultHealthDocId,
          health_record_id: healthRecordId,
        },
      }),
      providesTags: [Tags.EvaultDocument],
    }),
    getVisitEvaultDocument: builder.query<
      VisitEvaultDocument,
      GetEvaultDocumentParams
    >({
      query: ({ vaultId, vaultHealthDocId, healthRecordId }) => ({
        url: `${Base.V3}/evault_documents/${vaultHealthDocId}`,
        method: 'GET',
        params: {
          vault_id: vaultId,
          vault_health_doc_id: vaultHealthDocId,
          health_record_id: healthRecordId,
        },
      }),
      providesTags: [Tags.EvaultDocument],
    }),
    getChatEvaultDocument: builder.query<
      ChatEntry[],
      GetChatEvaultDocumentParams
    >({
      queryFn: async (
        { vaultId, vaultHealthDocIds, healthRecordId },
        queryApi,
        extraOptions,
        fetch,
      ) => {
        const promises = _.map(vaultHealthDocIds, vaultHealthDocId =>
          fetch({
            url: `${Base.V3}/evault_documents/${vaultHealthDocId}`,
            method: 'GET',
            params: {
              vault_id: vaultId,
              vault_health_doc_id: vaultHealthDocId,
              health_record_id: healthRecordId,
            },
          }),
        );

        const results = await Promise.all(promises);
        const resultWithError = _.find(results, result => !!result.error);

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (resultWithError && resultWithError.error) {
          return { error: resultWithError.error };
        }

        const chatLog = _.chain(results)
          .map(result => {
            const data = result.data as ChatEvaultResponse;
            const document = JSON.parse(base64Decode(data.document));

            return _.map(document.messages, message => ({
              message: decodeURIComponent(message.message),
              created_at: decodeURIComponent(message.created_at),
              user_id: sanitizeId(message.user_id),
              user_name: decodeURIComponent(message.user_name),
              visit_id: sanitizeId(message.visit_id),
            }));
          })
          .flatten()
          .sortBy('created_at')
          .value();

        return { data: chatLog };
      },
    }),
    updateEvaultDocument: builder.mutation<
      UpdateEvaultDocumentResponse,
      UpdateEvaultDocumentParams
    >({
      query: ({
        vaultId,
        vaultHealthDocId,
        healthRecordId,
        currentUserId,
        currentDocument,
        modifiedDocument,
      }) => {
        const updatedDocument = mergeEvaultDocuments(
          currentDocument,
          modifiedDocument,
          currentUserId,
        );

        return {
          url: `${Base.V3}/evault_documents/${vaultHealthDocId}`,
          method: 'PATCH',
          params: {
            vault_id: vaultId,
            vault_health_doc_id: vaultHealthDocId,
            health_record_id: healthRecordId,
          },
          body: {
            document: encodeEvaultDocument(updatedDocument),
          },
        };
      },
      invalidatesTags: [Tags.EvaultDocument, Tags.User],
    }),

    addEvaultDocument: builder.mutation<
      AddEvaultDocumentResponse,
      AddEvaultDocumentParams
    >({
      query: ({ vaultId, document, healthRecordId, practiceId }) => {
        return {
          url: `${Base.V3}/evault_documents`,
          method: 'POST',
          params: {
            vault_id: vaultId,
            health_record_id: healthRecordId,
            practice_id: practiceId,
          },
          body: {
            document: encodeEvaultDocument(document),
          },
        };
      },
      invalidatesTags: [Tags.EvaultDocument, Tags.User],
    }),
    uploadBlob: builder.mutation<UploadBlobResponse, UploadBlobParams>({
      query: ({ vaultId, blob, visitId }) => {
        const formData = new FormData();
        formData.append('file', blob);
        return {
          url: `${Base.V3}/evault_blobs`,
          method: 'POST',
          params: {
            vault_id: vaultId,
            visit_id: visitId,
          },
          body: formData,
        };
      },
    }),
    getBlob: builder.query<string, GetBlobParams>({
      query: ({ vaultId, blobId, attachmentId, visitId }) => {
        return {
          url: `${Base.V3}/evault_blobs/${blobId}`,
          method: 'GET',
          params: {
            vault_id: vaultId,
            blob_id: blobId,
            attachment_id: attachmentId,
            visit_id: visitId,
          },
          responseHandler: response =>
            response.headers.get('content-type') === 'text/html'
              ? response.text()
              : response.blob(),
        };
      },
      transformResponse: eVaultFileTransform,
    }),
  }),
});
export const {
  useGetPatientEvaultDocumentQuery,
  useGetVisitEvaultDocumentQuery,
  useLazyGetVisitEvaultDocumentQuery,
  useGetChatEvaultDocumentQuery,
  useUpdateEvaultDocumentMutation,
  useAddEvaultDocumentMutation,
  useLazyGetPatientEvaultDocumentQuery,
  useUploadBlobMutation,
  useGetBlobQuery,
} = newEvaultApi;
