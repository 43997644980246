import { MatchedIntegration, User } from 'ev-types';

import api, { AllAvailableTags, Base, Tags } from 'ev-api/api';
import { SuccessResponse } from 'ev-api/common/SuccessResponse';
import { sanitizeAndTransformResponseData } from 'ev-api/common/transformers';
import { PharmacyAttributesResponse } from 'ev-api/core';
import { userTransform } from 'ev-api/core/users/transformers';
import {
  availabilityOptimisticUpdate,
  setBiographyStatusOptimisticUpdate,
} from 'ev-api/thunks';
import { setUserTokenOptimisticUpdate } from 'ev-api/thunks/user-access-token-optimistic-update';
import {
  clearAvatar,
  clearPfv,
  clearSelectedVisit,
  closeSidebar,
  setAuthToken,
  setAvatar,
} from 'ev-store/actions';

import { transformMatchedIntegration } from '../integrations';
import {
  CreateDependentParams,
  CreateOrUpdateProfileParams,
  DeleteAvatarParams,
  GetMatchItegrationDataParams,
  GetUserFromInviteTokenParams,
  SetDependentFavoritePharmacyParam,
  SetFavoritePharmacyParam,
  SetLocationOverrideParams,
  UpdateAvailabilityParams,
  UpdateAvatarParams,
  UpdateDependentParams,
  UpdateUserContactInfoParams,
  UpdateUserEmailParams,
  UpdateUserInvitationPrams,
  UpdateUserLastPracticeParams,
  UpdateUserLocaleParams,
  UpdateUserParams,
  UpdateUserPasswordParams,
  UpdateUserPersonalInfoParams,
  UserIdParam,
  ValidateUserProfileParams,
} from './params';
import {
  CreateDependentResponse,
  KeepAliveResponse,
  ProviderIdsResponse,
  UpdateUserInvitationResponse,
  UpdateUserResponse,
  UserInformationResponse,
} from './responses';

const withGetCurrentUser = api.injectEndpoints({
  endpoints: builder => ({
    getCurrentUser: builder.query<User, void>({
      query: () => ({ url: `${Base.V3}/current_user?members_lite=true` }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAuthToken(data.attributes.secure_authentication_token));
        } catch {
          /* empty */
        }
      },
      transformResponse: userTransform,
      providesTags: [Tags.User],
    }),
  }),
});

export const currentUserApi = withGetCurrentUser.injectEndpoints({
  endpoints: builder => ({
    keepAlive: builder.mutation<KeepAliveResponse, UserIdParam>({
      query: ({ userId }) => ({
        url: `${Base.V2}/current_user/${userId}/session_keepalive`,
        method: 'POST',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { success, vault_access_token } = (await queryFulfilled).data;
        if (vault_access_token && success) {
          const patchResult = setUserTokenOptimisticUpdate(
            dispatch,
            vault_access_token,
          );
          queryFulfilled.catch(patchResult.undo);
        }
      },
    }),
    unpublishProfile: builder.mutation<SuccessResponse, UserIdParam>({
      query: ({ userId }) => ({
        url: `${Base.V2}/current_user/${userId}/unpublish_profile`,
        method: 'POST',
      }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        const patchResult = setBiographyStatusOptimisticUpdate(dispatch, false);
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    createOrUpdateProfile: builder.mutation<
      SuccessResponse,
      CreateOrUpdateProfileParams
    >({
      query: ({ userId, publish, content }) => ({
        url: `${Base.V2}/current_user/${userId}/create_or_update_profile`,
        method: 'POST',
        body: {
          publish,
          user: {
            body: content,
          },
        },
      }),
      onQueryStarted({ publish, content }, { dispatch, queryFulfilled }) {
        const patchResult = setBiographyStatusOptimisticUpdate(
          dispatch,
          publish,
          content,
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    updateAvatar: builder.mutation<void, UpdateAvatarParams>({
      query: ({ file, fileName, userId, creatorId }) => {
        const formData = new FormData();
        formData.append('file', file, fileName);
        const url = creatorId
          ? `${Base.V2}/current_user/${creatorId}/dependents/${userId}/avatar`
          : `${Base.V2}/current_user/${userId}/avatar`;
        return {
          url,
          method: 'POST',
          body: formData,
        };
      },
      async onQueryStarted(
        { dataUrl, userId, creatorId },
        { dispatch, queryFulfilled },
      ) {
        dispatch(setAvatar({ userId, dataUrl }));

        if (creatorId) {
          return;
        }
        /* when updating the avatar, rails takes a while to respond the correct image,
        so we patch the current user with the version of the image we have available */
        const patchResult = dispatch(
          withGetCurrentUser.util.updateQueryData(
            'getCurrentUser',
            undefined,
            currentUser => {
              Object.assign(currentUser, {
                ...currentUser,
                attributes: {
                  ...currentUser.attributes,
                  thumb_url: dataUrl,
                  medium_url: dataUrl,
                  has_avatar: true,
                },
              });
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteAvatar: builder.mutation<void, DeleteAvatarParams>({
      query: ({ userId, dependentId }) => ({
        url: dependentId
          ? `${Base.V2}/current_user/${userId}/dependents/${dependentId}/avatar`
          : `${Base.V2}/current_user/${userId}/avatar`,
        method: 'DELETE',
      }),
      onQueryStarted(_, { dispatch }) {
        dispatch(clearAvatar());
      },
      invalidatesTags: [Tags.User],
    }),
    updateUserEmail: builder.mutation<SuccessResponse, UpdateUserEmailParams>({
      query: ({ userId, email, currentPassword }) => ({
        url: `${Base.V2}/current_user/${userId}/update_email`,
        method: 'PUT',
        body: {
          email,
          current_password: currentPassword,
        },
      }),
      invalidatesTags: [Tags.User],
    }),
    updateUserPassword: builder.mutation<
      SuccessResponse,
      UpdateUserPasswordParams
    >({
      query: ({ userID, password, confirmPassword, currentPassword }) => ({
        url: `${Base.V2}/current_user/${userID}/update_password`,
        method: 'PUT',
        body: {
          current_password: currentPassword,
          password,
          password_confirmation: confirmPassword,
        },
      }),
      invalidatesTags: [Tags.User],
    }),
    updateUser: builder.mutation<
      UserInformationResponse,
      | UpdateUserParams
      | UpdateUserPersonalInfoParams
      | UpdateUserContactInfoParams
    >({
      query: ({ userId, membersLite, userUpdate }) => ({
        url: `${Base.V2}/current_user/${userId}`,
        method: 'PATCH',
        body: {
          members_lite: membersLite,
          requires_response: true,
          current_user: { ...userUpdate },
        },
      }),
      transformResponse: sanitizeAndTransformResponseData,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        // Update the user but keep the avatar,so we won't alter it if it's being updated still
        dispatch(
          withGetCurrentUser.util.updateQueryData(
            'getCurrentUser',
            undefined,
            currentUser => {
              const newUser = data.current_user;
              const userWithSameImage = {
                ...newUser,
                thumb_url: currentUser.attributes.thumb_url,
                medium_url: currentUser.attributes.medium_url,
              };
              Object.assign(currentUser.attributes, userWithSameImage);
            },
          ),
        );
      },
    }),
    updateUserLastPractice: builder.mutation<
      void,
      UpdateUserLastPracticeParams
    >({
      query: ({ userId, lastPracticeId }) => ({
        url: `${Base.V2}/current_user/${userId}`,
        method: 'PATCH',
        body: {
          current_user: {
            last_practice_id: lastPracticeId,
          },
          members_lite: true,
          requires_response: false,
        },
      }),
      async onQueryStarted(val, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(clearSelectedVisit());
        dispatch(closeSidebar());
        dispatch(clearPfv());
      },
      invalidatesTags: AllAvailableTags,
    }),
    updateDependent: builder.mutation<
      UpdateUserResponse,
      UpdateDependentParams
    >({
      query: ({ userId, dependentId, membersLite, address, userUpdate }) => ({
        url: `${Base.V2}/current_user/${userId}/dependents/${dependentId}`,
        method: 'PATCH',
        body: {
          members_lite: membersLite,
          requires_response: true,
          address,
          dependent: { ...userUpdate, address },
        },
      }),
      invalidatesTags: [Tags.User],
    }),
    updateUserLocale: builder.mutation<void, UpdateUserLocaleParams>({
      query: ({ locale }) => ({
        url: `${Base.V3}/current_user/update_locale`,
        method: 'PUT',
        params: { locale },
        body: { locale },
      }),
      invalidatesTags: [
        Tags.User,
        Tags.ManualInsuranceDynamicFields,
        Tags.SupportedInsuranceDynamicFields,
      ],
    }),
    toggleAvailability: builder.mutation<void, UpdateAvailabilityParams>({
      query: ({ practiceId, userId, available }) => ({
        url: `${Base.V2}/current_user/${userId}/toggle_availability`,
        method: 'POST',
        body: {
          availability: available ? 'available' : 'unavailable',
          practice_id: practiceId,
        },
      }),
      onQueryStarted({ available }, { dispatch, queryFulfilled }) {
        const patchResult = availabilityOptimisticUpdate(dispatch, available);
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: [Tags.Providers, Tags.VisitTypeMemberships],
    }),
    createDependent: builder.mutation<
      CreateDependentResponse,
      CreateDependentParams
    >({
      query: ({ userId, address, dependent }) => ({
        url: `${Base.V2}/current_user/${userId}/dependents.json`,
        method: 'POST',
        body: {
          address,
          dependent: {
            ...dependent,
            address,
          },
          members_lite: true,
        },
      }),
      invalidatesTags: (_result, _error, args) =>
        args.skipTagsInvalidation ? [] : [Tags.User],
    }),
    setFavoritePharmacy: builder.mutation<
      PharmacyAttributesResponse,
      SetFavoritePharmacyParam
    >({
      query: ({ userId, pharmacy, skip }) => ({
        url: `${Base.V2}/current_user/${userId}/favorite_pharmacy`,
        method: 'POST',
        body: {
          skip,
          favorite_pharmacy: pharmacy,
        },
      }),
      invalidatesTags: [Tags.User],
    }),
    setDependentFavoritePharmacy: builder.mutation<
      PharmacyAttributesResponse,
      SetDependentFavoritePharmacyParam
    >({
      query: ({ dependentId, userId, pharmacy, skip }) => ({
        url: `${Base.V2}/current_user/${userId}/dependents/${dependentId}/favorite_pharmacy`,
        method: 'POST',
        body: {
          skip,
          favorite_pharmacy: pharmacy,
        },
      }),
      invalidatesTags: [Tags.User],
    }),
    setLocationOverride: builder.mutation<
      ProviderIdsResponse,
      SetLocationOverrideParams
    >({
      query: ({ userId, practiceId, stateCode, text }) => ({
        url: `${Base.V2}/current_user/${userId}/set_location_override.json`,
        method: 'POST',
        body: {
          agree: true,
          countryCode: 'US',
          members_lite: true,
          practice_id: practiceId,
          state_code: stateCode,
          text,
        },
      }),
      transformResponse: sanitizeAndTransformResponseData,
      invalidatesTags: [
        Tags.ServesLocation,
        Tags.VisitTypeMemberships,
        Tags.VisitTypes,
      ],
    }),
    getUserFromInviteToken: builder.query<User, GetUserFromInviteTokenParams>({
      query: ({ token }) => ({
        url: `${Base.V3}/current_user/user_from_invite?token=${token}&members_lite=true`,
        method: 'GET',
      }),
      transformResponse: userTransform,
      providesTags: [Tags.User],
    }),
    updateUserInvitation: builder.mutation<
      UpdateUserInvitationResponse,
      UpdateUserInvitationPrams
    >({
      query: ({
        evisitTos,
        id,
        invitationToken,
        password,
        passwordConfirmation,
        practiceTos,
      }) => ({
        url: `users/invitation.json`,
        method: 'PUT',
        body: {
          lite: true,
          member_lite: true,
          user: {
            evisit_tos: evisitTos ? 'true' : 'false',
            id,
            invitation_token: invitationToken,
            password: password,
            password_confirmation: passwordConfirmation,
            practice_tos: practiceTos ? 'true' : 'false',
          },
        },
      }),
    }),
    validateUserProfile: builder.mutation<User, ValidateUserProfileParams>({
      query: ({ userId }) => ({
        url: `${Base.V3}/current_user/welcome`,
        method: 'PUT',
        body: {
          user_id: Number(userId),
        },
      }),
      invalidatesTags: (_result, _error, args) =>
        args.skipTagsInvalidation ? [] : [Tags.User],
    }),
    matchIntegrationData: builder.query<
      MatchedIntegration[],
      GetMatchItegrationDataParams
    >({
      query: ({ userId }) => ({
        url: `${Base.V2}/current_user/${userId}/get_integration_match_data`,
        method: 'GET',
      }),
      transformResponse: transformMatchedIntegration,
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useUpdateUserLocaleMutation,
  useSetFavoritePharmacyMutation,
  useSetDependentFavoritePharmacyMutation,
  useSetLocationOverrideMutation,
  useGetUserFromInviteTokenQuery,
  useUpdateUserInvitationMutation,
  useValidateUserProfileMutation,
  useMatchIntegrationDataQuery,
} = currentUserApi;
