import { createTheme } from '@mui/material/styles';

import {
  MuiAccordion,
  MuiAutocomplete,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiDataGrid,
  MuiDialog,
  MuiDialogActions,
  MuiDialogTitle,
  MuiDivider,
  MuiDrawer,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiInputLabel,
  MuiListItem,
  MuiListItemButton,
  MuiListItemIcon,
  MuiListSubheader,
  MuiMenu,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiPaginationItem,
  MuiRadio,
  MuiRating,
  MuiSelect,
  MuiSwitch,
  MuiTab,
  MuiTabs,
  MuiTextField,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiTooltip,
} from './components';
import { BreakpointsTheme, PaletteTheme, TypographyTheme } from './styles';

export const theme = createTheme({
  breakpoints: BreakpointsTheme,
  palette: PaletteTheme,
  typography: TypographyTheme,
  components: {
    MuiDataGrid,
    MuiAutocomplete,
    MuiListSubheader,
    MuiButton,
    MuiChip,
    MuiCheckbox,
    MuiFormControlLabel,
    MuiRadio,
    MuiInputLabel,
    MuiOutlinedInput,
    MuiFormHelperText,
    MuiMenu,
    MuiMenuItem,
    MuiSelect,
    MuiSwitch,
    MuiTabs,
    MuiTab,
    MuiTextField,
    MuiToggleButtonGroup,
    MuiToggleButton,
    MuiDrawer,
    MuiListItem,
    MuiListItemButton,
    MuiListItemIcon,
    MuiDivider,
    MuiDialog,
    MuiDialogTitle,
    MuiDialogActions,
    MuiTooltip,
    MuiPaginationItem,
    MuiRating,
    MuiAccordion,
  },
});
