import { NotificationChannel } from 'ev-types';

import api, { Base, Tags } from 'ev-api/api';
import { sanitizeAndTransformResponseData } from 'ev-api/common/transformers';

import {
  CreateNotificationChannelParams,
  NotificationChannelIdParam,
} from './params';

export const notificationChannelsApi = api.injectEndpoints({
  endpoints: builder => ({
    updateNotificationChannel: builder.mutation<
      NotificationChannel,
      NotificationChannel
    >({
      query: channel => ({
        url: `${Base.V2}/notification_channels/${channel.id}`,
        method: 'PUT',
        body: channel,
      }),
      invalidatesTags: [Tags.User],
      transformResponse: sanitizeAndTransformResponseData,
    }),
    deleteNotificationChannel: builder.mutation<
      void,
      NotificationChannelIdParam
    >({
      query: ({ id }) => ({
        url: `${Base.V2}/notification_channels/${id}`,
        method: 'DELETE',
      }),
    }),
    createNotificationChannel: builder.mutation<
      NotificationChannel,
      CreateNotificationChannelParams
    >({
      query: ({ target, is_active, kind }) => ({
        url: `${Base.V2}/notification_channels`,
        method: 'POST',
        body: {
          default: false,
          is_active,
          target,
          kind,
        },
      }),
      transformResponse: sanitizeAndTransformResponseData,
    }),
  }),
});

export const {
  useUpdateNotificationChannelMutation,
  useDeleteNotificationChannelMutation,
  useCreateNotificationChannelMutation,
} = notificationChannelsApi;
