import { EVColors } from 'ev-theme/styles';

export const MuiToggleButtonGroup = {
  styleOverrides: {
    grouped: {
      border: `1px solid ${EVColors.aluminum}`,
      '&.Mui-selected': {
        border: `1px solid ${EVColors.cerulean}`,
      },

      '&:not(:first-of-type)': {
        margin: '0',
        left: '0',
      },
      backgroundColor: EVColors.white,
      '&:hover, &.hover': {
        backgroundColor: EVColors.alabaster,
      },
      '&.Mui-disabled': {
        backgroundColor: EVColors.concrete,
        color: EVColors.mercury,
        '& .MuiChip-toggleBadge': {
          color: EVColors.mercury,
          backgroundColor: EVColors.concrete,
        },
        '&.Mui-selected': {
          borderColor: EVColors.aluminum,
        },
      },
    },
  },
};
