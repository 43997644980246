import { UnitOption } from 'ev-common/Sidebar/Prescribe/types';
import { Option } from 'ev-components/Menu';

export const MAX_REFILL = 99;
export const MAX_DIRECTIONS_LENGTH = 140;
export const MAX_PHARMACY_DIRECTIONS_LENGTH = 210;

export enum PrescriptionStatus {
  Entered = 'entered',
  Edited = 'edited',
  ErXSent = 'erxsent',
  Sending = 'sending',
  PharmacyVerified = 'pharmacyverified',
  Error = 'error',
  Deleted = 'deleted',
}

export type DirectionOption = Option & {
  label: string;
  multiplier: string;
  hideDose?: boolean;
};

export const Directions: DirectionOption[] = [
  {
    label: 'As Directed',
    value: 'as directed',
    multiplier: '0',
    hideDose: true,
  },
  {
    label: '(prn) As needed',
    value: 'as needed.',
    multiplier: '0',
    hideDose: true,
  },
  { label: '(qd) Every day', value: 'every day.', multiplier: '1' },
  {
    label: '(qod) Every other day',
    value: 'every other day.',
    multiplier: '0.5',
  },
  { label: '(bid) Twice a day', value: 'twice a day.', multiplier: '2' },
  {
    label: '(tid) Three times a day',
    value: 'three times a day.',
    multiplier: '3',
  },
  {
    label: '(qid) Four times a day',
    value: 'four times a day.',
    multiplier: '4',
  },
  { label: '(q4h) Every 4 hrs', value: 'every 4 hours.', multiplier: '6' },
  { label: '(q6h) Every 6 hrs', value: 'every 6 hours.', multiplier: '4' },
  { label: '(q8h) Every 8 hrs', value: 'every 8 hours.', multiplier: '3' },
  { label: '(qhs) At bed time', value: 'at bed time.', multiplier: '1' },
];

export const PrescribeUnits: UnitOption[] = [
  { value: '34', label: 'Ampule', active: false },
  { value: '35', label: 'Applicator', active: true },
  { value: '36', label: 'Applicatorful', active: false },
  { value: '1', label: 'Bag', active: false },
  { value: '37', label: 'Bar', active: false },
  { value: '38', label: 'Bead', active: false },
  { value: '39', label: 'Blister', active: true },
  { value: '40', label: 'Block', active: false },
  { value: '41', label: 'Bolus', active: false },
  { value: '2', label: 'Bottle', active: false },
  { value: '3', label: 'Box', active: false },
  { value: '42', label: 'Can', active: false },
  { value: '43', label: 'Canister', active: false },
  { value: '44', label: 'Caplet', active: true },
  { value: '4', label: 'Capsule', active: true },
  { value: '45', label: 'Carton', active: false },
  { value: '5', label: 'Cartridge', active: false },
  { value: '46', label: 'Case', active: false },
  { value: '47', label: 'Cassette', active: false },
  { value: '6', label: 'Container', active: false },
  { value: '48', label: 'Cylinder', active: false },
  { value: '49', label: 'Disk', active: false },
  { value: '50', label: 'Dose Pack', active: false },
  { value: '51', label: 'Dual Packs', active: false },
  { value: '32', label: 'Each', active: true },
  { value: '52', label: 'Film', active: true },
  { value: '18', label: 'Fluid Ounce', active: false },
  { value: '85', label: 'French', active: false },
  { value: '53', label: 'Gallon', active: false },
  { value: '8', label: 'Gram', active: true },
  { value: '33', label: 'Gum', active: true },
  { value: '54', label: 'Implant', active: true },
  { value: '55', label: 'Inhalation', active: false },
  { value: '9', label: 'Inhaler', active: false },
  { value: '56', label: 'Inhaler Refill', active: false },
  { value: '57', label: 'Insert', active: true },
  { value: '58', label: 'Intravenous Bag', active: false },
  { value: '86', label: 'Kilogram', active: false },
  { value: '11', label: 'Kit', active: true },
  { value: '90', label: 'Lancet', active: true },
  { value: '12', label: 'Liter', active: false },
  { value: '13', label: 'Lozenge', active: true },
  { value: '7', label: 'Metric Drop', active: false },
  { value: '59', label: 'Milimeter', active: false },
  { value: '87', label: 'Milliequivalent', active: false },
  { value: '14', label: 'Milligram', active: false },
  { value: '15', label: 'Milliliter', active: true },
  { value: '60', label: 'Nebule', active: false },
  { value: '61', label: 'Needle Free Injection', active: false },
  { value: '62', label: 'Oscular System', active: false },
  { value: '63', label: 'Ounce', active: false },
  { value: '20', label: 'Package', active: false },
  { value: '21', label: 'Packet', active: true },
  { value: '64', label: 'Pad', active: true },
  { value: '65', label: 'Paper', active: false },
  { value: '28', label: 'Patch', active: true },
  { value: '83', label: 'Pen Needle', active: true },
  { value: '22', label: 'Pint', active: false },
  { value: '66', label: 'Pouch', active: false },
  { value: '67', label: 'Pound', active: false },
  { value: '84', label: 'Pre-filled Pen Syringe', active: false },
  { value: '68', label: 'Puff', active: false },
  { value: '88', label: 'Pump', active: false },
  { value: '69', label: 'Quart', active: false },
  { value: '70', label: 'Ring', active: true },
  { value: '24', label: 'Ring', active: false },
  { value: '71', label: 'Sachet', active: false },
  { value: '72', label: 'Scoopful', active: false },
  { value: '73', label: 'Sponge', active: true },
  { value: '74', label: 'Spray', active: false },
  { value: '75', label: 'Stick', active: true },
  { value: '76', label: 'Strip', active: true },
  { value: '23', label: 'Suppository', active: true },
  { value: '77', label: 'Swab', active: true },
  { value: '24', label: 'Syringe', active: false },
  { value: '25', label: 'Tablespoon', active: false },
  { value: '26', label: 'Tablet', active: true },
  { value: '78', label: 'Tabminder', active: false },
  { value: '79', label: 'Tampon', active: false },
  { value: '27', label: 'Teaspoon', active: false },
  { value: '80', label: 'Tray', active: false },
  { value: '81', label: 'Troche', active: true },
  { value: '29', label: 'Tube', active: false },
  { value: '19', label: 'Unspecified', active: true },
  { value: '31', label: 'Vial', active: false },
  { value: '82', label: 'Wafer', active: true },
];

export const DMETypes: Option[] = [
  {
    label: 'Compound',
    value: 'Compound',
  },
  {
    label: 'Medication',
    value: 'Medication',
  },
  {
    label: 'Supply',
    value: 'Supply',
  },
];
