import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useSignInWithTokenMutation } from 'ev-api/core';
import { setAuthToken } from 'ev-store/actions';
import { useAppDispatch } from 'ev-store/redux';
import { buildParamsMap } from 'ev-utils/browser';

const TOKEN_PARAMETER = 'auth_token';

export const useTokenAuth = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get(TOKEN_PARAMETER);

  const [signIn, { isLoading }] = useSignInWithTokenMutation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!token || isLoading) {
      return;
    }
    const signInWithToken = async () => {
      await signIn({ token });
      setSearchParams(
        buildParamsMap(searchParams, {
          exclude: TOKEN_PARAMETER,
        }),
        { replace: true },
      );
      dispatch(setAuthToken(token));
    };
    void signInWithToken();
  }, [token, setSearchParams, searchParams, signIn, dispatch, isLoading]);

  return { isSigningIn: isLoading || !!token };
};
