import { Components } from '@mui/material/styles/components';

import { EVColors } from 'ev-theme/styles';

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    large: true;
    extraLarge: true;
  }
}

export const MuiTextField: Components['MuiTextField'] = {
  styleOverrides: {
    root: {
      '.MuiInputBase-input::placeholder': {
        opacity: '90%',
        color: EVColors.mako,
      },
      '& .MuiInput-underline::after': {
        borderBottomColor: EVColors.cerulean,
      },
      '& .MuiInput-underline::before, &:hover .MuiInput-underline::before': {
        borderBottomColor: EVColors.silverBullet,
      },
      '.MuiInputBase-multiline': {
        padding: 0,
        width: '320px',
      },
      '.MuiInputBase-multiline.MuiInputBase-fullWidth': {
        width: '100%',
      },
      '.MuiInputBase-inputMultiline': {
        fontSize: '14px',
        padding: '10px 8px',
      },
      '.MuiInputLabel-root': {
        fontSize: '12px',
        fontWeight: 600,
      },
      '.MuiFormHelperText-root': {
        fontSize: '12px',
        lineHeight: '16px',
      },
      '& .ev-borderless, .ev-borderless:hover:not(.Mui-disabled)': {
        border: 0,
      },
      '&.ev-outside-label': {
        '.MuiInputLabel-root': {
          position: 'unset',
          transform: 'unset',
          textAlign: 'left',
          marginBottom: '4px',
        },
      },
      '& .MuiInputBase-adornedEnd': {
        button: {
          i: {
            margin: 0,
          },
        },
        i: {
          aspectRatio: '1/1',
          borderRadius: '50%',
          flexShrink: 0,
          flexGrow: 1,
          padding: 4,
          margin: '0 -4px 0 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            background: EVColors.alabaster,
            svg: {
              color: EVColors.cobalt,
            },
          },
        },
      },
      '& .MuiInputBase-adornedEnd.focused i, & .MuiInputBase-adornedEnd.Mui-focused i':
        {
          svg: {
            color: EVColors.cerulean,
          },
          background: EVColors.zircon,
        },
    },
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        '.MuiOutlinedInput-root:not(.MuiInputBase-multiline)': {
          height: '32px',
        },
        '.MuiOutlinedInput-input:not(.MuiInputBase-inputMultiline)': {
          padding: '8px',
          fontSize: 12,
        },
        '.MuiInputBase-adornedStart': {
          paddingLeft: '8px',
          '.MuiOutlinedInput-input': {
            paddingLeft: '0px',
          },
        },
        '.MuiInputAdornment-outlined > *, .MuiOutlinedInput-root.MuiInputBase-adornedEnd > span':
          {
            fontSize: 12,
            lineHeight: 12,
            color: EVColors.mako,
          },
        '.MuiInputBase-adornedEnd > i > svg': {
          width: '14px',
          height: '14px',
        },
        '.MuiFormHelperText-root': {
          fontSize: 12,
          svg: {
            width: '12px',
            height: '12px',
            marginRight: '4px',
          },
        },
      },
    },
    {
      props: { size: 'medium' },
      style: {
        '.MuiInputLabel-root': {
          fontSize: '14px',
        },
        '.MuiOutlinedInput-root:not(.MuiInputBase-multiline)': {
          height: '40px',
        },
        '.MuiOutlinedInput-input:not(.MuiInputBase-inputMultiline)': {
          padding: '8px',
          fontSize: 14,
        },
        '.MuiInputBase-adornedStart': {
          paddingLeft: '8px',
          '.MuiOutlinedInput-input': {
            paddingLeft: '0px',
          },
        },
        '.MuiInputAdornment-outlined > *, .MuiOutlinedInput-root.MuiInputBase-adornedEnd > span':
          {
            fontSize: 14,
            lineHeight: 14,
            color: EVColors.mako,
          },
        '.MuiInputBase-adornedEnd > i > svg': {
          width: '16px',
          height: '16px',
        },
        '.MuiFormHelperText-root': {
          fontSize: 12,
          marginTop: '4px',
          svg: {
            width: '12px',
            height: '12px',
            marginRight: '4px',
          },
        },
      },
    },
    {
      props: { size: 'large' },
      style: {
        '.MuiInputLabel-root': {
          fontSize: '14px',
        },
        '.MuiOutlinedInput-root:not(.MuiInputBase-multiline)': {
          height: '48px',
        },
        '.MuiOutlinedInput-input:not(.MuiInputBase-inputMultiline)': {
          padding: '16px',
          fontSize: 16,
        },
        '.MuiInputBase-adornedStart': {
          paddingLeft: '8px',
          '.MuiOutlinedInput-input': {
            paddingLeft: '0px',
          },
        },
        '.MuiInputAdornment-outlined > *, .MuiOutlinedInput-root.MuiInputBase-adornedEnd > span':
          {
            fontSize: 16,
            lineHeight: 16,
            color: EVColors.mako,
          },
        '.MuiInputBase-adornedEnd > i > svg': {
          width: '18px',
          height: '18px',
        },
        '.MuiFormHelperText-root': {
          fontSize: 14,
          marginTop: '2px',
          svg: {
            width: '14px',
            height: '14px',
            marginRight: '4px',
          },
        },
        '&.ev-inside-label': {
          '.MuiInputBase-input': {
            paddingBottom: 0,
            fontSize: 14,
          },
          '.MuiInputLabel-root': {
            fontSize: 16,
            fontWeight: 400,
            transform: 'translate(16px,14px) scale(1)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(16px,9px) scale(0.7)',
            },
          },
        },
        '&.ev-outside-label': {
          '.MuiInputLabel-root': {
            marginBottom: '2px',
          },
        },
      },
    },
    {
      props: { size: 'extraLarge' },
      style: {
        '.MuiInputLabel-root': {
          fontSize: '14px',
        },
        '.MuiOutlinedInput-root:not(.MuiInputBase-multiline)': {
          height: '56px',
        },
        '.MuiOutlinedInput-input:not(.MuiInputBase-inputMultiline)': {
          padding: '16px',
          fontSize: 16,
        },
        '.MuiInputBase-adornedStart': {
          paddingLeft: '8px',
          '.MuiOutlinedInput-input': {
            paddingLeft: '0px',
          },
        },
        '.MuiInputAdornment-outlined > *, .MuiOutlinedInput-root.MuiInputBase-adornedEnd > span':
          {
            fontSize: 18,
            lineHeight: 18,
            color: EVColors.mako,
          },
        '.MuiInputBase-adornedEnd > i > svg': {
          width: '20px',
          height: '20px',
        },
        '.MuiFormHelperText-root': {
          fontSize: 14,
          marginTop: '2px',
          svg: {
            width: '14px',
            height: '14px',
            marginRight: '4px',
          },
        },
        '&.ev-inside-label': {
          '.MuiInputBase-input': {
            paddingBottom: 0,
            fontSize: 14,
          },
          '.MuiInputLabel-root': {
            fontSize: 16,
            fontWeight: 400,
            transform: 'translate(16px,18px) scale(1)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(16px,9px) scale(0.7)',
            },
          },
        },
        '&.ev-outside-label': {
          '.MuiInputLabel-root': {
            marginBottom: '2px',
          },
        },
      },
    },
  ],
};
