export const MuiDialog = {
  styleOverrides: {
    root: {
      '& .MuiPaper-root': {
        overflowX: 'hidden',
      },
      '& .MuiDialogContent-root': {
        padding: '8px 24px 8px 24px',
      },
      '& .MuiDialogActions-root': {
        padding: '24px',
      },
      '& .MuiDialogTitle-root + .MuiDialogContent-root': {
        paddingTop: '8px',
      },
    },
  },
};
