import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type GlobalError = string | null;
const globalErrorInitialState = null as GlobalError;

export const globalErrorSlice = createSlice({
  name: 'globalError',
  initialState: globalErrorInitialState,
  reducers: {
    setGlobalError(state, action: PayloadAction<string>) {
      return action.payload;
    },
    clearGlobalError() {
      return null;
    },
  },
});
