import React from 'react';
// Importing module directly due to dependency tree error when bootstrapping
// eslint-disable-next-line no-restricted-imports
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import type {
  IconDefinition,
  IconName,
  SizeProp,
  Transform,
} from '@fortawesome/fontawesome-common-types';
import { faPhoneVolume } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonProps, ButtonTypes } from './Button';

export type IconProps = {
  icon: IconDefinition;
  margin?: string;
  id?: string;
  spin?: boolean;
  className?: string;
  transform?: string | Transform;
  size?: SizeProp;
  color?: string;
  buttonBehavior?: {
    id: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };
  buttonProps?: Partial<ButtonProps>;
  fixedWidth?: boolean;
};

const Icon = (props: IconProps) => {
  const {
    icon,
    id,
    margin,
    className,
    size,
    color,
    buttonBehavior,
    spin = false,
    transform,
    buttonProps,
    fixedWidth = true,
  } = props;

  let rotate = 0;
  if (icon === faPhoneVolume) {
    rotate = -45;
  }

  const { t } = useTranslation();

  const ALERT_ICONS: Record<IconName, string> = {
    'circle-exclamation': t('Error'),
    'triangle-exclamation': t('Warning'),
  } as const;

  const label = ALERT_ICONS[icon.iconName] || undefined;

  const iconComponent = (
    <StyledIcon
      $margin={margin}
      aria-label={label}
      className={className}
      color={color}
      data-testid={id}
      fixedWidth={fixedWidth}
      icon={icon}
      size={size}
      spin={spin}
      title={label}
      transform={transform || { rotate }}
    />
  );

  if (buttonBehavior) {
    const { id, onClick } = buttonBehavior;
    return (
      <Button
        id={id}
        label={`button-${id}`}
        minWidth="0"
        onClick={onClick}
        variant={ButtonTypes.Tertiary}
        {...buttonProps}
      >
        {iconComponent}
      </Button>
    );
  }

  return iconComponent;
};

export default Icon;

const StyledIcon = styled(FontAwesomeIcon)<{
  $margin?: string;
}>`
  ${p => p.$margin && `margin: ${p.$margin} !important`}
`;
