import { Base64 } from 'js-base64';

// -----------------------------------------------------------------------------------------
// Note:
// See: https://en.wikipedia.org/wiki/Base64#Variants_summary_table
//
// RA uses https://github.com/mathiasbynens/base64 to encode, which is a RFC 4648 compliant
// It maybe RFC 4648 §4, not URL safe, and the escaping is done by RA code
//
// This code uses the URL Safe flag to false, right now Corvel does not use the RFC 4648 §5 standard
// it would be good let the library do the special chars handling
// but unfortunately we have to migrate old RA code, as seen below, to keep the compatility
// -----------------------------------------------------------------------------------------

export function base64Encode(value: string, disableBase64UrlSafe: boolean) {
  return addBase64Padding(Base64.encode(value, !disableBase64UrlSafe));
}

export function base64Decode(value: string) {
  return Base64.decode(value);
}

const addBase64Padding = (value: string) => {
  return value + Array(((4 - (value.length % 4)) % 4) + 1).join('=');
};

// -----------------------------------------------------------------------------------------
// Migrated code from RA to keep URL safe and UTF-16 -> UTF-8 compatibility
const getUnicodeCharacter = (cp: number) => {
  if ((cp >= 0 && cp <= 0xd7ff) || (cp >= 0xe000 && cp <= 0xffff)) {
    return String.fromCharCode(cp);
  } else if (cp >= 0x10000 && cp <= 0x10ffff) {
    // we substract 0x10000 from cp to get a 20-bits number
    // in the range 0..0xFFFF
    cp -= 0x10000;

    // we add 0xD800 to the number formed by the first 10 bits
    // to give the first byte
    const first = ((0xffc00 & cp) >> 10) + 0xd800;

    // we add 0xDC00 to the number formed by the low 10 bits
    // to give the second byte
    const second = (0x3ff & cp) + 0xdc00;

    return String.fromCharCode(first) + String.fromCharCode(second);
  }
};

export const safeEscapeDecode = (str?: string) => {
  if (!str) {
    return str;
  }
  return str.replace(/%u([\dA-F]{4})/g, (m, p) => {
    const c = getUnicodeCharacter(parseInt(p, 16));
    if (!c) {
      return '';
    }
    return encodeURIComponent(c);
  });
};
// -----------------------------------------------------------------------------------------
