import { AuthenticationMethod } from 'ev-api/core/authentication-methods';

import { Azadb2cProvider } from './azadb2c-provider';
import { ChartQuestion } from './charting';
import { Id, Timestamp } from './common';
import { Customer } from './customer';
import { DynamicFormField } from './dynamic-form-field';
import { PracticeEnablementConfig, Preference } from './preference';
import { ServedLocation } from './served-location';
import { CustomAction } from './visit';
import { VisitSegment } from './visit-segment';

export type ProviderGroup = {
  id: Id;
  name: string;
};

export type PracticeAttributes = {
  allow_availability_provider_search: boolean;
  allow_scheduling_provider_search: boolean;
  allow_visit_cancel_reason: boolean;
  always_geoblock: boolean;
  available_apps: {
    Android: { protocol: string | null; buy_url: string | null };
    iOS: { protocol: string | null; buy_url: string | null };
  };
  background_images: string;
  branding: { favicon: string | null; logo: string | null };
  // business_hours: null
  contact_phone: string | null;
  cosignature_enabled: boolean;
  // coverage_plan_options: []
  created_at: string;
  // current_version: null
  // custom_styles: null
  custom_terms: boolean;
  default_locale: string;
  disallow_patient_enrollment: number;
  enablement_configs: { [k in PracticeEnablementConfig]?: boolean };
  env: string;
  ev_customer_id: Id | null;
  express_enabled: boolean;
  facebook?: string | null;
  fax: string | null;
  handle: string;
  // has_integration: null
  hostname: string;
  id: Id;
  is_branded: boolean;
  is_fully_branded: boolean;
  kind: string;
  localization_enabled: boolean;
  locations_served: ServedLocation;
  logo: string | null;
  name: string;
  office: string | null;
  owner_id: Id;
  payment_account_processor: string | null;
  phone: string;
  preferences: Preference[];
  status: string;
  has_connected_devices: boolean;
  twitter?: string | null;
  url?: string | null;
  verbiages: Preference[];
  video_solution: string;
  wonder_enabled: boolean;
  youtube?: string | null;
  timezone: string;
  supports_third_party_interpreters: boolean;
  provider_groups: ProviderGroup[];
};

export type Practice = {
  id: Id;
  attributes: PracticeAttributes;
  customer: Customer | null;
  dynamic_form_field?: DynamicFormField[];
  chart_questions: ChartQuestion[];
  attestation_questions: ChartQuestion[];
  azadb2c_providers: Azadb2cProvider[] | [];
  authentication_method?: AuthenticationMethod[];
  misc_question?: DynamicFormField[];
  visit_survey: {
    id: Id;
    message: string;
    default: boolean;
  } | null;
  visit_survey_questions?: DynamicFormField[];
  visit_segment?: VisitSegment[];
  address?: DynamicFormField;
  custom_actions: CustomAction[];
};

export type PracticeTimezones = {
  [id: string]: string;
};

export type PracticeDevice = {
  id: string;
  label: string;
  practice_id: Id;
  uuid: string;
  pusher_channel: string;
  session_status: PracticeDeviceSessionStatus;
};

export type VisitLinkForDevice = {
  link: string | undefined;
};

export type CustomerPracticeDevice = {
  id: Id;
  name: string;
  count: string;
};

export type AdminPractice = {
  id: Id;
  name: string;
  handle: string;
  supports_third_party_interpreters: boolean;
};

export enum PracticeDeviceSessionStatus {
  LOGGED_OUT = 'logged_out',
  AWAITING_CONNECTION = 'awaiting_connection',
  READY = 'ready',
  IN_VISIT = 'in_visit',
}

export type EditablePracticeAttributes = {
  name?: PracticeAttributes['name'];
  kind?: PracticeAttributes['kind'];
  url?: PracticeAttributes['url'];
  office?: PracticeAttributes['office'];
  contact_phone?: PracticeAttributes['contact_phone'];
  fax?: PracticeAttributes['fax'];
  facebook?: PracticeAttributes['facebook'];
  twitter?: PracticeAttributes['twitter'];
  youtube?: PracticeAttributes['youtube'];
};

export type ScheduledVisitPatientPractice = {
  allow_availability_provider_search: boolean;
  allow_scheduling_provider_search: boolean;
  always_geoblock: boolean;
  background_content_type: string | null;
  background_file_name: string | null;
  background_updated_at: string | null;
  balanced_customer_url: string | null;
  contact_phone: string | null;
  cosignature_enabled: boolean;
  created_at: Timestamp;
  created_via: string | null;
  crm_company_id: string | null;
  crm_platform: string | null;
  cross_practice_waiting_room_awareness: false;
  customer_id: Id | null;
  default_locale: string;
  disallow_patient_enrollment: number;
  dosespot_clinic_id: Id | null;
  dosespot_clinic_key: string | null;
  ev_customer_id: Id | null;
  evault_job_last_run: string | null;
  express_enabled: boolean;
  express_fallback_enabled: boolean;
  facebook: string | null;
  favicon_content_type: string | null;
  favicon_file_name: string | null;
  favicon_updated_at: Timestamp;
  fax: string | null;
  handle: string;
  hostname: string;
  id: Id;
  kind: string;
  logo_content_type: string | null;
  logo_file_name: string | null;
  logo_updated_at: Timestamp;
  name: string;
  office: string | null;
  phone: string | null;
  priority: number;
  status: string;
  sticky_intake_form: boolean;
  subscription_id: Id | null;
  timezone: string;
  twitter: string | null;
  updated_at: Timestamp;
  url: string | null;
  video_solution: string;
  visit_escalation_levels_enabled: boolean;
  wonder_enabled: boolean;
  youtube: string | null;
};

export type PracticeInsurancePlan = {
  id: Id;
  practice_id: Id;
  trading_partner_id: Id;
  practice_trading_partner_id: Id;
  external_id: Id | null;
  copay_override: string;
  name: string;
  status: 'active' | 'archived';
};
