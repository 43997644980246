import _ from 'lodash';
import { MouseEventHandler, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import MUIDialog, { DialogProps as MUIDialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { useTranslation } from 'ev-i18n';

import Button, {
  ButtonProps,
  ButtonSize,
  ButtonTypes,
} from 'ev-components/Button';
import Icon from 'ev-components/Icon';
import Text from 'ev-components/Text';
import useLayout from 'ev-hooks/layout';
import { EVColors } from 'ev-theme/styles';

type TextAlignment = 'left' | 'center';

export type DialogProps = {
  className?: string;
  children?: React.ReactNode;
  open: boolean;
  title?: string;
  titleColor?: string;
  icon?: IconDefinition;
  iconColor?: string;
  textAlign?: TextAlignment;
  onAccept?: MouseEventHandler | (() => void);
  onReject?: () => void;
  onClose?: () => void;
  acceptText?: string;
  rejectText?: string;
  acceptId?: string;
  rejectId?: string;
  banner?: React.ReactNode;
  width?: number | string;
  busy?: boolean;
  required?: boolean;
  hideReject?: boolean;
  hideAccept?: boolean;
  centerActions?: boolean;
  container?: Element | (() => Element | null) | null | undefined;
  sx?: { [key: string]: string | number | React.CSSProperties };
  headerMargin?: string;
  rejectButtonProps?: Partial<ButtonProps>;
  acceptButtonProps?: Partial<ButtonProps>;
  customAction?: () => void;
  renderRejectAction?: () => void;
  scroll?: MUIDialogProps['scroll'];
  scrollShadow?: boolean;
  hideCloseButton?: boolean;
  closeAfter?: number;
};

const Dialog = ({
  open,
  children,
  title,
  icon,
  iconColor,
  textAlign = 'center',
  onAccept = _.noop,
  onReject = _.noop,
  onClose = _.noop,
  acceptText,
  acceptId = 'dialog-primary',
  rejectText,
  rejectId = 'dialog-secondary',
  banner,
  width,
  busy,
  required,
  hideReject,
  hideAccept,
  centerActions,
  className = 'dialog',
  container = document.body,
  headerMargin,
  sx = {},
  rejectButtonProps,
  acceptButtonProps,
  customAction,
  scroll = 'body',
  scrollShadow,
  hideCloseButton = false,
  closeAfter,
}: DialogProps) => {
  const { t } = useTranslation();
  const { isMobile } = useLayout();
  const [isModalVisible, setIsModalVisible] = useState(open);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (open && closeAfter) {
      setTimer(
        setTimeout(() => {
          onClose();
        }, closeAfter),
      );
    }
  }, [open, closeAfter, onClose]);

  useEffect(() => {
    setIsModalVisible(open);
  }, [open]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const handleClose = () => {
    onClose();
    if (timer) {
      clearTimeout(timer);
    }
  };

  const renderActions = () => {
    const buttonSize = isMobile ? ButtonSize.Large : ButtonSize.Medium;
    const buttonVariant = isMobile
      ? ButtonTypes.Secondary
      : ButtonTypes.Special;

    return (
      (!hideReject || !hideAccept) && (
        <DialogActions
          sx={{
            justifyContent:
              banner || centerActions || isMobile ? 'center' : 'flex-end',
            boxShadow: scrollShadow
              ? '0px 2px 17px -4px rgba(0, 0, 0, 0.08)'
              : undefined,
          }}
        >
          {customAction?.()}
          {!hideReject && (
            <Button
              disabled={busy}
              fullWidth={isMobile}
              id={rejectId}
              onClick={onReject}
              size={buttonSize}
              variant={buttonVariant}
              {...rejectButtonProps}
            >
              {rejectText || t('Cancel')}
            </Button>
          )}
          {!hideAccept && (
            <Button
              busy={busy}
              disabled={busy}
              fullWidth={isMobile}
              id={acceptId}
              onClick={onAccept}
              size={buttonSize}
              variant={ButtonTypes.Primary}
              {...acceptButtonProps}
            >
              {acceptText || t('Ok')}
            </Button>
          )}
        </DialogActions>
      )
    );
  };

  const showCloseButton = () => !hideCloseButton && !banner && !required;

  return (
    <MUIDialog
      PaperProps={{
        sx: {
          width: width ?? (isMobile ? 'calc(100vw - 48px)' : 560),
          margin: '24px',
          '& .MuiDialogActions-root': {
            paddingTop: isMobile ? '8px' : '10px',
          },
          borderRadius: '8px',
        },
      }}
      className={className}
      container={container}
      onClose={required ? undefined : handleClose}
      open={isModalVisible}
      scroll={scroll}
      sx={sx}
    >
      {banner}
      <Stack
        alignItems="flex-start"
        className="dialogHeader"
        direction="row"
        margin={headerMargin}
        sx={{
          pt: '24px',
          px: '24px',
          pb: '9px',
        }}
      >
        {(icon || title) && (
          <Stack
            alignItems="center"
            direction="row"
            flex={1}
            justifyContent={textAlign === 'center' ? 'center' : 'flex-start'}
          >
            {icon && (
              <StyledIcon
                $textAlign={textAlign}
                color={iconColor}
                fixedWidth={false}
                icon={icon}
              />
            )}
            {title && (
              <Text.Title
                color={EVColors.asphalt}
                paddingLeft={
                  icon
                    ? '8px'
                    : textAlign === 'center' && showCloseButton()
                      ? '34px'
                      : '0px'
                }
                sx={{
                  display: 'block',
                  textAlign,
                }}
                bold
              >
                {title}
              </Text.Title>
            )}
          </Stack>
        )}
        {showCloseButton() && (
          <CloseButton
            ariaLabel={t('Close Dialog')}
            id="dialog-close"
            onClick={onClose}
            variant={ButtonTypes.Tertiary}
          >
            <Icon color={EVColors.vulcan} icon={faTimes} />
          </CloseButton>
        )}
      </Stack>
      {children && (
        <Content $align={textAlign}>
          <Text.Description color={EVColors.mako}>{children}</Text.Description>
        </Content>
      )}
      {renderActions()}
    </MUIDialog>
  );
};

export default Dialog;

const Content = styled(DialogContent)<{
  $align: TextAlignment;
}>`
  text-align: ${p => p.$align};
  min-height: 32px;
`;

const CloseButton = styled(Button)`
  min-width: 0;
  padding: 8px;
  height: 25px;
  left: 5px;
  margin-left: auto;
`;

const StyledIcon = styled(Icon)<{ $textAlign: TextAlignment }>`
  height: 24px;
  width: 24px;
  padding-left: ${p => (p.$textAlign === 'center' ? '34px' : '0px')};
`;
