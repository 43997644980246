import _ from 'lodash';

import { BaseIncludedElement } from 'ev-api/common/IncludedElementResponse';
import { sanitizeId } from 'ev-api/utils';
import {
  ChartAttestationType,
  ChartQuestion,
  ChartQuestionKind,
  ChartQuestionTemplate,
  ChartQuestionTemplateQuestion,
  ChartTemplateQuestionKind,
} from 'ev-types/charting';

import {
  IncludedChartQuestionResponse,
  IncludedChartQuestionTemplateQuestionResponse,
  IncludedChartQuestionTemplateResponse,
} from './responses';

const findIncludedElementsByType = (
  includedByType: BaseIncludedElement[],
  type: string,
) => {
  return _.filter(includedByType, { type });
};

export const transformChartQuestions = (
  allIncludedByType: Record<string, BaseIncludedElement[]>,
  chart_question_key: string,
  chart_question_template_key: string,
  chart_question_template_question_key: string,
) => {
  const chart_questions = findIncludedElementsByType(
    allIncludedByType[chart_question_key],
    chart_question_key,
  ) as IncludedChartQuestionResponse[];

  const chart_question_templates = findIncludedElementsByType(
    allIncludedByType[chart_question_template_key],
    chart_question_template_key,
  ) as IncludedChartQuestionTemplateResponse[];

  const chart_question_template_questions = findIncludedElementsByType(
    allIncludedByType[chart_question_template_question_key],
    chart_question_template_question_key,
  ) as IncludedChartQuestionTemplateQuestionResponse[];

  return chartQuestionResponseTransform(
    chart_questions,
    chart_question_templates,
    chart_question_template_questions,
  );
};

export const chartQuestionResponseTransform = (
  questionsResponse: IncludedChartQuestionResponse[],
  templatesResponse: IncludedChartQuestionTemplateResponse[],
  templateQuestionsResponse: IncludedChartQuestionTemplateQuestionResponse[],
): ChartQuestion[] => {
  const questions = questionsResponse.map(questionResponse => {
    const attestationType =
      questionResponse.attributes.attestation_type === 'signature'
        ? ChartAttestationType.Signature
        : questionResponse.attributes.attestation_type === 'cosignature'
          ? ChartAttestationType.Cosignature
          : undefined;
    return {
      ...questionResponse.attributes,
      id: sanitizeId(questionResponse.id),
      attestation_type: attestationType,
      templates: getTemplates(
        questionResponse,
        templatesResponse,
        templateQuestionsResponse,
      ),
      required: attestationType ? true : questionResponse.attributes.required,
    };
  });
  return _.compact(questions);
};

const getTemplates = (
  questionResponse: IncludedChartQuestionResponse,
  templatesResponse: IncludedChartQuestionTemplateResponse[],
  templateQuestionsResponse: IncludedChartQuestionTemplateQuestionResponse[],
): ChartQuestionTemplate[] => {
  const templates = questionResponse.relationships?.templates?.data.map(
    templateRelationship => {
      const templateResponse = _.find(templatesResponse, {
        id: sanitizeId(templateRelationship.id),
      });
      if (!templateResponse) {
        return null;
      }
      return {
        ...templateResponse.attributes,
        id: sanitizeId(templateResponse.id),
        kind:
          (templateResponse.attributes.kind ||
            templateResponse.attributes.template_type) === 'robust'
            ? ChartQuestionKind.Robust
            : ChartQuestionKind.Text,
        questions: getTemplateQuestions(
          templateResponse,
          templateQuestionsResponse,
        ),
      };
    },
  );
  return _.compact(templates);
};

const getTemplateQuestions = (
  templateResponse: IncludedChartQuestionTemplateResponse,
  templateQuestionsResponse: IncludedChartQuestionTemplateQuestionResponse[],
): ChartQuestionTemplateQuestion[] => {
  const templates = _.concat(
    _.map(templateResponse.relationships?.questions?.data, t => t.id),
    _.map(templateResponse.relationships?.robusts?.data, t => t.id),
  ).map(id => {
    const templateQuestion = _.find(templateQuestionsResponse, {
      id: sanitizeId(id),
    });
    if (!templateQuestion) {
      return null;
    }
    return {
      ...templateQuestion.attributes,
      kind: getTemplateKind(templateQuestion.attributes.kind),
      id: sanitizeId(templateQuestion.id),
      config: {
        ...templateQuestion.attributes.config,
        required:
          templateQuestion.attributes.config.required?.toLowerCase() === 'true',
      },
    };
  });
  return _.compact(templates);
};

const getTemplateKind = (kind: string) => {
  switch (kind) {
    case 'string':
      return ChartTemplateQuestionKind.String;
    case 'text':
      return ChartTemplateQuestionKind.Text;
    case 'options':
      return ChartTemplateQuestionKind.Options;
    case 'radio-options':
      return ChartTemplateQuestionKind.RadioOptions;
    case 'boolean':
      return ChartTemplateQuestionKind.Boolean;
    case 'date':
      return ChartTemplateQuestionKind.Date;
    case 'esignature':
      return ChartTemplateQuestionKind.eSignature;
    default:
      return ChartTemplateQuestionKind.String;
  }
};
