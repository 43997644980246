import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  STRIPE_HOST,
  STRIPE_LIVE_API_KEY,
  STRIPE_TEST_API_KEY,
} from 'ev-config/config';

import { StripeTokenParams, StripeTokenResponse } from './types';

const stripeQuery = (baseUrl = '/r-static/proxy/') => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      headers.set('x-target-host', STRIPE_HOST);
      return headers;
    },
  });
};

export const stripeApi = createApi({
  reducerPath: 'stripeApi',
  baseQuery: stripeQuery(),
  endpoints: builder => ({
    createStripeToken: builder.mutation<StripeTokenResponse, StripeTokenParams>(
      {
        query: ({
          name,
          number,
          expiryMonth,
          expiryYear,
          cvc,
          processor_mode,
        }) => ({
          url: `v1/tokens`,
          method: 'POST',
          headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
            Authorization: `Bearer ${
              processor_mode === 'live'
                ? STRIPE_LIVE_API_KEY
                : STRIPE_TEST_API_KEY
            }`,
          },
          body: encodeURI(
            `card[name]=${name}&card[number]=${number}&card[exp_month]=${expiryMonth}&card[exp_year]=20${expiryYear}&card[cvc]=${cvc}`,
          ),
        }),
      },
    ),
  }),
});

export default stripeApi;

export const { useCreateStripeTokenMutation } = stripeApi;
