import { Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import {
  GridColumnVisibilityModel,
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
  GridSortModel,
} from '@mui/x-data-grid-pro';

import { Id, VisitChartActions } from 'ev-types';

import { GridDensities } from 'ev-api/types';

export const DateFormat = 'yyyy-MM-dd';

export type CommonFilters = {
  searchFilter: string;
  density: GridDensities;
  columnsVisibility: GridColumnVisibilityModel;
  columnSorting: GridSortModel;
  columnFiltering: GridFilterModel;
};

export type ChartFilters = {
  chartActionsFilter: Record<VisitChartActions, boolean>;
};

export type ProvidersFilter = {
  providersFilter: Record<Id, true>;
};

export const commonInitialState = {
  searchFilter: '',
  density: GridDensities.Standard,
  columnsVisibility: {},
  columnSorting: {},
  columnFiltering: {
    items: [] as GridFilterItem[],
    logicOperator: 'and' as GridLogicOperator,
    quickFilterLogicOperator: 'and' as GridLogicOperator,
    quickFilterValues: [],
  } as GridFilterModel,
} as CommonFilters;

export const commonFilters = {
  setSearchFilter(
    state: Draft<{ searchFilter: string }>,
    action: PayloadAction<string>,
  ) {
    state.searchFilter = action.payload;
  },
  setDensity(
    state: Draft<CommonFilters>,
    action: PayloadAction<GridDensities>,
  ) {
    state.density = action.payload;
  },
  setColumnSorting(
    state: Draft<CommonFilters>,
    action: PayloadAction<GridSortModel>,
  ) {
    state.columnSorting = action.payload;
  },
  setColumnFiltering(
    state: Draft<CommonFilters>,
    action: PayloadAction<GridFilterModel>,
  ) {
    state.columnFiltering = action.payload;
  },
  toggleFieldVisibility(
    state: Draft<CommonFilters>,
    action: PayloadAction<{ field: string; hidden: boolean }>,
  ) {
    state.columnsVisibility[action.payload.field] = !action.payload.hidden;
  },
  setColumnsVisibility(
    state: Draft<CommonFilters>,
    action: PayloadAction<{ visibilityModel: GridColumnVisibilityModel }>,
  ) {
    state.columnsVisibility = action.payload.visibilityModel;
  },
};

export const chartInitialFilter = {
  chartActionsFilter: {
    [VisitChartActions.toSign]: false,
    [VisitChartActions.toCoSign]: false,
  } as Record<VisitChartActions, boolean>,
};

export const chartFilters = {
  setChartSignFilter(
    state: Draft<ChartFilters>,
    { payload }: PayloadAction<boolean>,
  ) {
    state.chartActionsFilter.to_sign = payload;
  },
  setChartCoSignFilter(
    state: Draft<ChartFilters>,
    { payload }: PayloadAction<boolean>,
  ) {
    state.chartActionsFilter.to_co_sign = payload;
  },
  clearChartActions(state: Draft<ChartFilters>) {
    _.forIn(state.chartActionsFilter, (_value, key, obj) =>
      _.set(obj, key, false),
    );
  },
};

export const initialProviderFilter: ProvidersFilter = {
  providersFilter: {},
};

export const providersFilter = {
  clearProvidersFilter(state: Draft<ProvidersFilter>) {
    _.each(state.providersFilter, (selected, id) => {
      delete state.providersFilter[id];
    });
  },
  selectProvider(
    state: Draft<ProvidersFilter>,
    action: PayloadAction<{ id: Id; selected: boolean }>,
  ) {
    const { id, selected } = action.payload;
    if (selected) {
      state.providersFilter[id] = true;
    } else {
      delete state.providersFilter[id];
    }
  },
};
