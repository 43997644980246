import { EVColors, Font, Typography } from 'ev-theme/styles';

export const MuiPaginationItem = {
  styleOverrides: {
    root: {
      ...Font.Regular,
      ...Typography.Description,
      background: 'none',
      color: EVColors.mako,
      borderRadius: 32,
      margin: '0 2px',
      width: 40,
      height: 40,
      lineHeight: '40px',
      '&.hover,&:hover:not(.MuiPaginationItem-ellipsis)': {
        ...Font.SemiBold,
        color: EVColors.gunpowder,
        backgroundColor: EVColors.alabaster,
      },
      '&.Mui-focusVisible,&.focus-visible': {
        ...Font.SemiBold,
        background: 'none',
      },
      '&.Mui-selected': {
        ...Font.SemiBold,
        color: EVColors.cerulean,
        background: 'none',
        '&.hover,&:hover': {
          backgroundColor: EVColors.zircon,
          color: EVColors.cobalt,
        },
        border: `1px solid ${EVColors.cerulean}`,
        '&.focus-visible,&.Mui-focusVisible': {
          backgroundColor: EVColors.zircon,
          color: EVColors.cerulean,
        },
      },
      '.MuiTouchRipple-root': {
        display: 'none',
      },
    },
    sizeSmall: {
      ...Font.Regular,
      ...Typography.Footnote,
      width: 32,
      height: 32,
      lineHeight: '32px',
    },
    sizeLarge: {
      ...Font.Regular,
      ...Typography.Description,
      width: 48,
      height: 48,
      lineHeight: '48px',
    },
  },
};
