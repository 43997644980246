import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import {
  convertToDateRangeValue,
  DateRangeShortcutType,
  DateRangeValue,
  getDateRangeShortcutValue,
} from 'ev-components/DateRange';

import { ScheduleViewMode } from 'app-provider/Scheduling/SchedulingButtons';

import {
  chartFilters,
  chartInitialFilter,
  commonFilters,
  commonInitialState,
  DateFormat,
  initialProviderFilter,
  providersFilter,
} from './common';

const initialState = {
  ...commonInitialState,
  ...chartInitialFilter,
  ...initialProviderFilter,
  scheduleViewMode: 'table',
  statusFilter: {
    scheduled: false,
    checkedIn: false,
  },
  dateRangeFilter: {
    format: DateFormat,
    value: convertToDateRangeValue(
      getDateRangeShortcutValue(DateRangeShortcutType.Next14Days),
      DateFormat,
    ),
  },
};

export const schedulingViewSlice = createSlice({
  name: 'schedulingViewSlice',
  initialState,
  reducers: {
    ...commonFilters,
    ...chartFilters,
    ...providersFilter,
    setStatusScheduledFilter(state, action: PayloadAction<boolean>) {
      state.statusFilter.scheduled = action.payload;
    },
    setStatusCheckedInFilter(state, action: PayloadAction<boolean>) {
      state.statusFilter.checkedIn = action.payload;
    },
    clearVisitStatusFilter(state) {
      state.statusFilter = {
        scheduled: false,
        checkedIn: false,
      };
    },
    setDateRangeFilter(state, action: PayloadAction<DateRangeValue>) {
      state.dateRangeFilter.value = action.payload;
    },
    clearDateRangeFilter(state) {
      state.dateRangeFilter.value = convertToDateRangeValue(
        getDateRangeShortcutValue(DateRangeShortcutType.Next14Days),
        DateFormat,
      );
    },
    setViewMode(state, action: PayloadAction<ScheduleViewMode>) {
      state.scheduleViewMode = action.payload;
    },
    resetFilters(state) {
      _.each(initialState, (object, key) => _.set(state, key, object));
    },
  },
});

export const {
  clearChartActions,
  clearDateRangeFilter,
  clearProvidersFilter,
  clearVisitStatusFilter,
  resetFilters,
  selectProvider,
  setChartCoSignFilter,
  setChartSignFilter,
  setColumnFiltering,
  setColumnSorting,
  setDateRangeFilter,
  setDensity,
  setSearchFilter,
  setStatusCheckedInFilter,
  setStatusScheduledFilter,
  setViewMode,
  toggleFieldVisibility,
  setColumnsVisibility,
} = schedulingViewSlice.actions;
