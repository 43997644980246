import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import _ from 'lodash';
import { initReactI18next } from 'react-i18next';

export const nt = _.identity;

export const DEFAULT_LOCALE = 'en';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

void i18n
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`./locales/${language}/translation.json`)
        .then(resource => callback(null, resource))
        .catch(error => callback(error, null));
    }),
  )
  .use(initReactI18next)
  .init({
    returnNull: false,
    lng: DEFAULT_LOCALE,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    nsSeparator: false,
  });

window.i18n = i18n;

export default i18n;
