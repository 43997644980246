import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Id } from 'ev-types';

type Avatar = Record<Id, string>;
const avatarInitialState = {} as Avatar;

export const avatarSlice = createSlice({
  name: 'avatar',
  initialState: avatarInitialState,
  reducers: {
    setAvatar(state, action: PayloadAction<{ userId: Id; dataUrl: string }>) {
      const { userId, dataUrl } = action.payload;
      state[userId] = dataUrl;
    },
    clearAvatar() {
      return {};
    },
  },
});
