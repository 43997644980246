import { encode } from 'js-base64';
import _ from 'lodash';
import { useState } from 'react';

import { Azadb2cProvider, Practice } from 'ev-types';

import { useNullableCurrentUser } from 'ev-hooks/commonData';
import { redirect } from 'ev-utils/browser';

const AUTHORIZE_COMMAND = 'authorize';
const RESPONSE_TYPE = 'code';
const RESPONSE_MODE = 'query';
const MOBILE_WEB = 'mobile_web';
const DESKTOP = 'desktop';

export const buildSSOLoginURL = (
  isMobile: boolean,
  azadb2cProvider: Azadb2cProvider,
) => {
  return (
    `${azadb2cProvider.provider_scheme}://${azadb2cProvider.provider_host}/${azadb2cProvider.domain_name}/oauth2/v2.0/${AUTHORIZE_COMMAND}?` +
    `client_id=${azadb2cProvider.client_id}` +
    `&response_type=${RESPONSE_TYPE}` +
    `&redirect_uri=${encodeURIComponent(azadb2cProvider.redirect_uri || '')}` +
    `&response_mode=${RESPONSE_MODE}` +
    `&scope=${azadb2cProvider.scope?.split(',').join('+')}` +
    `&p=${azadb2cProvider.policy_name}` +
    `&state=${encode(
      JSON.stringify({
        idphandle: azadb2cProvider.idphandle,
        user_type: azadb2cProvider.user_type,
        platform: isMobile ? MOBILE_WEB : DESKTOP,
      }),
    )}` +
    `&rand=${Math.random()}` +
    `&radSSO=true`
  );
};

export const useSSOInfo = () => {
  const currentUser = useNullableCurrentUser();
  const postSSOLogoutURL =
    currentUser?.attributes.post_sign_out_target_url ?? null;

  return {
    loggedInWithSSO: !!postSSOLogoutURL,
    postSSOLogoutURL,
  };
};

export const useSSOLogin = (practice?: Practice) => {
  const [redirected, setRedirected] = useState(false);

  const b2cSSOProvider = _.find(
    practice?.authentication_method,
    auth => auth.attributes.sso_provider_type === 'Azadb2cProvider',
  );
  if (!!b2cSSOProvider && !redirected) {
    redirect(b2cSSOProvider.attributes.login_url);
    setRedirected(true);
  }
  return { isSSOEnabled: !!b2cSSOProvider };
};
