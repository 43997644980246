import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const timezoneNagSlice = createSlice({
  name: 'timezoneNag',
  initialState: false,
  reducers: {
    setTimezoneNag(state, action: PayloadAction<boolean>) {
      state = action.payload;
      return state;
    },
  },
});
