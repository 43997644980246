import Pusher, { Channel } from 'pusher-js';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  BACKEND_PORT,
  HTTP_PROTOCOL,
  PUSHER_CLUSTER,
  PUSHER_KEY,
  SERVER_HOST,
} from 'ev-config/config';

export type PusherAuthType = 'chat' | 'simple' | 'device';

export type PusherChannelOptions = {
  skipBindToEvent?: boolean;
  skipChannelSubscription?: boolean;
  unsubscribeChannelOnUnmount?: boolean;
  authType?: PusherAuthType;
  errorHandler?: (error: unknown) => void;
};

Pusher.logToConsole = true;

export const PUSHER_SUBSCRIPTION_ERROR_EVENT = 'pusher:subscription_error';

// Global pusher instance
export let gPusherWithChatAuth: Pusher | null = null;
export let gPusherWithSimpleAuth: Pusher | null = null;
export let gPusherWithDeviceAuth: Pusher | null = null;

export function usePusherWithChatAuth() {
  const [pusher, setPusher] = useState(gPusherWithChatAuth);

  useEffect(() => {
    if (gPusherWithChatAuth) {
      setPusher(gPusherWithChatAuth);
      return;
    }

    const endpoint = '/r-static/proxy/api/v2/pusher/auth';
    const proxyHost = `${HTTP_PROTOCOL}://${SERVER_HOST}:${BACKEND_PORT}`;

    const config = {
      cluster: PUSHER_CLUSTER,
      encrypted: true,
      authEndpoint: endpoint,
      auth: {
        headers: {
          'X-Target-Host': proxyHost,
        },
      },
    };

    const newPusher = new Pusher(PUSHER_KEY, config);

    gPusherWithChatAuth = newPusher;

    setPusher(newPusher);

    return () => {
      pusher?.disconnect();
    };
  }, [pusher]);

  return pusher;
}

export function usePusherWithSimpleAuth() {
  const [pusher, setPusher] = useState(gPusherWithSimpleAuth);
  useEffect(() => {
    if (gPusherWithSimpleAuth) {
      setPusher(gPusherWithSimpleAuth);
      return;
    }

    const endpoint = '/r-static/proxy/api/v4/pusher/simple_auth';
    const proxyHost = `${HTTP_PROTOCOL}://${SERVER_HOST}:${BACKEND_PORT}`;

    const config = {
      cluster: PUSHER_CLUSTER,
      encrypted: true,
      authEndpoint: endpoint,
      auth: {
        headers: {
          'X-Target-Host': proxyHost,
        },
      },
    };

    const newPusher = new Pusher(PUSHER_KEY, config);

    gPusherWithSimpleAuth = newPusher;

    setPusher(newPusher);

    return () => {
      pusher?.disconnect();
    };
  }, [pusher]);

  return pusher;
}

export function usePusherWithDeviceAuth() {
  const [pusher, setPusher] = useState(gPusherWithDeviceAuth);

  useEffect(() => {
    if (gPusherWithDeviceAuth) {
      setPusher(gPusherWithDeviceAuth);
      return;
    }

    const endpoint = '/r-static/proxy/api/v2/pusher/device_auth';
    const proxyHost = `${HTTP_PROTOCOL}://${SERVER_HOST}:${BACKEND_PORT}`;

    const config = {
      cluster: PUSHER_CLUSTER,
      encrypted: true,
      authEndpoint: endpoint,
      auth: {
        headers: {
          'X-Target-Host': proxyHost,
        },
      },
    };

    const newPusher = new Pusher(PUSHER_KEY, config);

    gPusherWithDeviceAuth = newPusher;

    setPusher(newPusher);

    return () => {
      pusher?.disconnect();
    };
  }, [pusher]);

  return pusher;
}

const usePusherInstance = (
  authType: PusherAuthType = 'chat',
): Pusher | null => {
  const simplePusher = usePusherWithSimpleAuth();
  const chatPusher = usePusherWithChatAuth();
  const devicePusher = usePusherWithDeviceAuth();

  switch (authType) {
    case 'chat':
      return chatPusher;
    case 'simple':
      return simplePusher;
    case 'device':
      return devicePusher;
  }
};

const usePusherChannel = (
  channelName: string,
  options?: PusherChannelOptions,
): [boolean, Channel?] => {
  const pusher = usePusherInstance(options?.authType);

  const [pusherChannel, setPusherChannel] = useState<Channel>();
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      if (channelName !== pusherChannel?.name && pusherChannel?.name) {
        pusher?.unsubscribe(pusherChannel.name);
        setIsSubscribed(false);
      }
    };
  }, [channelName, isSubscribed, pusher, pusherChannel?.name]);

  useEffect(() => {
    if (!pusher || !channelName || options?.skipChannelSubscription) {
      return;
    }

    setPusherChannel(pusher.subscribe(channelName));
    setIsSubscribed(true);

    return () => {
      if (options?.unsubscribeChannelOnUnmount) {
        pusher.unsubscribe(channelName);
      }
    };
  }, [
    channelName,
    isSubscribed,
    options?.skipChannelSubscription,
    options?.unsubscribeChannelOnUnmount,
    pusher,
  ]);

  return [isSubscribed, pusherChannel];
};

export function usePusherChannelSubscription(
  channelName: string,
  eventName: string,
  handler: (event: unknown) => void,
  options?: PusherChannelOptions,
) {
  const [isSubscribed, channel] = usePusherChannel(channelName, options);

  const trigger = useCallback(
    (event: string, data: unknown) => {
      channel?.trigger(event, data);
    },
    [channel],
  );

  useEffect(() => {
    if (!channel || options?.skipBindToEvent) {
      return;
    }

    channel.bind(eventName, handler);
    if (options?.errorHandler) {
      channel.bind(PUSHER_SUBSCRIPTION_ERROR_EVENT, options.errorHandler);
    }

    return () => {
      channel.unbind(eventName, handler);
    };
  }, [
    channel,
    eventName,
    handler,
    options?.skipBindToEvent,
    options?.errorHandler,
  ]);

  return useMemo(() => ({ isSubscribed, trigger }), [isSubscribed, trigger]);
}
