import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { commonFilters, commonInitialState } from './common';

const initialState = {
  ...commonInitialState,
  patientStatus: {
    registered: false,
    pending: false,
  },
  patientActivity: {
    recentlyRegistered: false,
    recentlySeen: false,
  },
};

export const patientsViewSlice = createSlice({
  name: 'patientsViewSlice',
  initialState,
  reducers: {
    ...commonFilters,
    setStatusPendingFilter(state, action: PayloadAction<boolean>) {
      state.patientStatus.pending = action.payload;
      state.patientStatus.registered = false;
    },
    setStatusRegisteredFilter(state, action: PayloadAction<boolean>) {
      state.patientStatus.pending = false;
      state.patientStatus.registered = action.payload;
    },
    setActivityRecentlyRegisteredFilter(state, action: PayloadAction<boolean>) {
      state.patientActivity.recentlyRegistered = action.payload;
      state.patientActivity.recentlySeen = false;
    },
    setActivityRecentlySeenFilter(state, action: PayloadAction<boolean>) {
      state.patientActivity.recentlyRegistered = false;
      state.patientActivity.recentlySeen = action.payload;
    },
    clearStatusFilter(state) {
      state.patientStatus = {
        pending: false,
        registered: false,
      };
    },
    clearActivityFilter(state) {
      state.patientActivity = {
        recentlyRegistered: false,
        recentlySeen: false,
      };
    },
    resetFilters(state) {
      _.each(initialState, (object, key) => _.set(state, key, object));
    },
  },
});

export const {
  setSearchFilter,
  setDensity,
  clearActivityFilter,
  clearStatusFilter,
  resetFilters,
  setActivityRecentlyRegisteredFilter,
  setActivityRecentlySeenFilter,
  setStatusPendingFilter,
  setStatusRegisteredFilter,
  setColumnFiltering,
  setColumnSorting,
  toggleFieldVisibility,
  setColumnsVisibility,
} = patientsViewSlice.actions;
