import { ResponseId } from 'ev-types';

import { isObject } from 'ev-utils/types';

export type AddressResponse = {
  id: ResponseId;
  type: 'address';
  attributes: AddressAttributesResponse;
};

export const isAddressResponse = (value: unknown): value is AddressResponse => {
  return isObject(value) && value.type === 'address';
};

export type AddressAttributesResponse = {
  id: ResponseId;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  zip?: string;
  addressable_id?: ResponseId;
  addressable_type?: string;
  created_at?: string;
  updated_at?: string;
  name?: string;
  primary?: boolean;
  country_iso?: string;
  latitude?: number | string | null;
  location?: { lat: number; lng: number };
  longitude?: number | string | null;
};
