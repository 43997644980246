import {
  Address,
  AddressComponent,
  AddressCoordinatesResponse,
  GeocodingResponse,
} from './types';

const convertAddressComponentsToAddress = (components: AddressComponent[]) => {
  const convertedAddress: Address = {
    line1: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  };

  components.forEach(component => {
    const type = component.types;
    if (type.indexOf('street_number') >= 0) {
      convertedAddress.line1 =
        component.long_name + (convertedAddress.line1 || '');
    } else if (type.indexOf('route') >= 0) {
      convertedAddress.line1 =
        (convertedAddress.line1 + ' ' || '') + component.short_name;
    } else if (type.indexOf('locality') >= 0) {
      convertedAddress.city = component.long_name;
    } else if (type.indexOf('administrative_area_level_1') >= 0) {
      convertedAddress.state = component.short_name;
    } else if (type.indexOf('postal_code') >= 0) {
      convertedAddress.zip = component.long_name;
    } else if (type.indexOf('country') >= 0) {
      convertedAddress.country = component.short_name;
      if (('' + component.short_name).match(/^(pr|vi|mp|gu)$/i)) {
        convertedAddress.state = component.short_name.toUpperCase();
      }
    }
  });

  return convertedAddress;
};

export const transformGeocodeResponse = (
  data: GeocodingResponse,
): AddressCoordinatesResponse => {
  if (data.error_message) {
    throw new Error(data.error_message);
  }

  const result = data.results[0];

  return {
    address: convertAddressComponentsToAddress(result.address_components),
    formattedAddress: result.formatted_address,
    geometry: result.geometry,
    place_id: result.place_id,
  };
};
