import { AppDispatch } from 'ev-store';
import { NotificationChannel } from 'ev-types';

import { currentUserApi } from 'ev-api/core';

export function setNotificationChannels(
  dispatch: AppDispatch,
  notificationChannels: NotificationChannel[],
) {
  const action = currentUserApi.util.updateQueryData(
    'getCurrentUser',
    undefined,
    currentUser => {
      currentUser.attributes.notification_channels = notificationChannels;
    },
  );

  return dispatch(action);
}
