import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const filtersMenuContext = createContext({
  filtersMenuOpen: false,
  setFiltersMenuOpen: {} as Dispatch<SetStateAction<boolean>>,
});

export const FiltersMenuProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [filtersMenuOpen, setFiltersMenuOpen] = useState<boolean>(false);

  return (
    <filtersMenuContext.Provider
      value={{ filtersMenuOpen, setFiltersMenuOpen }}
    >
      {children}
    </filtersMenuContext.Provider>
  );
};

export const useFiltersMenu = () => {
  const { filtersMenuOpen, setFiltersMenuOpen } =
    useContext(filtersMenuContext);

  useEffect(() => {
    if (filtersMenuOpen) {
      setFiltersMenuOpen(false);
    }
  }, [filtersMenuOpen, setFiltersMenuOpen]);

  return useMemo(() => {
    return {
      isFiltersMenuOpen: filtersMenuOpen,
      openFiltersMenu: () => setFiltersMenuOpen(true),
    };
  }, [filtersMenuOpen, setFiltersMenuOpen]);
};
