import { Elevations, EVColors } from 'ev-theme/styles';

export const MuiMenu = {
  styleOverrides: {
    root: {
      '.MuiPaper-root': {
        borderRadius: 6,
        color: EVColors.gunpowder,
        boxShadow: Elevations.elevation4.boxShadow,
        '.MuiList-padding:has(.MuiDataGrid-panelWrapper)': {
          paddingBottom: '0px',
        },
      },
    },
  },
};
