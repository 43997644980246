import _ from 'lodash';
import { createContext, useContext, useState } from 'react';

export const mobileContext = createContext({
  mobileBarTitle: document.title,
  setMobileBarTitle: _.noop,
});

export const MobileProvider = ({ children }: { children: React.ReactNode }) => {
  const [mobileBarTitle, setMobileBarTitle] = useState(document.title);

  return (
    <mobileContext.Provider value={{ mobileBarTitle, setMobileBarTitle }}>
      {children}
    </mobileContext.Provider>
  );
};

export default function useMobile() {
  const { mobileBarTitle, setMobileBarTitle } = useContext(mobileContext);
  return { mobileBarTitle, setMobileBarTitle };
}
