import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState: false,
  reducers: {
    toggleDrawer(state) {
      return !state;
    },
    setDrawerOpen(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});
