import classnames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import { nt, useTranslation } from 'ev-i18n';
import { Practice, PracticePreference } from 'ev-types';

import { ReactComponent as ChromeImage } from 'ev-assets/images/chrome.svg';
import { ReactComponent as EdgeImage } from 'ev-assets/images/edge.svg';
import EvisitLogo from 'ev-assets/images/evisit-logo-color.svg';
import { ReactComponent as FirefoxImage } from 'ev-assets/images/firefox.svg';
import { ReactComponent as SafariImage } from 'ev-assets/images/safari.svg';
import { HeaderLogo } from 'ev-common/HeaderComponents';
import Button, { ButtonSize, ButtonTypes } from 'ev-components/Button';
import Link from 'ev-components/Link';
import Text from 'ev-components/Text';
import useBrowserCheck from 'ev-hooks/browserCheck';
import useLayout from 'ev-hooks/layout';
import { EVColors } from 'ev-theme/styles/Colors';
import { clipboardExists, copyToClipboard } from 'ev-utils/clipboard';
import { getPracticePreference } from 'ev-utils/practice';

const DOWNLOAD_CHROME_URL = 'https://www.google.com/chrome/';
const DOWNLOAD_FIREFOX_URL = 'https://www.mozilla.org/en-US/firefox/new/';
const DOWNLOAD_SAFARI_URL = 'https://support.apple.com/downloads/safari';
const DOWNLOAD_EDGE_URL = 'https://www.microsoft.com/en-us/edge';
const DOWNLOAD_SAFARI_MOBILE_URL =
  'https://itunes.apple.com/us/app/safari/id1146562112';
const DOWNLOAD_CHROME_MOBILE_URL =
  'https://play.google.com/store/apps/details?id=com.android.chrome&hl=en';
const GOOGLE_PLAY_URL = 'market://details?id=com.evisit.app';
const APPLE_STORE_URL =
  'https://itunes.apple.com/us/app/evisit-telehealth/id981640895?mt=8';

export type UnsupportedBrowserProps = {
  practice?: Practice;
};

const UnsupportedBrowser = ({ practice }: UnsupportedBrowserProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useLayout();
  const logo = practice?.attributes.branding.logo || EvisitLogo;
  const { isAndroidBrowser, isIosBrowser, isMacSystem } = useBrowserCheck();
  const isAndroidOrIos = isAndroidBrowser || isIosBrowser;
  const iosUrl =
    practice?.attributes.available_apps.iOS.buy_url || APPLE_STORE_URL;
  const androidUrl =
    practice?.attributes.available_apps.Android.buy_url || GOOGLE_PLAY_URL;
  const [searchParams] = useSearchParams();
  const isExpress = !!searchParams.get('express');
  const [isClicked, setIsClicked] = useState(false);
  const isVideoApp = window.location.href.includes('/video/');
  const hasCopyInviteLink = isVideoApp && clipboardExists();

  const hideUnsupportedBrowserNativeAppAccess = getPracticePreference(
    practice,
    PracticePreference.HideUnsupportedBrowserNativeAppAccess,
  );

  useEffect(() => {
    if (isClicked) {
      const timerId = setTimeout(() => {
        setIsClicked(false);
      }, 1500);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [isClicked]);

  const copyInviteLink = async () => {
    setIsClicked(true);

    await copyToClipboard(async () => {
      const link = window.location.href;
      return link || Promise.reject();
    });
  };

  const getDescription = () => {
    if (hasCopyInviteLink) {
      return isAndroidOrIos
        ? t('Copy your invite link and paste it into a browser listed below:')
        : t(
            'Copy your invite link and paste it into one of the browsers below:',
          );
    } else {
      return isAndroidOrIos
        ? t('Please use a browser listed below:')
        : t('Please use one of the following browsers instead:');
    }
  };

  const className = isDesktop ? 'desktop' : 'mobile';

  const renderBrowsers = () => {
    const browsers = {
      chrome: {
        url: isAndroidBrowser
          ? DOWNLOAD_CHROME_MOBILE_URL
          : DOWNLOAD_CHROME_URL,
        icon: <ChromeImage />,
        text: isAndroidBrowser ? nt('Android') : t('MacOS or Windows'),
        shouldRender: !isIosBrowser,
      },
      safari: {
        url: isIosBrowser ? DOWNLOAD_SAFARI_MOBILE_URL : DOWNLOAD_SAFARI_URL,
        icon: <SafariImage />,
        text: isIosBrowser ? nt('iOS') : nt('MacOS'),
        shouldRender: isMacSystem || isIosBrowser,
      },
      firefox: {
        url: DOWNLOAD_FIREFOX_URL,
        icon: <FirefoxImage />,
        text: t('MacOS or Windows'),
        shouldRender: !isAndroidOrIos,
      },
      edge: {
        url: DOWNLOAD_EDGE_URL,
        icon: <EdgeImage />,
        text: nt('Windows'),
        shouldRender: !isMacSystem && !isAndroidOrIos,
      },
    };

    return (
      <BrowsersContainer
        className={classnames(className, { hasCopyInviteLink })}
      >
        {_.map(browsers, (browser, key) => {
          return (
            browser.shouldRender && (
              <BrowserContainer key={key}>
                {browser.icon}
                <BrowserLink href={browser.url} id={`${key}-link`} underlined>
                  {_.capitalize(nt(key))}
                </BrowserLink>
                <Text.Footnote color={EVColors.mako} paddingTop="10px">
                  {browser.text}
                </Text.Footnote>
              </BrowserContainer>
            )
          );
        })}
      </BrowsersContainer>
    );
  };

  const renderDownloadApp = () => {
    if (
      isAndroidOrIos &&
      !hideUnsupportedBrowserNativeAppAccess &&
      !isExpress
    ) {
      return (
        <>
          <Text.Body color={EVColors.gunpowder}>
            {t(
              `You may also download your practice's native app by clicking below`,
            )}
          </Text.Body>
          <Button
            href={isAndroidBrowser ? androidUrl : iosUrl}
            id="download-app"
            margin="16px 0 0 0"
            size={ButtonSize.Large}
            variant={ButtonTypes.Secondary}
            fullWidth
          >
            {t('Download App')}
          </Button>
        </>
      );
    }
  };

  return (
    <Container className={className}>
      <HeaderLogo src={logo} />
      <ContentContainer className={className}>
        <Text.Headline1 color={EVColors.asphalt} bold>
          {t('Unsupported Browser')}
        </Text.Headline1>
        <Text.Headline5 color={EVColors.gunpowder} paddingTop="16px">
          {t(`You're using an unsupported browser.`)}
        </Text.Headline5>
        <Text.Headline5 color={EVColors.gunpowder}>
          {getDescription()}
        </Text.Headline5>
        {hasCopyInviteLink && (
          <CopyInviteLinkButton
            className={className}
            endIcon={isClicked ? faCheck : undefined}
            fullWidth={!isDesktop}
            id="copy-invite-link"
            onClick={copyInviteLink}
            size={isDesktop ? ButtonSize.Medium : ButtonSize.Large}
            startIcon={isClicked ? undefined : faCopy}
          >
            {isClicked ? t('Copied') : t('Copy invite link')}
          </CopyInviteLinkButton>
        )}
        {renderBrowsers()}
        {renderDownloadApp()}
      </ContentContainer>
    </Container>
  );
};

export default UnsupportedBrowser;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  &.mobile {
    padding: 16px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 56px;

  &.mobile {
    align-items: center;
    text-align: center;
    padding-top: 40px;
  }
`;

const CopyInviteLinkButton = styled(Button)`
  margin-top: 16px;
  min-width: 160px;

  &.mobile {
    margin-top: 32px;
  }
`;

const BrowsersContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding-top: 80px;

  &.mobile {
    justify-content: center;
    padding-top: 48px;
  }

  svg {
    margin-bottom: 24px;
  }

  &.mobile,
  &.hasCopyInviteLink {
    svg {
      width: 64px;
      height: 64px;
      margin-bottom: 16px;
    }
  }
`;

const BrowserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 64px;

  &:last-child {
    padding-right: 0;
  }
`;

const BrowserLink = styled(Link)`
  font-size: 20px;
`;
