import { Components } from '@mui/material/styles/components';

import { EVColors, Typography } from 'ev-theme/styles';

declare module '@mui/material/Autocomplete' {
  interface AutocompletePropsSizeOverrides {
    large: true;
    extraLarge: true;
  }
}

const fontSize = {
  small: Typography.Footnote.fontSize,
  medium: Typography.Description.fontSize,
  large: Typography.Body.fontSize,
  extraLarge: Typography.Body.fontSize,
} as const;

const padding = {
  small: '12px 16px',
  medium: '15px 16px',
  large: '18px 16px',
  extraLarge: '18px 16px',
} as const;

export const MuiAutocomplete: Components['MuiAutocomplete'] = {
  styleOverrides: {
    popper: ({ ownerState: { size = 'small' } }) => ({
      '.MuiAutocomplete-noOptions': {
        color: EVColors.mako,
        fontStyle: 'italic',
        fontSize: fontSize[size],
      },
      '.MuiAutocomplete-option': {
        fontSize: fontSize[size],
        minHeight: 0,
        padding: padding[size],
      },
    }),
    root: {
      '&.hover .MuiInputBase-root': {
        backgroundColor: EVColors.alabaster,
        borderColor: EVColors.aluminum,
      },
      '&.active .MuiInputBase-root': {
        borderColor: `${EVColors.cerulean} !important`,
      },
      '& .MuiInputBase-root': {
        padding: 0,
        '&:hover': {
          backgroundColor: EVColors.alabaster,
          borderColor: EVColors.aluminum,
        },
        '&.Mui-focused': {
          borderColor: EVColors.cerulean,
        },
        '&.Mui-disabled': {
          backgroundColor: EVColors.concrete,
        },
      },
      '& .MuiInputLabel-root': {
        fontSize: 14,
        fontWeight: 600,
        color: EVColors.mako,
        zIndex: 1,
        pointerEvents: 'none',
      },
      '& .MuiInputLabel-root.Mui-disabled': {
        color: EVColors.mercury,
      },
      '& .MuiAutocomplete-endAdornment': {
        top: '45%',
        '.MuiButtonBase-root': {
          fontSize: 16,
        },
        '.MuiAutocomplete-popupIndicatorOpen': {
          marginTop: '3px',
        },
        button: {
          backgroundColor: 'transparent',
        },
      },
      '& .MuiInputBase-inputAdornedEnd': {
        marginRight: '18px',
      },
      '&.ev-inside-label': {
        '.MuiInputBase-root': {
          marginTop: 0,
          border: `1px solid ${EVColors.aluminum}`,
          borderRadius: 4,
          '.MuiInputBase-input': {
            transform: 'translate(0,8px)',
          },
        },
        '.MuiInputLabel-root': {
          fontWeight: 400,
          fontSize: 16,
          textOverflow: 'ellipsis',
        },
      },
      '&.ev-outside-label': {
        '.MuiInputLabel-root': {
          position: 'unset',
          transform: 'unset',
          textAlign: 'left',
          marginBottom: '4px',
        },
      },
    },
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        '& .MuiInputBase-root.MuiInputBase-sizeSmall': {
          height: 32,
          padding: 0,
          '.MuiInputBase-input': {
            padding: '4px 8px',
            fontSize: 12,
            height: 18,
          },
        },
        '& .MuiInputLabel-root': {
          fontSize: 12,
        },
        '& .MuiFormHelperText-root': {
          fontSize: 12,
        },
        '&.ev-inside-label': {
          '.MuiInputLabel-root': {
            transform: 'translate(16px,7px) scale(1)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(16px,9px) scale(0.7)',
            },
          },
        },
      },
    },
    {
      props: { size: 'medium' },
      style: {
        '& .MuiTextField-root .MuiInputLabel-root': {
          fontSize: 14,
        },
        '& .MuiInputBase-root': {
          height: 40,
          padding: 0,
          '.MuiInputBase-input': {
            padding: '8px 8px',
            fontSize: 14,
            height: 22,
          },
        },
        '& .MuiFormHelperText-root': {
          fontSize: 12,
          marginTop: '4px',
        },
        '&.ev-inside-label': {
          '.MuiInputLabel-root': {
            transform: 'translate(16px,10px) scale(1)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(16px,9px) scale(0.7)',
            },
          },
        },
      },
    },
    {
      props: { size: 'large' },
      style: {
        '& .MuiInputBase-root': {
          height: 48,
          padding: 0,
          '.MuiInputBase-input': {
            fontSize: 16,
            padding: '12px 16px',
          },
        },
        '& .MuiFormHelperText-root': {
          fontSize: 14,
          marginTop: '2px',
        },
        '&.ev-inside-label': {
          '.MuiInputBase-input': {
            fontSize: 14,
          },
          '.MuiInputLabel-root': {
            fontSize: 16,
            transform: 'translate(16px,14px) scale(1)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(16px,9px) scale(0.7)',
            },
          },
        },
        '&.ev-outside-label': {
          '.MuiInputLabel-root': {
            marginBottom: '2px',
          },
        },
      },
    },
    {
      props: { size: 'extraLarge' },
      style: {
        '& .MuiInputBase-root': {
          height: 56,
          padding: 0,
          '.MuiInputBase-input': {
            fontSize: 18,
            padding: '14px 16px',
          },
        },
        '& .MuiFormHelperText-root': {
          fontSize: 14,
          marginTop: '2px',
        },
        '&.ev-inside-label': {
          '.MuiInputBase-input': {
            fontSize: 16,
          },
          '.MuiInputLabel-root': {
            fontSize: 14,
            transform: 'translate(16px,17px) scale(1)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(17px,9px) scale(0.625)',
            },
          },
        },
        '&.ev-outside-label': {
          '.MuiInputLabel-root': {
            marginBottom: '2px',
          },
        },
      },
    },
  ],
};
