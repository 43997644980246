enum PatientPaths {
  AddDependent = 'add_dependent',
  ConfirmLocation = 'confirm_location',
  HealthRecords = 'health_records',
  Home = 'home',
  MyAccount = 'my_account',
  Register = 'register',
  ReviewDetails = 'review_details',
  SelectDate = 'select_date',
  SelectPatient = 'select_patient',
  SelectProvider = 'select_provider',
  SelectVisitType = 'select_visit_type',
  SwitchPractice = 'switch_practice',
  UpcomingVisits = 'upcoming_visits',
  VisitHistory = 'visit_history',
  VisitInformation = 'visit_information',
  PharmacySearch = 'pharmacy_search',
  Payments = 'payments',
  Insurance = 'insurance',
  InsuranceForm = 'insurance/form',
  InsuranceVerificationFailed = 'insurance/verification_failed',
  InsurancePersonalInformation = 'insurance/personal_information',
  AddInsurance = 'insurance/add',
  ConfirmInsurance = 'insurance/confirm',
  Intake = 'intake',
  DependentInformation = 'dependent_information',
}

export default PatientPaths;
