import { useCallback, useEffect } from 'react';

import { useGetCurrentUserQuery } from 'ev-api/core';
import { isReactNative, URL_PREFIX } from 'ev-config/config';
import { setTimezoneNag } from 'ev-store/actions';
import { useAppDispatch } from 'ev-store/redux';
import { redirect } from 'ev-utils/browser';
import { isMobileBrowser } from 'ev-utils/is-mobile-browser';
import { SessionStorage } from 'ev-utils/storage';

import LoginPaths from 'app-login/paths';
import PatientPaths from 'app-patient/paths';
import ProviderPaths from 'app-provider/paths';

import { useAppNavigate } from './navigate';

export const useActiveSessionRedirect = (skip = false) => {
  const navigate = useAppNavigate();
  const redirectToRoleHome = useRedirectToRoleHome();

  const {
    data: currentUser,
    isFetching,
    isError,
  } = useGetCurrentUserQuery(undefined, { skip });

  useEffect(() => {
    if (skip) {
      return;
    }
    if (isError) {
      navigate(`${URL_PREFIX}/login/${LoginPaths.Login}`);
    } else if (!isFetching && currentUser) {
      const { role } = currentUser.attributes;
      redirectToRoleHome(role);
    }
  }, [isFetching, isError, navigate, currentUser, redirectToRoleHome, skip]);
};

export const useRedirectToRoleHome = () => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const isMobileDevice = isMobileBrowser() || isReactNative();

  return useCallback(
    (role: string) => {
      // We need to check if the user was previously entering a visit via a shareable
      // link and push them back into the visit. Once that is done we can remove that
      // session storage entry to reduce risk of them being pushed back in after the
      // visit ends.
      const SSORedirectUrl = SessionStorage.getItem('sso_redirect_url');
      SessionStorage.removeItem('sso_redirect_url');
      if (SSORedirectUrl) {
        redirect(SSORedirectUrl);
        return;
      }
      switch (role) {
        case 'patient':
          dispatch(setTimezoneNag(true));
          navigate(`${URL_PREFIX}/patient/${PatientPaths.Home}`);
          break;
        case 'admin':
        case 'superadmin':
        case 'customeradmin':
        case 'support':
          if (!isMobileDevice) {
            redirect('/admin/dashboard');
          }
          break;
        case 'scheduler':
          if (!isMobileDevice) {
            navigate(`${URL_PREFIX}/provider/patients`);
          }
          break;
        case 'advocate':
          navigate(`${URL_PREFIX}/provider/${ProviderPaths.CheckIn}`);
          break;
        case 'attendee':
          break;
        default:
          navigate(`${URL_PREFIX}/provider/waiting_room`);
      }
    },
    [navigate, isMobileDevice, dispatch],
  );
};
