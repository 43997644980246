import { User } from 'ev-types';

import { UserIncludedElement, UserResponseData } from 'ev-api/core';
import { userTransform } from 'ev-api/core/users/transformers';

type ProvidersResponse = {
  data: UserResponseData[];
  included: UserIncludedElement[];
};

export const getProviderTransform = (response: ProvidersResponse): User => {
  return userTransform({
    data: response.data[0],
    included: response.included,
  });
};
