import _ from 'lodash';
import React, { createContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';

import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import {
  CloudfrontAlertWithFilters,
  LocalizedMessages,
  useGetCloudfrontAlertsQuery,
} from 'ev-api/cloudfront';
import Icon from 'ev-components/Icon';
import { EVColors } from 'ev-theme/styles/Colors';
import { redirect } from 'ev-utils/browser';
import { SessionStorage } from 'ev-utils/storage';

import {
  useNullableCurrentPractice,
  useNullableCurrentUser,
} from './commonData';

const AlertContext = createContext({ setAlert: _.noop });
// One minute poll interval
const SYSTEM_ALERTS_POLLING_INTERVAL = 1 * (60 * 1000);

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [alert, setAlert] = useState<CloudfrontAlertWithFilters | null>(null);

  const currentUser = useNullableCurrentUser();
  const currentPractice = useNullableCurrentPractice();

  const {
    data: cloudfrontAlert,
    isFetching: isFetchingAlerts,
    refetch,
  } = useGetCloudfrontAlertsQuery(null, {
    pollingInterval: SYSTEM_ALERTS_POLLING_INTERVAL,
  });

  useEffect(() => {
    if (!isFetchingAlerts && cloudfrontAlert?.length) {
      cloudfrontAlert.forEach(alert => {
        const idIsInStorage = SessionStorage.getItem(alert.id) === 'true';
        const practiceHandle = currentPractice?.attributes.handle;

        const locale = currentUser?.attributes.locale;
        const userLanguage = locale === 'es' ? 'es_MX' : locale;

        // alert has not been discarded and it corresponds to the current practice
        const displayAlert = alert.parsedFilter?.PRACTICE_HANDLE
          ? alert.parsedFilter.PRACTICE_HANDLE === practiceHandle
          : true;
        if (!idIsInStorage && displayAlert) {
          // alert has support for the user set language
          if (alert[userLanguage as keyof CloudfrontAlertWithFilters]) {
            const language = alert[
              userLanguage as keyof CloudfrontAlertWithFilters
            ] as LocalizedMessages;
            setAlert({
              ...alert,
              ...language,
            });
          } else {
            setAlert(alert);
          }
        }
      });
    }
  }, [
    setAlert,
    cloudfrontAlert,
    isFetchingAlerts,
    currentPractice?.attributes.handle,
    currentUser?.attributes.locale,
  ]);

  const alertOpen = !!alert;

  const clearAlert = async () => {
    if (alert) {
      SessionStorage.setItem(`${alert.id}`, 'true');
    }
    setAlert(null);
    await refetch();
  };

  const redirectToUrl = () => {
    if (alert?.actionURL) {
      redirect(alert.actionURL);
    }
  };

  const getColor = (type: string) => {
    let color = '';
    switch (type) {
      case 'warning':
        color = EVColors.sunflower;
        break;
      case 'error':
      default:
        color = EVColors.maraschino;
        break;
    }
    return color;
  };

  if (document.readyState !== 'complete') {
    return null;
  }

  return (
    <AlertContext.Provider value={{ setAlert }}>
      <Slide direction="down" in={alertOpen} mountOnEnter unmountOnExit>
        <AlertContainer
          $color={getColor(alert?.type || 'error')}
          data-testid="evisit-alert"
          key={alertOpen ? 'alert-open' : 'alert-closed'}
          role="alert"
        >
          <Grid container>
            <MessageContainer mobile={10} item>
              {alert?.title && <span>{alert.title}</span>}
              {alert?.title && alert.message && <span>: </span>}
              {alert?.message && <span>{alert.message}</span>}
            </MessageContainer>
            <Grid mobile={2} textAlign="right" item>
              {alert?.actionURL ? (
                <Button onClick={redirectToUrl}>
                  <span>{alert.actionTitle}</span>
                </Button>
              ) : (
                <Button onClick={clearAlert}>
                  <Icon icon={faTimes} />
                </Button>
              )}
            </Grid>
          </Grid>
        </AlertContainer>
      </Slide>
      {children}
    </AlertContext.Provider>
  );
};

const AlertContainer = styled.div<{ $color: string }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 64px;
  background: ${p => p.$color};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-sizing: border-box;
  padding: 0 32px;
`;

const MessageContainer = styled(Grid)`
  font-weight: 700;
  font-size: 14px;
`;

const Button = styled.button`
  border: none;
  background: none;
  color: white;
  cursor: pointer;
`;
