import { EVColors } from 'ev-theme/styles';

export const MuiTooltip = {
  styleOverrides: {
    popper: {
      '& .MuiTooltip-tooltip': {
        height: 'auto',
        borderRadius: '4px',
        padding: '8px 16px',
        gap: '10px',
        fontWeight: '400',
        fontSize: '12px',
      },
      '.MuiTooltip-arrow': {
        '::before': {
          margin: '0px',
          borderRadius: '2px',
        },
      },
      '&[data-popper-placement*="top-end"] > .MuiTooltip-tooltip > .MuiTooltip-arrow':
        {
          left: '-8px !important',
        },
      '&[data-popper-placement*="top-start"] > .MuiTooltip-tooltip > .MuiTooltip-arrow':
        {
          left: '8px !important',
        },
      '&[data-popper-placement*="bottom-end"] > .MuiTooltip-tooltip > .MuiTooltip-arrow':
        {
          left: '-8px !important',
        },
      '&[data-popper-placement*="bottom-start"] > .MuiTooltip-tooltip > .MuiTooltip-arrow':
        {
          left: '8px !important',
        },
      '&[data-popper-placement*="left-end"] > .MuiTooltip-tooltip > .MuiTooltip-arrow':
        {
          top: '-8px !important',
        },
      '&[data-popper-placement*="left-start"] > .MuiTooltip-tooltip > .MuiTooltip-arrow':
        {
          top: '8px !important',
        },
      '&[data-popper-placement*="right-end"] > .MuiTooltip-tooltip > .MuiTooltip-arrow':
        {
          top: '-8px !important',
        },
      '&[data-popper-placement*="right-start"] > .MuiTooltip-tooltip > .MuiTooltip-arrow':
        {
          top: '8px !important',
        },
      '& .light': {
        backgroundColor: EVColors.zircon,
        color: EVColors.asphalt,
        '.MuiTooltip-arrow': {
          color: EVColors.zircon,
        },
      },
      '& .dark': {
        backgroundColor: EVColors.asphalt,
        color: EVColors.white,
        '.MuiTooltip-arrow': {
          color: EVColors.asphalt,
        },
      },
    },
  },
};
