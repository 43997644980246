import _ from 'lodash';
import { isValidElement } from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const isString = (value: unknown): value is string => {
  return _.isString(value);
};

export const isStringArray = (value: unknown): value is string[] => {
  return !!value && _.isArray(value) && _.every(value, isString);
};

export const isArray = (value: unknown): value is unknown[] => {
  return _.isArray(value);
};

export const isNumber = (value: unknown): value is number => {
  return _.isNumber(value);
};

export const isNumberArray = (value: unknown): value is number[] => {
  return !!value && _.isArray(value) && _.every(value, isNumber);
};

export const isFunction = (
  value: unknown,
): value is (...args: unknown[]) => unknown => {
  return _.isFunction(value);
};

export const isObject = (value: unknown): value is Record<string, unknown> => {
  return _.isPlainObject(value);
};

export const isFAIcon = (value: unknown): value is IconDefinition => {
  if (!value) {
    return false;
  }

  return !isValidElement(value);
};

export const isValidNumber = (...args: string[]): boolean => {
  const regex = /^-?(0|[1-9]\d*)?(\.\d+)?$/;
  return _.every(args, arg => regex.test(arg));
};
