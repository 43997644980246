import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import { AlertProvider } from 'ev-hooks/alert';
import { AnalyticsProvider } from 'ev-hooks/analytics';
import { CommonDataProvider } from 'ev-hooks/commonData';
import { DialogProvider } from 'ev-hooks/dialog';
import { EnvironmentProvider } from 'ev-hooks/environment';
import { FiltersMenuProvider } from 'ev-hooks/filtersMenu';
import { LayoutProvider } from 'ev-hooks/layout';
import { MobileProvider } from 'ev-hooks/mobile';
import { ToastProvider } from 'ev-hooks/toast';
import { makeDisplayName } from 'ev-hooks/utils';
import { ReduxProvider } from 'ev-store/redux';
import { theme } from 'ev-theme/theme';
import Environments from 'ev-types/environments';

import ErrorBoundary from './ErrorBoundary';

export const CoreProviders = ({
  children,
  __environment,
}: {
  children: React.ReactNode;
  __environment?: Environments;
}) => {
  return (
    <EnvironmentProvider __environment={__environment}>
      <ReduxProvider>
        <Router>
          <ErrorBoundary>
            <LayoutProvider>
              <FiltersMenuProvider>
                <ThemeProvider theme={theme}>
                  <ToastProvider>
                    <MobileProvider>{children}</MobileProvider>
                  </ToastProvider>
                </ThemeProvider>
              </FiltersMenuProvider>
            </LayoutProvider>
          </ErrorBoundary>
        </Router>
      </ReduxProvider>
    </EnvironmentProvider>
  );
};

export const AppProviders = ({
  children,
  requiresAuth = true,
}: {
  children: React.ReactNode;
  requiresAuth: boolean;
}) => {
  return (
    <CommonDataProvider requiresAuth={requiresAuth}>
      <AnalyticsProvider>
        <AlertProvider>
          <DialogProvider>{children}</DialogProvider>
        </AlertProvider>
      </AnalyticsProvider>
    </CommonDataProvider>
  );
};

export const withCoreProviders = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  const HOC = (props: P) => {
    return (
      <CoreProviders>
        <Component {...props} />
      </CoreProviders>
    );
  };

  HOC.displayName = makeDisplayName('withCoreProviders', Component);
  return HOC;
};

export const withAppProviders = <P extends object>(
  Component: React.ComponentType<P>,
  requiresAuth: boolean = true,
) => {
  const HOC = (props: P) => {
    return (
      <AppProviders requiresAuth={requiresAuth}>
        <Component {...props} />
      </AppProviders>
    );
  };

  HOC.displayName = makeDisplayName('withAppProviders', Component);
  return HOC;
};
