import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const testOverlaySlice = createSlice({
  name: 'testModeOverlayVisible',
  initialState: true,
  reducers: {
    setTestModeOverlayVisible(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});
