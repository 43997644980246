import _ from 'lodash';

import { CloudfrontAlertResponse, CloudfrontAlertWithFilters } from './types';

export const transformAlerts = (
  data: CloudfrontAlertResponse[],
): CloudfrontAlertWithFilters[] | [] => {
  if (!data.length) {
    return [];
  }
  const parsedData = data.map(alert => {
    if (!alert.filter?.length) {
      return alert;
    }
    const accumulator: string[][] = [];
    alert.filter.forEach(filter => {
      accumulator.push(filter.split(':'));
    });
    return {
      ...alert,
      parsedFilter: _.fromPairs(accumulator),
    };
  });
  return parsedData.filter(alert => alert.enabled);
};
