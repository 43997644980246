import { AddressAttributesResponse } from 'ev-api/common/AddressResponse';
import { IncludedElementResponsePatient } from 'ev-api/common/IncludedElementResponse';
import { Attachment, OpenTextNotes } from 'ev-api/core';
import { VisitStates } from 'ev-api/types';
import { Sanitized } from 'ev-api/utils';

import { Id, Timestamp } from './common';
import { CustomColumns } from './custom-columns';
import { DynamicFormFieldKind } from './dynamic-form-field';
import {
  PracticeEnablementConfig,
  Preference,
  ScheduledVisitPreference,
} from './preference';
import { Role } from './role';
import { CondensedUser, ScheduledVisitPatientData, User } from './user';
import { Verbiage } from './verbiages';
import { CondensedVisitType, VisitType } from './visit-type';
import { VisitTypeMembership } from './visit-type-membership';

export type VisitSurveyItem = {
  name: string;
  label: string;
  kind: DynamicFormFieldKind;
  response: null | number | string | boolean | { label: string; value: string };
};

export type VisitSurvey = null | VisitSurveyItem[];

export type VisitAttributes = {
  addendum_signed_at: Timestamp;
  attachments: Attachment[];
  auxiliary_datapoints: Record<string, string>;
  calculated_price: null;
  canceled_at: Timestamp;
  capture: boolean;
  chat_last_read_at: Timestamp;
  chat_message_count: number;
  chat_session_id: Id | null;
  chat_unread_message_count: number;
  chat_last_message_user?: {
    id: Id;
    first_name: string;
    last_name: string;
    thumb_url: string;
    has_avatar: boolean;
    last_sent_at: string;
    is_attendee: boolean;
  } | null;
  check_in_at: Timestamp;
  completed_at: Timestamp;
  confirmed_at: Timestamp;
  copay: Copay;
  cosigned_at: Timestamp;
  cosigned_by_user_id: Id | null;
  created_at: Timestamp;
  current_segment_id: Id | null;
  custom_columns?: CustomColumns;
  dropped_by_primary: boolean;
  duration_seconds: number;
  estimated_duration_in_minutes: number | null;
  expired_at: Timestamp;
  finished_at?: Timestamp;
  has_been_paid: boolean;
  health_doc_id: Id;
  id: Id;
  kind: 'quick_entry' | 'default' | null;
  last_requeued_by: string | null;
  mode: string;
  needs_patient_confirmation: boolean;
  obfuscated_id: Id;
  open_text_notes: OpenTextNotes | null;
  paid: boolean;
  patient_geolocation: { country: string; state?: string; ip: string };
  patient_id: Id;
  patient_location?: string;
  patient_status: string | null;
  practice_id: Id;
  preferences: Preference[];
  preferred_physician_id: Id | null;
  prefers_first_available: boolean;
  reason_for_no_charge: string | null;
  requires_cosign: boolean;
  scheduled_at: Timestamp;
  signed_at: Timestamp;
  signed_by_user_id: Id | null;
  started_at: Timestamp;
  state_geo_determined_by?: GeolocationSource;
  state: VisitStates;
  submitted_at: Timestamp;
  updated_at: Timestamp;
  vault_id: Id;
  verbiages: Preference[];
  visit_survey: VisitSurvey;
  visit_type_id: Id;
  provider_group_id: Id | null;
  end_visit_callback_url: string | null;
};

export type PatientLocation = {
  country_code: string;
  country_name: string;
  determined_by: GeolocationSource;
  ip: string;
  state_code: string | null;
  type: string; //"ipv4",
  continent_code: string; //"SA",
  continent_name: string; //"South America",
  region_code: string; //"LR",
  region_name: string; //"Los Ríos Region",
  city: string; //"Las Animas",
  zip: string | null; //null,
  latitude: number; //-39.83399963378906,
  longitude: number; //-73.22100067138672,
  location: {
    geoname_id: number; //6458708,
    capital: string; //"Santiago",
    languages: [
      {
        code: string; //"es",
        name: string; //"Spanish",
        native: string; //"Español"
      },
    ];
    country_flag: string; //"https://assets.ipstack.com/flags/cl.svg",
    country_flag_emoji: string; //"🇨🇱",
    country_flag_emoji_unicode: string; //"U+1F1E8 U+1F1F1",
    calling_code: string; //"56",
    is_eu: boolean; //false
  };
  time_zone: {
    id: string; //"America/Santiago",
    current_time: string; //"2023-01-30T14:51:29-03:00",
    gmt_offset: number; //-10800,
    code: string; //"-03",
    is_daylight_saving: boolean; //true
  };
  currency: {
    code: string; //"CLP",
    name: string; //"Chilean Peso",
    plural: string; //"Chilean pesos",
    symbol: string; //"CL$",
    symbol_native: string; //"$"
  };
  connection: {
    asn: number; //14117,
    isp: string; //"Telefonica Del Sur s.A."
  };
};

export type PatientLocationOverridden = {
  country: string; //"US"
  overridden_state_code: string; // "AL"
};

export type GeolocationSource =
  | 'geoip'
  | 'patient_override'
  | 'provider_override';

export type Visit = {
  id: Id;
  attributes: VisitAttributes;
};

export type VisitWithPatient = Visit & {
  patient: User;
};

export type VisitsForPatient = {
  impending: UpcomingVisitAttributes[];
  scheduled: UpcomingVisitAttributes[];
  unscheduled: UpcomingVisitAttributes[];
  unsubmitted: UpcomingVisitAttributes[];
};

export type UpcomingVisitAttributes = {
  attachments: unknown[];
  calculated_price: number | null;
  canceled_at: Timestamp;
  capture: boolean;
  chat_message_count: number;
  chat_session_id: Id | null;
  chat_unread_message_count: number;
  check_in_at: Timestamp;
  completed_at: Timestamp;
  confirmed_at: Timestamp;
  copay: Copay | null;
  created_at: string;
  initiated_by?: 'physician' | 'patient' | null;
  dropped_by_primary: boolean;
  duration_seconds: number | null;
  enablement_configs: { [k in PracticeEnablementConfig]?: boolean };
  estimated_duration_in_minutes: number | null;
  has_been_paid: boolean;
  health_doc_id: Id;
  id: Id;
  kind: 'quick_entry' | 'default' | null;
  last_requeued_by: string | null;
  mode: string;
  needs_patient_confirmation: boolean;
  obfuscated_id: Id;
  patient_data: ScheduledVisitPatientData;
  patient_id: Id;
  patient_location: string | null;
  platforms: unknown[];
  practice_id: Id;
  preferences: ScheduledVisitPreference[];
  preferred_physician_id: Id;
  prefers_first_available: boolean;
  reason_for_no_charge: string | null;
  scheduled_at: Timestamp;
  signed_at: Timestamp;
  signed_by_user_id: Id | null;
  started_at: Timestamp;
  state: VisitsForPatientState;
  state_geo_determined_by: GeolocationSource | null;
  submitted_at: Timestamp;
  updated_at: Timestamp;
  vault_id: string;
  verbiages: Verbiage[];
  visit_survey: VisitSurvey;
  visit_type_id: Id;
};

export type CurrencyCode = 'USD' | 'CAN';

export type Copay = {
  cents: number;
  currency_iso: CurrencyCode;
};

export type JoinVisitUser = {
  creator_user_id: Id | null;
  id: Id;
  secure_authentication_token: string;
  notification_channel: string;
  skip_agreements?: boolean;
  vam?: string;
};

export type JoinVisit = {
  status: Id;
  success: boolean;
  user?: JoinVisitUser;
};

export type CancelVisit = {
  id: number;
  state: 'canceled';
  success: boolean;
};

export type InitiateVisit = {
  id: number;
  state: 'active';
  success: boolean;
};

export type CompleteVisit = {
  id: number;
  state: 'unprescribed';
  success: boolean;
};

export type SubmitVisit = {
  id: number;
  state: 'submitted';
  success: boolean;
};

export type ScheduleVisit = {
  id: number;
  state: 'scheduled';
  success: boolean;
};

export type AllowAttendees = {
  id: number;
  state: 'unprescribed';
  success: boolean;
};

export type BeginImpendingVisit = {
  id: number;
  state: 'submitted';
  success: boolean;
};

export type SearchVisitsResult = {
  visits: Array<
    VisitAttributes & {
      patient: Sanitized<IncludedElementResponsePatient>;
      patientAddress: Sanitized<AddressAttributesResponse>;
    }
  >;
  meta: {
    segment_visit_counts: Record<string, number>;
    total: number;
    total_in_set: number;
  };
};

export type VisitsForPatientState =
  | VisitStates.Impending
  | VisitStates.Scheduled
  | VisitStates.Unscheduled
  | VisitStates.Unsubmitted;

export type UpcomingVisitData = {
  attributes: UpcomingVisitAttributes;
  visitType: VisitType;
  visitTypeMembership: VisitTypeMembership;
  provider: User;
};

export type UpcomingVisits = {
  todaysVisits: UpcomingVisitData[];
  futureVisits: UpcomingVisitData[];
};

export type VisitParticipant = {
  id: Id;
  deviceInstanceID?: [string];
  attributes: {
    age: number;
    dob: string;
    email: string;
    first_name: string;
    full_name: string;
    gender: string;
    has_avatar: boolean;
    id: Id;
    is_dependent: boolean;
    large_url: string;
    last_name: string;
    medium_url: string;
    middle_name: string;
    phone_cell: string;
    phone_home: string;
    phone_work: string;
    profile_valid: boolean;
    profile_valid_at: string;
    role: string;
    roles?: Role[];
    thumb_url: string;
    timezone: string;
    tiny_url: string;
    visit_duration: number;
  };
};

export enum VisitStatus {
  All = 'all',
  Waiting = 'waiting',
  Upcoming = 'upcoming',
  Completed = 'completed',
  Recent = 'recent',
  Incomplete = 'incomplete',
  Expired = 'expired',
  PatientWaiting = 'patient_waiting',
  Scheduled = 'scheduled',
  Canceled = 'canceled',
  CheckedIn = 'checked_in',
}

export enum VisitKind {
  All = 'all',
  Default = 'default',
  OnDemand = 'on_demand',
  Scheduled = 'scheduled',
}

export enum VisitChartActions {
  toSign = 'to_sign',
  toCoSign = 'to_co_sign',
}

export type CondensedVisit = {
  id: Id;
  attributes: VisitAttributes;
  patient?: CondensedUser;
  preferred_physician?: CondensedUser;
  visit_type?: CondensedVisitType;
};

export type CondensedVisits = {
  visits: CondensedVisit[];
  meta: {
    total: number;
    total_in_set: number;
  };
};

export type CustomVisitStatus = {
  id: Id;
  name: string;
  label: string;
  color: string;
  trigger: string;
  locked: boolean;
  order: number;
};

export type Segment = {
  id: Id;
  order: number;
  name: string;
  active: boolean;
  statuses?: CustomVisitStatus[];
};

export type CustomAction = {
  id?: Id;
  label_id: string;
  section?: string;
  label: string | null;
  default_label?: string;
  action: string | null;
  visible: boolean | null;
  editable?: boolean;
  hidable?: boolean;
};
