import { useEffect } from 'react';

import { isAndroid, isReactNative } from 'ev-config/config';

interface AllEvents extends WindowEventMap {
  // Fired by the Dialog component when its about to be dismissed via the Esc key.
  // Allows other components to prevent the dialog from closing.
  closeDialog: CustomEvent;
}

export type UseEventsProps = {
  [key in keyof AllEvents]?: EventListenerOrEventListenerObject;
};

const useEvents = (props: UseEventsProps, capture: boolean = false) => {
  useEffect(() => {
    const isAndroidRN = isReactNative() && isAndroid;
    for (const [key, func] of Object.entries(props)) {
      (isAndroidRN && key !== 'resize' ? document : window).addEventListener(
        key,
        func,
        capture,
      );
    }
    return () => {
      for (const [key, func] of Object.entries(props)) {
        (isAndroidRN && key !== 'resize'
          ? document
          : window
        ).removeEventListener(key, func, capture);
      }
    };
  }, [props, capture]);
};

export default useEvents;
