import { Id } from './common';

export type VisitSegment = {
  id: Id;
  type: 'visit_segment';
  attributes: VisitSegmentAttributes;
};

export enum SegmentCollectors {
  Waiting = 'waiting',
  Impending = 'impending',
  Active = 'active',
}

export type VisitSegmentAttributes = {
  id: Id;
  collector: SegmentCollectors;
  name: string;
  order: number;
  owner_id: null;
  owner_type: string;
  roles?: string;
};
