import _ from 'lodash';

import { AppDispatch } from 'ev-store';

import { currentUserApi } from 'ev-api/core';
import { UserPreference } from 'ev-types/preference';

export function userPreferenceOptimisticUpdate(
  dispatch: AppDispatch,
  preferenceName: UserPreference,
  value: string | string[] | boolean,
) {
  const action = currentUserApi.util.updateQueryData(
    'getCurrentUser',
    undefined,
    currentUser => {
      const preference = _.find(currentUser.attributes.preferences, {
        name: preferenceName,
      });

      if (preference) {
        preference.value = value;
      } else {
        console.warn(
          'User preference optimistic update failed:',
          preference,
          value,
        );
      }
    },
  );

  return dispatch(action);
}
