import _ from 'lodash';

import {
  PatientStatus,
  PersonalFilterView,
  PersonalFilterViewType,
} from 'ev-types';

import { GridDensities, SortingOptions } from 'ev-api/types';
import { sanitizeId, sanitizeIdArray } from 'ev-api/utils';
import { PFVDefaultValues } from 'ev-store/slices/personal-filter-view';

import {
  PersonalFilterViewResponseData,
  PersonalFilterViewsResponse,
} from './responses';

export const personalFilterViewTransform = (
  response: PersonalFilterViewResponseData,
): PersonalFilterView => {
  const { attributes } = response;

  return {
    id: sanitizeId(response.id),
    type: PersonalFilterViewType,
    attributes: {
      default: attributes.default,
      name: attributes.name,
      practiceId: sanitizeId(attributes.practice_id),
      userId: sanitizeId(attributes.user_id),
      filters: {
        providerIds: sanitizeIdArray(attributes.data.provider_ids),
        status: {
          arrived: _.includes(
            attributes.data.patient_statuses,
            PatientStatus.Arrived,
          ),
          ready: _.includes(
            attributes.data.patient_statuses,
            PatientStatus.Ready,
          ),
        },
        gridDensity: attributes.data.grid_density || GridDensities.Standard,
        sortBy: attributes.data.sort_by || SortingOptions.EarliestToLatest,
        hiddenFields: attributes.data.hidden_fields || [],
        columnsProps: attributes.data.columns_props
          ? JSON.parse(attributes.data.columns_props)
          : PFVDefaultValues.columnsProps,
        columnFiltering: attributes.data.column_filtering
          ? JSON.parse(attributes.data.column_filtering)
          : PFVDefaultValues.columnFiltering,
        columnSorting: attributes.data.column_sorting
          ? JSON.parse(attributes.data.column_sorting)
          : PFVDefaultValues.columnSorting,
        columnsVisibility: attributes.data.columns_visibility
          ? JSON.parse(attributes.data.columns_visibility)
          : PFVDefaultValues.columnsVisibility,
      },
    },
  };
};

export const personalFilterViewsTransform = (
  response: PersonalFilterViewsResponse,
): PersonalFilterView[] => {
  return _.map(response.data, pfvResponse => {
    return personalFilterViewTransform(pfvResponse);
  });
};
