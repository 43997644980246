import { Id } from './common';

export type NotificationChannelKind = 'email' | 'voice' | 'sms';

export type NotificationChannel = {
  created_at: string;
  default: boolean;
  id: Id;
  is_active: boolean;
  kind: NotificationChannelKind;
  target: string;
  updated_at: string;
  user_id: Id;
};

export enum ProviderNotifications {
  VisitSubmittedByPatient = 'visit_submitted_by_patient',
  ScheduledVisitReadyToJoin = 'scheduled_visit_ready_to_join',
  VisitCanceledByPatient = 'visit_canceled_by_patient',
  NewVisitScheduledByPatient = 'new_visit_scheduled_by_patient',
  PatientReschedulesScheduledVisit = 'patient_reschedules_scheduled_visit',
  ScheduledVisitCanceledByPatient = 'scheduled_visit_canceled_by_patient',
  PostVisitChatSent = 'post_visit_chat_sent',
  PatientJoinsVisit = 'patient_joins_qev',
  VisitCreated = 'qev_created_for_provider',
}

export enum PatientNotifications {
  NewVisitScheduledByPatient = 'new_visit_scheduled_by_patient',
  PatientReschedulesScheduledVisit = 'patient_reschedules_scheduled_visit',
  ProviderSchedulesVisitForExistingPatient = 'provider_schedules_visit_for_exisiting_patient',
  ProviderReschedulesScheduledVisit = 'provider_reschedules_scheduled_visit',
  ScheduledVisitCanceledByPatient = 'scheduled_visit_canceled_by_patient',
  ScheduledVisitReminderADayBefore = 'scheduled_visit_reminder_a_day_before',
  ScheduledVisitReminderOnTheDayOfVisit = 'scheduled_visit_reminder_on_the_day_of_visit',
  VisitStartedByPhysician = 'visit_started_by_physician',
  VisitRequeuedByPhysician = 'visit_requeued_by_physician',
  VisitCompletedByPhysician = 'visit_completed_by_physician',
  PostVisitchatSent = 'post_visit_chat_sent',
}
