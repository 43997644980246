import _ from 'lodash';

import { PharmacyAttributes } from 'ev-types';

import { PharmacyAttributesResponse, PharmacyIdResponse } from 'ev-api/core';
import { sanitizeId } from 'ev-api/utils';

export const pharmacyResponseTransform = (
  response: PharmacyAttributesResponse[],
): PharmacyAttributes[] => {
  return _.map(response, data => {
    return pharmacyTransform(data);
  });
};

export const pharmacyIdResponseTransform = (
  response: PharmacyIdResponse,
): PharmacyAttributes => {
  return pharmacyTransform(response.pharmacy);
};

export const pharmacyTransform = (
  pharmacy: PharmacyAttributesResponse,
): PharmacyAttributes => {
  return {
    ...pharmacy,
    dosespot_pharmacy_id: sanitizeId(pharmacy.dosespot_pharmacy_id),
    id: sanitizeId(pharmacy.id),
    name: pharmacy.name
      ? pharmacy.name
      : pharmacy.store_name
        ? pharmacy.store_name
        : '',
    pharmacy_id: sanitizeId(
      pharmacy.pharmacy_id
        ? pharmacy.pharmacy_id
        : pharmacy.dosespot_pharmacy_id,
    ),
  };
};
