import _ from 'lodash';

import LoginPaths from 'app-login/paths';
import PatientPaths from 'app-patient/paths';
import ProviderPaths from 'app-provider/paths';

export const isPath = (
  pathname: string,
  path: PatientPaths | ProviderPaths | LoginPaths,
) => {
  return _.endsWith(pathname, path);
};
