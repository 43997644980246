import { useEffect } from 'react';

import { User } from 'ev-types';

import i18n from './i18n';
import { getBrowserLanguage } from './languageUpdater';

export function useLanguageUpdaterVideo(user?: User | undefined) {
  const browserdefault = navigator.language.substring(0, 2);
  const userLocale = user?.attributes.locale;

  useEffect(() => {
    void i18n.changeLanguage(
      userLocale?.substring(0, 2) || getBrowserLanguage(browserdefault),
    );
  }, [browserdefault, userLocale]);
}
