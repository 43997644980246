import {
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';

import { GridDensities, SortingOptions } from 'ev-api/types';

import { WRColumns } from 'app-provider/WaitingRoom/constants';

import { Id } from './common';
import { GridColumnProps } from './custom-columns';

export enum PatientStatus {
  Arrived = 'arrived',
  Ready = 'ready',
}

export const PersonalFilterViewType = 'PersonalFilterView';

export type PersonalFilterView = {
  id: Id;
  type: typeof PersonalFilterViewType;
  attributes: {
    default: boolean;
    name: string;
    practiceId: Id;
    userId: Id;
    filters: {
      providerIds: Id[];
      status: {
        arrived: boolean;
        ready: boolean;
      };
      gridDensity: GridDensities;
      sortBy: SortingOptions;
      hiddenFields: WRColumns[];
      columnsProps: GridColumnProps;
      columnSorting: GridSortModel;
      columnFiltering: GridFilterModel;
      columnsVisibility: GridColumnVisibilityModel;
    };
  };
};
