import { useTranslation } from 'ev-i18n';

import { useSignOutMutation } from 'ev-api/core';
import { URL_PREFIX } from 'ev-config/config';
import useDialog from 'ev-hooks/dialog';
import { redirect } from 'ev-utils/browser';
import { SessionStorage, updateAuthParams } from 'ev-utils/storage';

import { useSSOInfo } from 'app-login/hooks/sso';
import LoginPaths from 'app-login/paths';

const useSignOut = () => {
  const { openDialog, closeDialog, setDialogBusy } = useDialog();
  const [signOut] = useSignOutMutation();
  const { t } = useTranslation();

  const { postSSOLogoutURL } = useSSOInfo();

  return (afterSignOutCallback?: () => void) =>
    openDialog({
      title: t('Logout'),
      children: t('Are you sure you want to logout?'),
      onAccept: async () => {
        setDialogBusy(true);
        await signOut();
        closeDialog();
        SessionStorage.removeItem('_eVisitSession'); // This is set by the RA app.
        afterSignOutCallback?.();

        void signOutRedirect(postSSOLogoutURL);
      },
      acceptText: t('Yes'),
      rejectText: t('No'),
    });
};

export default useSignOut;

export const signOutRedirect = async (postSignOutUrl: string | null) => {
  await updateAuthParams({ logoutTime: new Date().getTime() });
  const handle = new URLSearchParams(window.location.search).get('practice');
  const radSearchParams = new URLSearchParams();
  if (handle) {
    radSearchParams.set('practice', handle);
  }

  if (postSignOutUrl) {
    redirect(postSignOutUrl);
  } else {
    redirect(`${URL_PREFIX}/login/${LoginPaths.Login}?${radSearchParams}`);
  }
};
