import React from 'react';
import {
  connect,
  Provider,
  TypedUseSelectorHook,
  useDispatch, // eslint-disable-line no-restricted-imports
  useSelector, // eslint-disable-line no-restricted-imports
} from 'react-redux';

import { AppDispatch, RootState, store } from 'ev-store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const ReduxProvider = ({ children }: { children: React.ReactNode }) => {
  return <Provider store={store}>{children}</Provider>;
};

export { connect };
