import { BreakPoints } from 'ev-static/breakpoints';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    desktop: true;
  }
}

export const BreakpointsTheme = {
  values: {
    mobile: 0,
    // When these breakpoints are used in the Grid/Theme it indicates that the
    // value should be used for anything LARGER than the given breakpoint, thus
    // we use the Mobile value for the Tablet breakpoint and the Tablet value
    // for the Desktop breakpoint.
    tablet: BreakPoints.Mobile,
    desktop: BreakPoints.Tablet,
  },
};
