import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Id, VisitSegment } from 'ev-types';

type WaitingRoomState = {
  selectedSegment: null | VisitSegment;
  unreadMessages: Record<Id, number>;
};

const initialState: WaitingRoomState = {
  selectedSegment: null,
  unreadMessages: {},
};

export const waitingRoomSlice = createSlice({
  name: 'waitingRoom',
  initialState,
  reducers: {
    selectSegment(state, action: PayloadAction<VisitSegment>) {
      state.selectedSegment = action.payload;
    },
  },
});
