import _, { isObject } from 'lodash';
import { DateTime } from 'luxon';

import { Id, PatientEvaultDocument, VisitEvaultDocument } from 'ev-types';

import { base64Decode, base64Encode, safeEscapeDecode } from 'ev-utils/base64';

import {
  PatientEvaultDocumentResponseDecoded,
  VisitEvaultDocumentResponseDecoded,
} from './responses';

export const decodeJSON = <T>(encodedDocument: string) =>
  JSON.parse(base64Decode(encodedDocument), (_key, value) => {
    try {
      return _.isString(value) ? decodeURIComponent(value) : value;
    } catch (e) {
      return value;
    }
  }) as T;

export const encodeJSON = <T>(document: T) => {
  return base64Encode(
    JSON.stringify(document, (_key, value) =>
      _.isString(value) ? safeEscapeDecode(value) : value,
    ),
    true,
  );
};

export const encodeEvaultToken = (token: string) => base64Encode(token, false);

export const encodeEvaultDocument = (
  document: PatientEvaultDocument | VisitEvaultDocument,
) => encodeJSON(document);

export const decodePatientEvaultDocument = (document: string) =>
  decodeJSON<PatientEvaultDocumentResponseDecoded>(document);

export const decodeVisitEvaultDocument = (document: string) =>
  decodeJSON<VisitEvaultDocumentResponseDecoded>(document);

export const mergeEvaultDocuments = (
  currentDocument: PatientEvaultDocument | VisitEvaultDocument,
  modifiedDocument: PatientEvaultDocument | VisitEvaultDocument,
  currentUserId: Id,
) => {
  const timestamp = DateTime.utc().valueOf();
  const newModifiedDocument = _.cloneDeep(modifiedDocument);

  const compareNodes = (
    newDocument: PatientEvaultDocument | VisitEvaultDocument,
    pathToModify?: string,
  ) => {
    const path = pathToModify ? `${pathToModify}.` : '';

    Object.keys(newDocument).forEach(key => {
      const oldValue: PatientEvaultDocument | VisitEvaultDocument = _.get(
        currentDocument,
        `${path}${key}`,
      );
      const newValue: PatientEvaultDocument | VisitEvaultDocument = _.get(
        modifiedDocument,
        `${path}${key}`,
      );

      let isEqual = true;

      if (isObject(newValue)) {
        compareNodes(newValue, `${path}${key}`);
      } else {
        isEqual = _.isEqual(oldValue, newValue);
      }

      if (!isEqual) {
        _.set(
          newModifiedDocument,
          `meta.${path.slice(0, -1)}.updated_at`,
          timestamp,
        );
        _.set(newModifiedDocument, `meta.${path}${key}.updated_at`, timestamp);
        _.set(
          newModifiedDocument,
          `meta.${path.slice(0, -1)}.updated_by`,
          currentUserId,
        );
        _.set(
          newModifiedDocument,
          `meta.${path}${key}.updated_by`,
          currentUserId,
        );
      }
    });
  };

  compareNodes(modifiedDocument);

  _.set(newModifiedDocument, `meta.updated_at`, timestamp);
  _.set(newModifiedDocument, `meta.updated_by`, currentUserId);

  return newModifiedDocument;
};

export const hasInsuranceInformationChanged = (
  modifiedDocument: PatientEvaultDocument,
  currentDocument: PatientEvaultDocument,
) => !_.isEqual(modifiedDocument.insurance, currentDocument.insurance);
