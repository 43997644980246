import _ from 'lodash';

import { AppDispatch } from 'ev-store';

import { currentUserApi } from 'ev-api/core';

export function setBiographyStatusOptimisticUpdate(
  dispatch: AppDispatch,
  publish: boolean,
  content?: string,
) {
  const action = currentUserApi.util.updateQueryData(
    'getCurrentUser',
    undefined,
    currentUser => {
      currentUser.attributes.profile.published = publish;

      // We currently allow for empty biographys so we must allow empty strings as well
      if (!_.isNil(content)) {
        currentUser.attributes.profile.body = content;
      }
    },
  );

  return dispatch(action);
}
