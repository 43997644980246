import _ from 'lodash';

import { ChartCode, ChartCodeAttributes } from 'ev-types';

import { ChartCodeResponse } from './responses';

export const chartCodeResponseTransform = (
  response: ChartCodeResponse,
): ChartCodeAttributes[] => {
  return _.map(response.data, data => {
    return chartCodeTransform(data);
  });
};

export const chartCodeTransform = (
  chartCode: ChartCode,
): ChartCodeAttributes => {
  return {
    ...chartCode.attributes,
  };
};
