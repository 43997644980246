import { CSSObject } from 'styled-components/macro';

import { GridColDef } from '@mui/x-data-grid-pro';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

import { PersonalFilterView } from 'ev-types';

import { EVColors, Font, Typography } from 'ev-theme/styles';

// augment the props for the slot declaration
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    filteredTotal: number;
    columns: GridColDef[];
    personalFilterViews: PersonalFilterView[];
    unfilteredTotal: number;
  }
  interface NoRowsOverlayPropsOverrides {
    searchInput: string;
  }
}

const ColumnSeparatorStyle: CSSObject = {
  width: '5px',
  right: '-1px',
  visibility: 'visible',
  borderRight: `1px solid ${EVColors.silverBullet}`,
  borderImage: `linear-gradient(to bottom, transparent 20%, ${EVColors.silverBullet} 20% 40%, ${EVColors.silverBullet} 60% 80%, transparent 80%) 1 100%`,
};

const replaceDropdownIconActive = {
  backgroundImage:
    'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMTMzMDggNC42ODc3NEMxLjIzMTI1IDQuNTgxNSAxLjM2NTU5IDQuNTAwMjYgMS41MDAzIDQuNTAwMjZDMS42MjE5MSA0LjUwMDI2IDEuNzQzNDkgNC41NDQyIDEuODM5NzMgNC42MzMwNkw4LjAwMDEyIDEwLjI5MTlMMTQuMTYwNSA0LjYzMzA2QzE0LjM2MzcgNC40NDU1OCAxNC42ODA2IDQuNDU3MyAxNC44NjcyIDQuNjYxMzhDMTUuMDU0NyA0Ljg2NDQ4IDE1LjA0MiA1LjE3OTc3IDE0LjgzOTQgNS4zNjcyNUw4LjMzODI4IDExLjM2NjdDOC4xNDU4OCAxMS41NDQ0IDcuODUxOTUgMTEuNTQ0NCA3LjY1OTQyIDExLjM2NjdMMS4xNTgzNSA1LjM2NzI1QzAuOTU4MjM4IDUuMjA2NDUgMC45NDU1NDkgNC44OTA4NSAxLjEzMzA4IDQuNjg3NzRaIiBmaWxsPSIjNEY2MDc3Ii8+Cjwvc3ZnPgo=);',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
  color: 'transparent',
};
const replaceDropdownIconDisabled = {
  backgroundImage:
    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAAAXNSR0IArs4c6QAAAI1JREFUOE/tUjsSQEAMlUOhZKlwKBwKlV+JQ8XEbMzu2FlrFBqp874JeC8GXmC9j8HduMWZ8KcnEQhz2G76pQKAKE8C4ULQDuuIiPOZ2ZWAgUUaVlphdwQqkBxe2pYEJQAI7oHyISJZrUmRoxlPpRLQogloVGZWJpBgTdGqrDZuO+PHH+byGKad3/bD5nbJElFR2KMyNAAAAABJRU5ErkJggg==);',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
  color: 'transparent',
};

const filterInputStyle = {
  paddingRight: '8px',
  label: {
    ...Font.SemiBold,
    ...Typography.Description,
  },

  select: {
    ...Typography.Description,
  },
  '.MuiAutocomplete-inputRoot': {
    alignContent: 'center',
  },
  '.MuiInputBase-adornedStart': {
    height: 'auto',
  },
  '[data-testid="ArrowDropDownIcon"]': {
    ...replaceDropdownIconDisabled,
  },
  '[data-testid="ArrowDropDownIcon"]:not(.Mui-disabled)': {
    ...replaceDropdownIconActive,
  },
};

export const MuiDataGrid = {
  styleOverrides: {
    root: {
      borderLeft: '0',
      borderRight: '0',
      '.MuiDataGrid-columnHeaders': {
        backgroundColor: EVColors.alabaster,
        '.MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator':
          ColumnSeparatorStyle,
      },
      '.MuiDataGrid-columnHeader:has(+.MuiDataGrid-columnHeader:focus-within)':
        {
          '.MuiDataGrid-columnSeparator': {
            background: EVColors.cerulean,
          },
        },
    },
    cell: {
      borderImage: `linear-gradient(transparent 30%, ${EVColors.concrete} 0% 70%, transparent 30%) 0 0 0 1 / 1px`,
    },
    columnHeadersInner: {
      backgroundColor: EVColors.alabaster,
    },
    pinnedColumnHeaders: {
      backgroundColor: EVColors.alabaster,
    },
    columnHeaderTitle: {
      color: EVColors.mako,
      fontSize: '10px',
    },
    iconButtonContainer: {
      '.MuiBadge-standard': {
        color: EVColors.mako,
        top: '8px',
      },
    },
    row: {
      borderBottom: `1px solid ${EVColors.silverBullet}`,
      '&.Mui-selected ': {
        backgroundColor: EVColors.zircon,
      },
      '&:hover': {
        backgroundColor: EVColors.alabaster,
      },
    },
    columnSeparator: ColumnSeparatorStyle,
    iconSeparator: {
      color: 'transparent',
    },
    footerContainer: {
      '.MuiTablePagination-root': {
        '.MuiTablePagination-selectLabel': {
          display: 'flex',
        },
        '.MuiTablePagination-input ': {
          display: 'flex',
        },
      },
    },
    panelWrapper: {
      backgroundColor: EVColors.white,
    },
    panelContent: {
      padding: '16px',
      borderBottom: `1px solid ${EVColors.silverBullet}`,
      '.MuiDataGrid-filterFormValueInput': {
        ...filterInputStyle,
        '.MuiFormControl-root:has(select)': {
          label: { fontSize: '14px', ...Font.SemiBold },
          width: '100%',
        },
      },
    },
    filterForm: {
      paddingTop: '16px',
      alignItems: 'start',
    },
    deleteIconProps: {
      color: EVColors.mako,
    },
    filterFormLogicOperatorInput: { ...filterInputStyle, marginRight: '0' },
    filterFormColumnInput: { ...filterInputStyle },
    filterFormOperatorInput: { ...filterInputStyle },
  },
};
