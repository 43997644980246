import _ from 'lodash';

import {
  DynamicFormFieldAttributes,
  PatientEvaultDocument,
  VisitEvaultDocument,
} from 'ev-types';

import { DynamicFormFieldAttributesResponse } from 'ev-api/core';
import { maybeSanitizeId, sanitizeId } from 'ev-api/utils';

import {
  MetaElement,
  PatientEvaultResponse,
  VisitEvaultResponse,
} from './responses';
import {
  decodePatientEvaultDocument,
  decodeVisitEvaultDocument,
} from './utils';

export const patientEvaultTransform = (
  response: PatientEvaultResponse,
): PatientEvaultDocument => {
  const decoded = decodePatientEvaultDocument(response.document);

  return {
    ...decoded,
    cancel_details: decoded.cancel_details,
    insurance: {
      ...decoded.insurance,
      trading_partner_id: maybeSanitizeId(
        decoded.insurance?.trading_partner_id,
      ),
      plan_id: maybeSanitizeId(decoded.insurance?.plan_id),
      plan_external_id: maybeSanitizeId(decoded.insurance?.plan_external_id),
    },
    meta: decoded.meta?.insurance
      ? {
          ...decoded.meta,
          updated_by: sanitizeId(decoded.meta.updated_by),
          insurance: decoded.insurance
            ? {
                ...decoded.meta.insurance,
                updated_by: maybeSanitizeId(decoded.meta.insurance.updated_by),

                no_insurance: {
                  ...decoded.meta.insurance.no_insurance,
                  updated_by: maybeSanitizeId(
                    decoded.meta.insurance.no_insurance?.updated_by,
                  ),
                },
              }
            : undefined,
          phh: decoded.meta.phh
            ? {
                ...decoded.meta.phh,
                updated_by: sanitizeId(decoded.meta.phh.updated_by),

                allergies_medications: transformElement(
                  decoded.meta.phh.allergies_medications,
                ),
                allergies_general: transformElement(
                  decoded.meta.phh.allergies_general,
                ),
                conditions: transformElement(decoded.meta.phh.conditions),
                family_history: transformElement(
                  decoded.meta.phh.family_history,
                ),
                medications: transformElement(decoded.meta.phh.medications),
                prescriptions: transformElement(decoded.meta.phh.prescriptions),
                procedures: transformElement(decoded.meta.phh.procedures),
                questions: transformElement(decoded.meta.phh.questions),
                vitals: transformElement(decoded.meta.phh.vitals),
              }
            : undefined,
        }
      : undefined,
    phh: decoded.phh
      ? {
          ...decoded.phh,
          questions: dynamicFormFieldAttributesTransform(decoded.phh.questions),
        }
      : undefined,
  };
};

export const eVaultFileTransform = (response: Blob): string => {
  return URL.createObjectURL(response);
};

export const visitEvaultTransform = (
  response: VisitEvaultResponse,
): VisitEvaultDocument => {
  const decoded = decodeVisitEvaultDocument(response.document);

  return {
    ...decoded,
    cancel_details: decoded.cancel_details
      ? {
          ...decoded.cancel_details,
          initiator_id: sanitizeId(decoded.cancel_details.initiator_id),
        }
      : undefined,
    description: decoded.description || [],
    prescriptions: decoded.prescriptions,
    __ev: 1,
  };
};

function transformElement(element?: MetaElement) {
  if (!element) {
    return undefined;
  }

  return {
    ...element,
    updated_by: sanitizeId(element.updated_by),
  };
}

function dynamicFormFieldAttributesTransform(
  response: DynamicFormFieldAttributesResponse[] | undefined,
): DynamicFormFieldAttributes[] {
  return _.map(response, field => ({
    ...field,
    id: sanitizeId(field.id),
  }));
}
